import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useContainerRef } from './useContainerRef';
import { useCommonActions, useCommonSelector } from 'hooks/Common';

import { NAVIGATED_BY_BREADCRUMB } from 'helpers/AppHelpers';

const SCROLL_OFFSET = 80; // Header height + gaps

const useScrollManager = ({ tableKey, isDataLoaded, onScroll }) => {
  const history = useHistory();

  const { sessionId } = useCommonSelector();
  const { setTopPositionAction } = useCommonActions();

  const [hasScrolled, setHasScrolled] = useState(false);

  const { getScrollTop } = useContainerRef();

  const scrollToPosition = (positions) =>
    onScroll ? onScroll(positions) : setTopPositionAction(positions.container);

  const saveStorageItem = (position, options) =>
    sessionStorage.setItem(
      tableKey,
      JSON.stringify({ container: position, savedSessionId: sessionId, ...options })
    );
  const removeScrollPosition = () => sessionStorage.removeItem(tableKey);

  useEffect(() => {
    if (!isDataLoaded || hasScrolled || !sessionId) return;

    const sessionStorageValue = sessionStorage.getItem(tableKey);
    const savedPosition = JSON.parse(sessionStorageValue) || {};
    const isPositionExist = !!savedPosition?.container;

    // this is necessary to determine whether the page was refreshed or returned back
    // session id will remain the same until the user refreshes the page
    // no need to scroll to position if the user refreshes the page
    // because we have a conflict with saving selected summary page 
    const isSameSession = savedPosition?.savedSessionId === sessionId;

    const isNavigationBack = history.action === 'POP' && isSameSession;
    const isNavigationFromBreadcrumb =
      history.action === 'PUSH' &&
      history?.location?.state?.by === NAVIGATED_BY_BREADCRUMB &&
      isSameSession;

    const shouldScrollToPosition =
      isPositionExist && (isNavigationBack || isNavigationFromBreadcrumb);

    if (shouldScrollToPosition) {
      scrollToPosition(savedPosition);
      setHasScrolled(true);
    } else if (isPositionExist) {
      removeScrollPosition();
    }
  }, [isDataLoaded, hasScrolled, tableKey, history, sessionId, setTopPositionAction]);

  const saveScrollPosition = (options) => {
    if (!tableKey || !getScrollTop) return;

    const topPosition = getScrollTop();

    if (topPosition) {
      saveStorageItem(topPosition + SCROLL_OFFSET, options || {});
      return;
    }

    removeScrollPosition();
  };

  return { saveScrollPosition, removeScrollPosition };
};

export default useScrollManager;
