import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getServiceTicketsListApi = (query) =>
  api.get(`/ServiceTickets?${queryToString(query)}`).then((res) => res.data);

export const getTicketCountingsApi = (query) =>
  api.get(`/ServiceTickets/Counting?${queryToString(query)}`).then((res) => res.data);

export const getTicketsFilterCriteriaApi = () =>
  api.get(`/ServiceTickets/FilterCriteria`).then((res) => res.data);

export const getCreationFromApi = (q) =>
  api.get(`/ServiceTicket/CreationForm?${queryToString(q)}`).then((res) => res.data);

export const getSingleTicketApi = (id) =>
  api.get(`/ServiceTicket?id=${id}`).then((res) => res.data);

export const createTicketApi = (params) =>
  api.post(`/ServiceTicket`, params).then((res) => res.data);

export const updateTicketApi = (params) =>
  api.put(`/ServiceTicket`, params).then((res) => res.data);

export const deleteTicketApi = (id) =>
  api.delete(`/ServiceTicket?id=${id}`).then((res) => res.data);

export const getSummaryCsvResourceApi = (q) =>
  api.get(`/ServiceTickets/Serialized?${queryToString(q)}`).then((res) => res.data);
