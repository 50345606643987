import React from 'react';
import styles from './SelectionOverview.module.scss';

import ClearFilterButton from 'components/ClearFilterButton/ClearFilterButton';

import { useLooseItemActions, useLooseItemSelector } from 'hooks/LooseItems';
import { useMobileViewport } from 'hooks/useMobileViewport';

import { getSum, pluck } from 'helpers/AppHelpers';

import clsx from 'clsx';

export function SelectionOverview() {
  const isMobile = useMobileViewport();

  const { selectedRows } = useLooseItemSelector();
  const { selectRowAction } = useLooseItemActions();

  const clearCheckboxes = () => selectRowAction([]);

  const rowsCount = selectedRows.length || 0;
  const quantitySum = getSum(pluck(selectedRows, 'quantity'));
  const byAheadAccountSum = getSum(pluck(selectedRows, 'byAheadAccount'));

  return (
    <div className={clsx(styles.banner, selectedRows.length > 0 && styles.visible)}>
      <div className={styles.banner__details}>
        {!isMobile && (
          <div className={styles.banner__item}>
            <span className={styles.banner__label}>Selected Rows:</span>
            <span className={styles.banner__value}>{rowsCount}</span>
          </div>
        )}
        <div className={styles.banner__item}>
          <span className={styles.banner__label}>QTY Total:</span>
          <span className={styles.banner__value}>{quantitySum}</span>
        </div>
        <div className={styles.banner__item}>
          <span className={styles.banner__label}>BAA Total:</span>
          <span className={styles.banner__value}>{byAheadAccountSum}</span>
        </div>
      </div>
      <div className={styles.banner__button}>
        <ClearFilterButton onClick={clearCheckboxes} />
      </div>
    </div>
  );
}
