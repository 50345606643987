import {
  SET_LOCATIONS_FOR_PICK_LIST,
  SET_STATUSES_FOR_PICK_LIST,
  SET_PROJECTS_FOR_PICK_LIST,
  CLEAR_PICKLIST_STATE,
  SET_PICKLIST_FILTER,
  SET_CURRENT_PICKLIST,
  SET_PICKLIST_STATUSES,
  SET_PICKLIST_TRUCK_OR_TRAILERS,
  SET_PICKLIST_DR_CODES,
  SET_PICKLIST_ASSETS_COUNTING
} from 'constants/reduceConstants';

const initialState = {
  filter: { responseReceived: false },
  currentPicklist: {},
  assetsCounting: {
    slicedPicklistAssets: [],
    total: {}
  },
  locationList: [],
  statusList: [],
  projectList: [],
  assetsFromSearch: [],
  assetsFromArraySearch: {},
  truckOrTrailers: [],
  drCodes: [],
  picklistAssetStatuses: []
};

const pickListReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOCATIONS_FOR_PICK_LIST:
      return {
        ...state,
        locationList: payload
      };
    case SET_STATUSES_FOR_PICK_LIST:
      return {
        ...state,
        statusList: payload
      };
    case SET_PROJECTS_FOR_PICK_LIST:
      return {
        ...state,
        projectList: payload
      };
    case SET_CURRENT_PICKLIST:
      return {
        ...state,
        currentPicklist: payload
      };
    case SET_PICKLIST_STATUSES:
      return {
        ...state,
        picklistAssetStatuses: payload
      };
    case SET_PICKLIST_FILTER:
      return {
        ...state,
        filter: payload
      };
    case SET_PICKLIST_ASSETS_COUNTING:
      return { ...state, assetsCounting: payload };
    case SET_PICKLIST_TRUCK_OR_TRAILERS:
      return { ...state, truckOrTrailers: payload };
    case SET_PICKLIST_DR_CODES:
      return { ...state, drCodes: payload };
    case CLEAR_PICKLIST_STATE:
      return { ...initialState, filter: { ...state.filter, responseReceived: false } };
    default:
      return state;
  }
};

export default pickListReducer;
