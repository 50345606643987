import React from 'react';
import styles from './SearchTables.module.scss';

import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { Indicator } from 'components/Counting';

import { ROWS_LIMIT } from '../../../helpers';
import { dateTimeFormat, fileDownload, formatDate, getSeverityColor } from 'helpers/AppHelpers';

import { IconButton } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';

import fileIcon from 'assets/images/fileIcon.svg';

import LinkComponent from 'components/LinkComponent/LinkComponent';

import {
  ASSET_DETAILED_PATH,
  ASSET_REPORT_EDIT_PATH,
  BILL_OF_LADING_DETAILS_PATH,
  LEAVELIST_DETAILED_PATH,
  LOCATION_DETAILED_PATH,
  LOCATION_REPORT_EDIT_PATH,
  LOOSE_ITEM_DETAILED_PATH,
  PICKLIST_DETAILED_PATH,
  PROJECT_DETAILED_PATH,
  TICKET_DETAILED_PATH
} from 'constants/routeConstants';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch } from 'react-redux';
import { getGeneralPdfResource } from 'actions/reportListActions';
import { useMobileViewport } from 'hooks/useMobileViewport';
import NeedsToBeInspectedPopup from 'pages/AssetSummary/components/InspectedInfo/NeedsToBeInspectedPopup';

function CommonCell({ value, children }) {
  return <StyledTableCell>{children ? children : value}</StyledTableCell>;
}

export function RenderAssets({ rows }) {
  return rows.slice(0, ROWS_LIMIT).map((row) => (
    <StyledTableRow key={row.id}>
      <CommonCell>
        <LinkComponent path={`${ASSET_DETAILED_PATH}/${row.id}`} name={row.drCode} boldText />
      </CommonCell>
      <CommonCell>
        <LinkComponent
          path={`${LOCATION_DETAILED_PATH}/${row.lastLocationHistory?.location?.id}`}
          name={row.lastLocationHistory?.location?.siteCode || ''}
          isRedirectAvailable={!row.lastLocationHistory?.location?.deleted}
        />
      </CommonCell>
      <CommonCell value={row?.lastLocationHistory?.sublocation?.name} />
      <CommonCell>
        <div className={styles.opBlock}>
          <Indicator color={getSeverityColor(row?.severity?.name)} />
          {row['currentInspectionHistory']?.inspectionStatus?.name === 'NeedToBeInspected' && (
            <NeedsToBeInspectedPopup />
          )}
        </div>
      </CommonCell>
      <CommonCell value={row?.rental?.name} />
      <CommonCell value={row?.assetPrefix?.prefixType?.name} />
      <CommonCell value={row?.shortNote} />
    </StyledTableRow>
  ));
}

export function RenderBillOfLadings({ rows }) {
  return rows.slice(0, ROWS_LIMIT).map((row) => (
    <StyledTableRow key={row.id}>
      <CommonCell>
        <LinkComponent
          name={row?.id || ''}
          path={`${BILL_OF_LADING_DETAILS_PATH}/${row?.id}`}
          boldText
        />
      </CommonCell>
      <CommonCell value={row?.freightBill || ''} />
      <CommonCell>
        {row?.picklist?.id ? (
          <LinkComponent
            name={row?.picklist?.name || ''}
            path={`${PICKLIST_DETAILED_PATH}/${row?.picklist?.id}`}
            isRedirectAvailable={!!row?.picklist?.id}
          />
        ) : row?.leavelist?.id ? (
          <LinkComponent
            name={row?.leavelist?.name || ''}
            path={`${LEAVELIST_DETAILED_PATH}/${row?.leavelist?.id}`}
            isRedirectAvailable={!!row?.leavelist?.id}
          />
        ) : (
          'Custom BOL'
        )}
      </CommonCell>
      <CommonCell>
        <LinkComponent
          name={row?.originLocation || ''}
          path={`${LOCATION_DETAILED_PATH}/${row?.originLocationItem?.id}`}
          isRedirectAvailable={!row.originLocationItem?.deleted && !!row.originLocationItem?.id}
        />
      </CommonCell>
      <CommonCell>
        <LinkComponent
          name={row?.destinationLocation || ''}
          path={`${LOCATION_DETAILED_PATH}/${row?.destinationLocationItem?.id}`}
          isRedirectAvailable={
            !row.destinationLocationItem?.deleted && !!row?.destinationLocationItem?.id
          }
        />
      </CommonCell>
      <CommonCell value={row?.locationJobNumber} />
      <CommonCell
        value={row?.['createdByUser']?.firstName + ' ' + row?.['createdByUser']?.lastName}
      />
      <CommonCell value={formatDate(row?.createdAtUtc, dateTimeFormat)} />
    </StyledTableRow>
  ));
}

export function RenderPicklists({ rows }) {
  return rows.slice(0, ROWS_LIMIT).map((row) => (
    <StyledTableRow key={row.id}>
      <CommonCell>
        <LinkComponent
          path={`${PICKLIST_DETAILED_PATH}/${row.id}`}
          name={row.name}
          boldText={true}
        />
      </CommonCell>
      <CommonCell>
        <LinkComponent
          path={`${LOCATION_DETAILED_PATH}/${row.location?.id}`}
          name={row.location?.siteCode}
          isRedirectAvailable={!row.location?.deleted}
        />
      </CommonCell>
      <CommonCell value={row.sublocation?.name} />
      <CommonCell value={row.project?.jobNumber} />
      <CommonCell value={row.status?.displayName} />
      <CommonCell value={row.pocName} />
      <CommonCell
        value={row?.['createdByUser']?.firstName + ' ' + row?.['createdByUser']?.lastName}
      />
      <CommonCell value={formatDate(row?.createdAtUtc, dateTimeFormat)} />
    </StyledTableRow>
  ));
}

export function RenderLocations({ rows }) {
  return rows.slice(0, ROWS_LIMIT).map((row) => (
    <StyledTableRow key={row.id}>
      <CommonCell>
        <LinkComponent path={`${LOCATION_DETAILED_PATH}/${row.id}`} name={row.siteCode} boldText />
      </CommonCell>
      <CommonCell
        value={(row.city || '') + '' + (row.city && row.state && `, `) + (row.state || '')}
      />
      <CommonCell value={row?.locationJobNumber} />
      <CommonCell value={row.description} />
      <CommonCell value={row.siteType?.name} />
    </StyledTableRow>
  ));
}

export function RenderLooseItems({ rows }) {
  return rows.slice(0, ROWS_LIMIT).map((row) => (
    <StyledTableRow key={row.id}>
      <CommonCell>
        <LinkComponent
          path={`${LOOSE_ITEM_DETAILED_PATH}/${row?.looseItem?.id}`}
          name={row?.looseItem?.name}
          boldText
        />
      </CommonCell>
      <CommonCell>
        <LinkComponent
          path={`${LOCATION_DETAILED_PATH}/${row.location?.id}`}
          isRedirectAvailable={!row.location?.deleted}
          name={row.location?.siteCode}
        />
      </CommonCell>
      <CommonCell value={row.sublocation?.name} />
      <CommonCell value={row.quantity || 0} />
      <CommonCell value={row.byAheadAccount || 0} />
      <CommonCell
        value={
          (row?.status?.displayName || '') +
          '' +
          (row?.status?.displayName === 'In Picklist' ? ` (${row?.picklist?.name})` : '')
        }
      />
    </StyledTableRow>
  ));
}

export function RenderProjects({ rows }) {
  return rows.slice(0, ROWS_LIMIT).map((row) => (
    <StyledTableRow key={row.id}>
      <CommonCell>
        <LinkComponent path={`${PROJECT_DETAILED_PATH}/${row.id}`} name={row.name} />
      </CommonCell>
      <CommonCell value={row.jobNumber} />
      <CommonCell value={row['contractNumber']} />
      <CommonCell value={row.contractDate ? formatDate(row.contractDate) : ''} />
      <CommonCell value={row.description} />
    </StyledTableRow>
  ));
}

export function RenderTickets({ rows }) {
  return rows.slice(0, ROWS_LIMIT).map((row) => (
    <StyledTableRow key={row.id}>
      <CommonCell>
        <LinkComponent path={`${TICKET_DETAILED_PATH}/${row.id}`} name={row.id} boldText />
      </CommonCell>
      <CommonCell>
        <LinkComponent
          path={`${LOCATION_DETAILED_PATH}/${row.location?.id}`}
          name={row.location?.siteCode}
          isRedirectAvailable={!row.location?.deleted}
        />
      </CommonCell>
      <CommonCell>
        <LinkComponent path={`${ASSET_DETAILED_PATH}/${row.asset?.id}`} name={row.asset?.drCode} />
      </CommonCell>
      <CommonCell value={row.type} />
      <CommonCell value={row.rootCause} />
      <CommonCell>
        <Indicator color={getSeverityColor(row?.severity?.name)} />
      </CommonCell>
      <CommonCell value={row.description} />
      <CommonCell
        value={row.openedByUser ? row.openedByUser.firstName + ' ' + row.openedByUser.lastName : ''}
      />
      <CommonCell value={formatDate(row.openedAtUtc, dateTimeFormat)} />
    </StyledTableRow>
  ));
}

export function RenderAssetReports({ rows }) {
  const isMobile = useMobileViewport();
  const history = useHistory();
  const dispatch = useDispatch();

  const onFileDownload = (id) => {
    dispatch(getGeneralPdfResource(id)).then((res) => {
      if (isMobile) {
        fileDownload(res);
      } else {
        window.open(res.link, '_blank');
      }
    });
  };

  const handleIdClick = ({ id, submitted }) => {
    if (submitted) {
      onFileDownload(id);
    } else {
      history.push(`${ASSET_REPORT_EDIT_PATH}/${id}`);
    }
  };

  return rows.slice(0, ROWS_LIMIT).map((row) => (
    <StyledTableRow key={row.id}>
      <CommonCell>
        <span onClick={() => handleIdClick(row)} className={styles.clickable_id}>
          {row.id || ''}
        </span>
      </CommonCell>
      <CommonCell>
        <LinkComponent
          name={row.asset?.drCode || ''}
          path={`${ASSET_DETAILED_PATH}/${row.asset?.id}`}
        />
      </CommonCell>
      <CommonCell>
        <LinkComponent
          name={row.location?.siteCode || ''}
          path={`${LOCATION_DETAILED_PATH}/${row.location?.id}`}
          isRedirectAvailable={!row.location?.deleted}
        />
      </CommonCell>
      <CommonCell value={formatDate(row.createdAtUtc)} />
      <CommonCell
        value={
          row?.['submittedByUser']
            ? `${row['submittedByUser'].firstName} ${row['submittedByUser'].lastName}`
            : ''
        }
      />
      <CommonCell value={row.generalReportTemplate?.name || ''} />
      <CommonCell value={row?.note || ''} />
      <CommonCell>
        <div className={styles.cell_controls}>
          {row.submitted ? (
            <>
              <IconButton onClick={() => onFileDownload(row.id)} className={styles.icon_button}>
                <img src={fileIcon} alt="file" />
              </IconButton>
              {row.isEditable && (
                <IconButton
                  onClick={() => history.push(`${ASSET_REPORT_EDIT_PATH}/${row.id}`)}
                  className={styles.icon_button}>
                  <CreateIcon />
                </IconButton>
              )}
            </>
          ) : (
            <span
              className={styles.editingSpan}
              onClick={() => history.push(`${ASSET_REPORT_EDIT_PATH}/${row.id}`)}>
              Editing
            </span>
          )}
        </div>
      </CommonCell>
    </StyledTableRow>
  ));
}

export function RenderLocationReports({ rows }) {
  const isMobile = useMobileViewport();
  const history = useHistory();
  const dispatch = useDispatch();

  const onFileDownload = (id) => {
    dispatch(getGeneralPdfResource(id)).then((res) => {
      if (isMobile) {
        fileDownload(res);
      } else {
        window.open(res.link, '_blank');
      }
    });
  };

  const handleIdClick = ({ id, submitted }) => {
    if (submitted) {
      onFileDownload(id);
    } else {
      history.push(`${LOCATION_REPORT_EDIT_PATH}/${id}`);
    }
  };

  return rows.slice(0, ROWS_LIMIT).map((row) => (
    <StyledTableRow key={row.id}>
      <CommonCell>
        <span onClick={() => handleIdClick(row)} className={styles.clickable_id}>
          {row.id || ''}
        </span>
      </CommonCell>
      <CommonCell>
        {row?.project?.id ? (
          <div className={styles.project_cell}>
            <LinkComponent
              name={row.project?.name || ''}
              path={`${PROJECT_DETAILED_PATH}/${row.project?.id}`}
            />
          </div>
        ) : (
          <LinkComponent
            name={row.location?.siteCode || ''}
            path={`${LOCATION_DETAILED_PATH}/${row.location?.id}`}
            isRedirectAvailable={!row.location?.deleted}
          />
        )}
      </CommonCell>
      <CommonCell value={row?.location?.locationJobNumber || ''} />
      <CommonCell value={row.jobNumber || ''} />
      <CommonCell value={formatDate(row.createdAtUtc)} />
      <CommonCell
        value={
          row?.['submittedByUser']
            ? `${row['submittedByUser'].firstName} ${row['submittedByUser'].lastName}`
            : ''
        }
      />
      <CommonCell value={row.generalReportTemplate?.name || ''} />
      <CommonCell value={row?.note || ''} />
      <CommonCell>
        <div className={styles.cell_controls}>
          {row.submitted ? (
            <>
              <IconButton onClick={() => onFileDownload(row.id)} className={styles.icon_button}>
                <img src={fileIcon} alt="file" />
              </IconButton>
              {row.isEditable && (
                <IconButton
                  onClick={() => history.push(`${LOCATION_REPORT_EDIT_PATH}/${row.id}`)}
                  className={styles.icon_button}>
                  <CreateIcon />
                </IconButton>
              )}
            </>
          ) : (
            <span
              className={styles.editingSpan}
              onClick={() => history.push(`${LOCATION_REPORT_EDIT_PATH}/${row.id}`)}>
              Editing
            </span>
          )}
        </div>
      </CommonCell>
    </StyledTableRow>
  ));
}
