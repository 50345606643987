import api from '../api';
import { browserHistory } from 'browserHistory';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from 'helpers/AppHelpers';

import {
  SET_CURRENT_CATEGORY,
  SET_ACTIVE_CATEGORY,
  SET_CATEGORIES_SEARCH_VALUE,
  CLEAR_CATEGORIES_STATE
} from 'constants/reduceConstants';
import {
  SUCCESS_CREATE_CATEGORY,
  SUCCESS_DELETE_CATEGORY,
  SUCCESS_UPDATE_CATEGORY
} from '../constants/infoSnackbarData';
import { CATEGORY_DETAILED_PATH, CATEGORY_LIST_PATH } from '../constants/routeConstants';

export const getCategoryList = () => () =>
  api.category
    .getCategoryListApi()
    .then((res) => res)
    .catch((err) => console.log(err));

export const setSearchValue = (value) => (dispatch) =>
  dispatch({ type: SET_CATEGORIES_SEARCH_VALUE, payload: value });

export const getSingleCategory = (id) => (dispatch) =>
  api.category
    .getSingleCategoryApi(id)
    .then((res) => dispatch({ type: SET_CURRENT_CATEGORY, payload: res }))
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      browserHistory.push(CATEGORY_LIST_PATH);
    });

export const createCategory = (data) => () =>
  api.category.createCategoryApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_CREATE_CATEGORY);
      browserHistory.push(`${CATEGORY_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );

export const updateCategory = (data) => () =>
  api.category.updateCategoryApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_UPDATE_CATEGORY);
      browserHistory.push(`${CATEGORY_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );

export const deleteCategory = (id) => () =>
  api.category.deleteCategoryApi(id).then(
    () => {
      enqueueSuccessSnackbar(SUCCESS_DELETE_CATEGORY);
      browserHistory.push(CATEGORY_LIST_PATH);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );

export const setActiveCategory = (category) => (dispatch) =>
  dispatch({ type: SET_ACTIVE_CATEGORY, payload: category });

export const clearState = () => (dispatch) => dispatch({ type: CLEAR_CATEGORIES_STATE });
