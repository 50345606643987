import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './TeamMemberHistory.module.scss';

import {
  NoDataTableRow,
  Pagination,
  SearchField,
  SummaryHeaderWrapper,
  SummaryPageHeader,
  SummaryTableControls,
  SummaryWrapper,
  TableWrapper,
  TableHeadComponent
} from 'components/SummaryComponents';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { FilterButton, FilterChips, FilterWrapper } from 'components/FilterComponentsV2';
import StyledLink from 'components/StyledLink';
import { DownloadCSVButton } from 'components/Buttons';

import { Table, TableBody } from '@material-ui/core';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';
import { useHistoryActions, useHistorySelector } from 'hooks/TeamMemberHistory';
import { useCommonActions, useCommonSelector } from 'hooks/Common';

import {
  getChangedByApplyFilter,
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';
import {
  getTeamMemberFullName,
  formatDate,
  removeCharacterZ,
  getProjectName,
  fileDownload
} from 'helpers/AppHelpers';

import { TEAM_MEMBER_HISTORY_TABLE_PARAMETER } from 'constants/configTableConstants';
import { TEAM_MEMBER_DETAILED_PATH } from 'constants/routeConstants';

import clsx from 'clsx';

import {
  generateChips,
  generateInitialQuery,
  parseQuery,
  getTableHeadConfig,
  removeChip
} from './helpers';
import FullTimeTag from './FullTimeTag';
import FilterModal from './Filter';

export function TeamMemberHistory() {
  const isMobile = useMobileViewport();
  const tableRef = useRef(null);

  const [tableState, setTableState] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [chips, setChips] = useState([]);

  const {
    isConfigReceived,
    getTableLimit,
    isAllLimitEnabled,
    isAdminUser,
    isPersonnelManagerUser
  } = useUserConfig();
  const { setLoadMoreFlagAction } = useCommonActions();
  const { loadMoreFlag } = useCommonSelector();

  const { filter, filterCriteria } = useHistorySelector();
  const {
    getHistoryConfigAction,
    setFilterAction,
    getHistoryAction,
    getFilterCriteriaAction,
    updateConfigAction,
    getSummaryCsvResourceAction,
    clearStateAction
  } = useHistoryActions();

  const { filters, sortRules, pagination, responseReceived } = filter;

  const getHistory = useCallback(
    (query, isAutoload) => {
      setFilterAction(query);

      getHistoryAction(parseQuery(query)).then((tableData) => {
        setHistory(tableData, query, isAutoload);
        !loadMoreFlag && setLoadMoreFlagAction(false);
      });
    },
    [setFilterAction, getHistoryAction]
  );

  const setHistory = (data, query, isAutoload) => {
    if (!isAutoload) {
      setTableState(data?.items || []);
    } else {
      setTableState(tableState.concat(data?.items || []));
    }

    const updatedFilter = {
      ...query,
      pagination: { limit: data.limit, page: data.pageNumber, totalPages: data.totalPages },
      responseReceived: true
    };
    setFilterAction(updatedFilter);
  };

  useEffect(async () => {
    if (!isConfigReceived) return;

    const config = await getHistoryConfigAction();
    const filterCriteria = await getFilterCriteriaAction({
      departmentIds: config?.departmentIds || []
    });

    const q = { getTableLimit, isAllLimitEnabled, filter, config };
    const resultFilter = generateInitialQuery(q);
    getHistory(resultFilter);
    setChips(generateChips(filterCriteria, resultFilter.filters));
  }, [isConfigReceived]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const updateConfig = useCallback(
    ({ teamMemberDepartmentIds, projectIds }) => {
      const configModel = {
        departmentIds: teamMemberDepartmentIds || [],
        projectIds: projectIds || []
      };
      updateConfigAction(configModel);
    },
    [updateConfigAction]
  );

  const findMatches = useCallback(
    (value) => getHistory(getChangedBySearchFilter(filter, value)),
    [filter, getHistory]
  );
  const sortTable = useCallback(
    (rowName) => getHistory(getChangedBySortFilter(filter, rowName)),
    [filter, getHistory]
  );
  const applyFilter = useCallback(
    (values) => {
      getHistory(getChangedByApplyFilter(filter, values));
      updateConfig(values);
      setChips(generateChips(filterCriteria, values));
    },
    [filter, filterCriteria, getHistory]
  );
  const changeLimit = useCallback(
    () =>
      getHistory(
        getChangedByLimitFilter(filter, getTableLimit(TEAM_MEMBER_HISTORY_TABLE_PARAMETER))
      ),
    [filter, getTableLimit, getHistory]
  );
  const changePage = useCallback(
    (page, param) => getHistory(getChangedByPageFilter(filter, page), param === 'AUTOLOAD'),
    [filter, getHistory]
  );
  const deleteChip = useCallback(
    (chip) => applyFilter(removeChip(filters, chip)),
    [applyFilter, filters]
  );

  const openFilterModal = useCallback(() => setOpenFilter(true), [setOpenFilter]);

  const OnRoadTimeCells = ({ data: { timeOnProject, daysOnRoad, weeksOnRoad } }) => {
    const timeName = timeOnProject?.name;
    const highlightStyle = timeName && styles[timeName.toLowerCase()];

    return (
      <>
        <StyledTableCell className={clsx(styles.roadDaysCell, highlightStyle)}>
          {daysOnRoad || 0}
        </StyledTableCell>
        <StyledTableCell className={clsx(styles.roadWeeksCell, highlightStyle)}>
          {weeksOnRoad || 0}
        </StyledTableCell>
      </>
    );
  };

  const downloadCsv = () =>
    getSummaryCsvResourceAction(filter).then((file) => file?.link && fileDownload(file));

  return (
    <SummaryWrapper>
      <SummaryHeaderWrapper>
        <SummaryPageHeader title="Team Member History">
          {!isMobile && <DownloadCSVButton onClick={downloadCsv} />}
        </SummaryPageHeader>

        <SummaryTableControls>
          <FilterWrapper>
            <FilterButton onClick={openFilterModal} isActive={openFilter || !!chips?.length} />
            {!isMobile && <FilterChips chips={chips} onDelete={deleteChip} />}
          </FilterWrapper>
          <SearchField filterValue={filters?.searchQuery} onSearch={findMatches} />
        </SummaryTableControls>
      </SummaryHeaderWrapper>

      <TableWrapper ref={tableRef}>
        <Table stickyHeader={!isMobile} className={styles.table}>
          <TableHeadComponent
            config={getTableHeadConfig(isMobile)}
            sortRules={sortRules}
            onSortApply={sortTable}
          />
          <TableBody>
            {tableState.length ? (
              tableState.map((row) => (
                <StyledTableRow key={row.id} className={styles.history_table_row}>
                  <StyledTableCell className={styles.teamMemberCell}>
                    <div className={styles.content}>
                      <StyledLink
                        bold
                        to={`${TEAM_MEMBER_DETAILED_PATH}/${row.teamMember.id}`}
                        isRedirectAvailable={isAdminUser || isPersonnelManagerUser}>
                        {getTeamMemberFullName(row.teamMember)}
                      </StyledLink>

                      {!!row?.isFullTime && <FullTimeTag />}
                    </div>
                  </StyledTableCell>

                  {isMobile && <OnRoadTimeCells data={row} />}

                  <StyledTableCell className={styles.dateInCell}>
                    {formatDate(removeCharacterZ(row?.dateIn))}
                  </StyledTableCell>

                  <StyledTableCell className={styles.dateOutCell}>
                    {formatDate(removeCharacterZ(row?.dateOut))}
                  </StyledTableCell>

                  <StyledTableCell className={styles.projectCell}>
                    {getProjectName(row?.project)}
                  </StyledTableCell>

                  {!isMobile && <OnRoadTimeCells data={row} />}

                  <StyledTableCell className={styles.timeScheduleCell}>
                    {row?.timeSchedule || 0}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : responseReceived ? (
              <NoDataTableRow isTableFiltered={!!chips?.length || !!filters?.searchQuery} />
            ) : null}
          </TableBody>
        </Table>
      </TableWrapper>

      <Pagination
        withOptionAll
        rowsNumber={tableState?.length}
        currentPage={pagination?.page}
        totalPages={pagination?.totalPages}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        selectedLimit={pagination?.limit}
        tableName={TEAM_MEMBER_HISTORY_TABLE_PARAMETER}
        tableRef={tableRef}
      />

      {openFilterModal && (
        <FilterModal open={openFilter} setOpen={setOpenFilter} onApply={applyFilter} />
      )}
    </SummaryWrapper>
  );
}
