import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './BillOfLadingSummary.module.scss';

import {
  NoDataTableRow,
  Pagination,
  SearchField,
  SummaryHeaderWrapper,
  SummaryPageHeader,
  SummaryTableControls,
  SummaryWrapper,
  TableWrapper,
  TableHeadComponent
} from 'components/SummaryComponents';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { StyledButton } from 'components/Buttons';
import { FilterButton, FilterChips, FilterWrapper } from 'components/FilterComponentsV2';
import StyledLink from 'components/StyledLink';

import { Table, TableBody } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';
import { useBillOfLadingActions, useBillOfLadingSelector } from 'hooks/BillOfLading';
import { useCommonActions, useCommonSelector } from 'hooks/Common';
import useScrollManager from 'hooks/useScrollManager';

import {
  getChangedByApplyFilter,
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';
import { formatDate, dateTimeFormat, getUserFullName } from 'helpers/AppHelpers';

import {
  BILL_OF_LADING_EDIT_PATH,
  BILL_OF_LADING_DETAILS_PATH,
  LOCATION_DETAILED_PATH,
  PICKLIST_DETAILED_PATH,
  LEAVELIST_DETAILED_PATH
} from 'constants/routeConstants';
import { BILL_OF_LADING_TABLE_PARAMETER } from 'constants/configTableConstants';

import {
  generateChips,
  generateInitialQuery,
  useSummaryParams,
  parseQuery,
  tableConfig,
  removeChip
} from './helpers';
import FilterModal from './Filter';

import DownloadBlankButton from '../commonComponents/DownloadBlankButton';

export function BillOfLadingSummary() {
  const isMobile = useMobileViewport();
  const history = useHistory();
  const tableRef = useRef(null);

  const urlParams = useSummaryParams();

  const [tableState, setTableState] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [chips, setChips] = useState([]);

  const { isConfigReceived, getTableLimit, isAllLimitEnabled } = useUserConfig();
  const { setLoadMoreFlagAction } = useCommonActions();
  const { loadMoreFlag } = useCommonSelector();

  const { filter, filterCriteria } = useBillOfLadingSelector();
  const { getBillOfLadingsAction, getFilterCriteriaAction, setFilterAction, clearStateAction } =
    useBillOfLadingActions();

  const { filters, pagination, sortRules, responseReceived } = filter;

  const { saveScrollPosition, removeScrollPosition } = useScrollManager({
    tableKey: BILL_OF_LADING_TABLE_PARAMETER,
    isDataLoaded: responseReceived
  });

  const getBillOfLadings = useCallback(
    (query, isAutoload) => {
      setFilterAction(query);

      getBillOfLadingsAction(parseQuery(query)).then((tableData) => {
        setBillOfLadings(tableData, query, isAutoload);
        !loadMoreFlag && setLoadMoreFlagAction(false);
      });
    },
    [setFilterAction, getBillOfLadingsAction]
  );

  const setBillOfLadings = useCallback(
    (data, query, isAutoload) => {
      setTableState((prevState) =>
        isAutoload ? prevState.concat(data?.items || []) : data?.items || []
      );

      const updatedFilter = {
        ...query,
        pagination: { limit: data.limit, page: data.pageNumber, totalPages: data.totalPages },
        responseReceived: true
      };
      setFilterAction(updatedFilter);
    },
    [setFilterAction]
  );

  useEffect(() => {
    if (!isConfigReceived) return;

    getFilterCriteriaAction().then((criteria) => {
      const q = {
        getTableLimit,
        isAllLimitEnabled,
        filter,
        ...urlParams
      };
      const resultFilter = generateInitialQuery(q);
      getBillOfLadings(resultFilter);
      setChips(generateChips(criteria, resultFilter.filters));
    });
  }, [isConfigReceived]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const findMatches = useCallback(
    (value) => getBillOfLadings(getChangedBySearchFilter(filter, value)),
    [filter, getBillOfLadings]
  );
  const sortTable = useCallback(
    (rowName) => getBillOfLadings(getChangedBySortFilter(filter, rowName)),
    [filter, getBillOfLadings]
  );
  const applyFilter = useCallback(
    (values) => {
      getBillOfLadings(getChangedByApplyFilter(filter, values));
      setChips(generateChips(filterCriteria, values));
    },
    [filter, filterCriteria, getBillOfLadings]
  );
  const changeLimit = useCallback(
    () =>
      getBillOfLadings(
        getChangedByLimitFilter(filter, getTableLimit(BILL_OF_LADING_TABLE_PARAMETER))
      ),
    [filter, getTableLimit, getBillOfLadings]
  );
  const changePage = useCallback(
    (page, param) => getBillOfLadings(getChangedByPageFilter(filter, page), param === 'AUTOLOAD'),
    [filter, getBillOfLadings]
  );
  const deleteChip = useCallback(
    (chip) => applyFilter(removeChip(filters, chip)),
    [applyFilter, filters]
  );

  const openFilterModal = useCallback(() => setOpenFilter(true), [setOpenFilter]);

  const createBoL = useCallback(() => {
    removeScrollPosition();
    history.push(BILL_OF_LADING_EDIT_PATH);
  }, [history]);

  const MainButton = () => (
    <StyledButton label="Create custom bol" onClick={createBoL} classes={styles.main_button} />
  );

  return (
    <SummaryWrapper>
      <SummaryHeaderWrapper>
        <SummaryPageHeader title={isMobile ? 'BOL Summary' : 'Bill of Lading Summary'}>
          {!isMobile && (
            <div className={styles.controls}>
              <DownloadBlankButton />
              <MainButton />
            </div>
          )}
        </SummaryPageHeader>
        <SummaryTableControls>
          <FilterWrapper>
            <FilterButton onClick={openFilterModal} isActive={openFilter || !!chips?.length} />
            {!isMobile && <FilterChips chips={chips} onDelete={deleteChip} />}
          </FilterWrapper>
          <SearchField filterValue={filters?.searchQuery} onSearch={findMatches} />
        </SummaryTableControls>
      </SummaryHeaderWrapper>

      {isMobile && <MainButton />}

      <TableWrapper ref={tableRef}>
        <Table stickyHeader={!isMobile} className={styles.table}>
          <TableHeadComponent config={tableConfig} sortRules={sortRules} onSortApply={sortTable} />
          <TableBody>
            {tableState.length ? (
              tableState.map((row) => (
                <StyledTableRow key={row.id} className={styles.bol_table_row}>
                  <StyledTableCell className={styles.idCell}>
                    <StyledLink
                      bold
                      to={`${BILL_OF_LADING_DETAILS_PATH}/${row?.id}`}
                      onClick={saveScrollPosition}>
                      {row?.id || ''}
                    </StyledLink>
                  </StyledTableCell>
                  <StyledTableCell className={styles.freightBillCell}>
                    {row?.freightBill || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.picklistCell}>
                    {row?.picklist?.id ? (
                      <StyledLink
                        isRedirectAvailable={!!row?.picklist?.id}
                        to={`${PICKLIST_DETAILED_PATH}/${row?.picklist?.id}`}
                        onClick={saveScrollPosition}>
                        {row?.picklist?.name || ''}
                      </StyledLink>
                    ) : row?.leavelist?.id ? (
                      <StyledLink
                        isRedirectAvailable={!!row?.leavelist?.id}
                        to={`${LEAVELIST_DETAILED_PATH}/${row?.leavelist?.id}`}
                        onClick={saveScrollPosition}>
                        {row?.leavelist?.name || ''}
                      </StyledLink>
                    ) : (
                      'Custom BOL'
                    )}
                  </StyledTableCell>
                  <StyledTableCell className={styles.originCell}>
                    <StyledLink
                      isRedirectAvailable={
                        !row.originLocationItem?.deleted && !!row.originLocationItem?.id
                      }
                      to={`${LOCATION_DETAILED_PATH}/${row?.originLocationItem?.id}`}
                      onClick={saveScrollPosition}>
                      {row?.originLocation || ''}
                    </StyledLink>
                  </StyledTableCell>
                  <StyledTableCell className={styles.destinationCell}>
                    <StyledLink
                      isRedirectAvailable={
                        !row.destinationLocationItem?.deleted && !!row?.destinationLocationItem?.id
                      }
                      to={`${LOCATION_DETAILED_PATH}/${row?.destinationLocationItem?.id}`}
                      onClick={saveScrollPosition}>
                      {row?.destinationLocation || ''}
                    </StyledLink>
                  </StyledTableCell>
                  <StyledTableCell className={styles.jobNumberCell}>
                    {row?.locationJobNumber || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.userCell}>
                    {getUserFullName(row.createdByUser)}
                  </StyledTableCell>
                  <StyledTableCell className={styles.dateCell}>
                    {formatDate(row?.createdAtUtc, dateTimeFormat)}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : responseReceived ? (
              <NoDataTableRow isTableFiltered={!!chips?.length || !!filters?.searchQuery} />
            ) : null}
          </TableBody>
        </Table>
      </TableWrapper>

      <Pagination
        withOptionAll
        rowsNumber={tableState?.length}
        currentPage={pagination?.page}
        totalPages={pagination?.totalPages}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        selectedLimit={pagination?.limit}
        tableName={BILL_OF_LADING_TABLE_PARAMETER}
        tableRef={tableRef}
      />

      {openFilter && (
        <FilterModal open={openFilter} setOpen={setOpenFilter} onApply={applyFilter} />
      )}
    </SummaryWrapper>
  );
}
