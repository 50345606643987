import React from 'react';
import styles from './HeadRows.module.scss';

import { StyledTableCell } from 'components/StyledComponents';
import EditabilityToggle from 'components/EditabilityToggle/EditabilityToggle';

import { useTrackerActions, useTrackerSelector } from 'hooks/TeamMemberTracker';
import { useUserConfig } from 'hooks/useUserConfig';

export default function NoteCell({ cell }) {
  const { isAdminUser, isPersonnelManagerUser } = useUserConfig();

  const { isMasterSelected, filter } = useTrackerSelector();
  const { setDayNoteAction, getTrackerDataAction } = useTrackerActions();

  const updateCell = ({ value, onClose }) => {
    setDayNoteAction({ ...cell, note: value || '' }).then((res) => {
      if (res?.id) {
        getTrackerDataAction(
          { ...filter, filters: { ...filter.filters, columnPage: 1 } },
          { isMasterSelected }
        );
        onClose();
      }
    });
  };

  return (
    <StyledTableCell className={styles.noteCell}>
      <EditabilityToggle
        name="note"
        inputValue={cell?.note || ''}
        options={{
          max: 40,
          type: 'text',
          keyToDown: 'Enter',
          multiline: true,
          classes: {
            displaying: styles.displayMode,
            editing: styles.editMode
          }
        }}
        onAccept={updateCell}
        isDisabled={!isAdminUser && !isPersonnelManagerUser}
      />
    </StyledTableCell>
  );
}
