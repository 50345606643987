import {
  SET_TEAM_MEMBER_HISTORY_FILTER,
  SET_TEAM_MEMBER_HISTORY_FILTER_CRITERIA,
  CLEAR_TEAM_MEMBER_HISTORY_STATE
} from 'constants/reduceConstants';

const initialState = {
  filter: {},
  filterCriteria: {}
};

const teamMemberHistoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TEAM_MEMBER_HISTORY_FILTER:
      return { ...state, filter: payload };
    case SET_TEAM_MEMBER_HISTORY_FILTER_CRITERIA:
      return { ...state, filterCriteria: payload };
    case CLEAR_TEAM_MEMBER_HISTORY_STATE:
      return { ...initialState, filter: state.filter };
    default:
      return state;
  }
};

export default teamMemberHistoryReducer;
