import React, { useState } from 'react';
import styles from './EditabilityToggle.module.scss';
import { reformatInputValue, reformatNumberValue } from 'helpers/AppHelpers';
import clsx from 'clsx';
import { Button, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

export default function EditabilityToggle({ name, inputValue, onAccept, isDisabled, options }) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');

  const { focus, type, max, rule, index, keyToDown, multiline, classes } = options;

  const isNumberType = type === 'number';

  const handleOpen = () => {
    setOpen(true);
    setValue(isNumberType ? inputValue || 0 : inputValue || '');
  };

  const handleFocus = (event) => event.target.select();
  const onInput = (event) => {
    let finalValue = event.target.value;

    if (finalValue) {
      switch (type) {
        case 'number':
          finalValue = reformatNumberValue(finalValue);
          break;
        default:
          finalValue = reformatInputValue(finalValue, max, rule);
          break;
      }
    }

    setValue(finalValue);
  };

  const handleKeyDown = (e) => e.key === keyToDown && handleAccept();
  const handleClose = () => setOpen(false);
  const handleAccept = () => {
    const resultValue = isNumberType ? value || 0 : value || '';
    onAccept({ name, value: resultValue, index, onClose: handleClose });
  };

  return !open ? (
    <div
      className={clsx(
        classes?.['displaying'] && classes['displaying'],
        styles.editing,
        isDisabled && styles.editing_disabled
      )}
      onClick={handleOpen}>
      {inputValue}
    </div>
  ) : (
    <div className={clsx(classes?.['editing'] && classes['editing'], styles.editing)}>
      <TextField
        multiline={!!multiline}
        name={name}
        className={clsx(styles.textInput, !!multiline && styles.multiline)}
        variant="outlined"
        value={value}
        onChange={onInput}
        onFocus={focus && handleFocus}
        onKeyDown={keyToDown && handleKeyDown}
        inputProps={{ autoComplete: 'off' }}
      />
      <div className={styles.helpers}>
        <Button onClick={handleClose} className={styles.alert}>
          <CloseIcon />
        </Button>
        <Button onClick={handleAccept} className={styles.success}>
          <CheckIcon />
        </Button>
      </div>
    </div>
  );
}
