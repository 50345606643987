import api from '../api';
import qs from 'qs';
import { queryToString } from 'helpers/AppHelpers';

export const getLocationsListApi = (query) =>
  api
    .get(`/Locations?${qs.stringify(query, { allowDots: true, indices: false })}`)
    .then((res) => res.data);

export const getTicketsListApi = (query) =>
  api.get(`/ServiceTickets?${queryToString(query)}`).then((res) => res.data);

export const getSingleLocationApi = (id) => api.get(`/Location?id=${id}`).then((res) => res.data);

export const createLocationApi = (params) => api.post(`/Location`, params).then((res) => res.data);

export const updateLocationApi = (params) => api.put(`/Location`, params).then((res) => res.data);

export const deleteLocationApi = (id) => api.delete(`/Location?id=${id}`).then((res) => res.data);

export const getSiteTypesListApi = () => api.get('/TypeSites').then((res) => res.data);

export const getShortedLocationListApi = (query) =>
  api.get(`/Locations/Shorted?${queryToString(query)}`).then((res) => res.data);

export const getStatesListApi = () => api.get('/UnitedStates').then((res) => res.data);

export const getRentalStatuesApi = (query) =>
  api.get(`/AssetRentalStatuses?${queryToString(query)}`).then((res) => res.data);

export const getLocationLooseItemsApi = (query) =>
  api.get(`/LooseItemTentParts?${queryToString(query)}`).then((res) => res.data);

export const getLocationAssetsListApi = (query) =>
  api.get(`/Assets?${queryToString(query)}`).then((res) => res.data);

export const updateRentalStatuesApi = (params) =>
  api.put(`/AssetRentalStatuses`, params).then((res) => res);

export const getAssetsFilterCriteriaApi = () =>
  api.get(`/Assets/FilterCriteria`).then((res) => res.data);

export const getProjectsApi = (query) =>
  api.get(`/Projects?${queryToString(query)}`).then((res) => res.data);

export const getSummaryCsvResourceApi = (q) =>
  api.get(`/Locations/Serialized?${queryToString(q)}`).then((res) => res.data);
