import { useDispatch } from 'react-redux';
import {
  clearAllAssetState,
  clearAssetsState,
  deleteAsset,
  getAssetInspectionStatuses,
  getAssetOpenedTicketsList,
  getClosedTicketsList,
  getInspectionHistoryData,
  getLocationHistoryData,
  getAsset,
  getVerificationHistoryData,
  restoreAsset,
  setAssetInspectionStatus,
  setClosedTicketsFilter,
  setTablesTab,
  setInspectionHistoryFilter,
  setLocationHistoryFilter,
  setOpenedTicketsFilter,
  setVerificationHistoryFilter,
  getAssetsCounting,
  getFilterCriteria,
  setAssetFilter,
  updateAssetField,
  updateAssetFromList,
  getAssetsList,
  getCreationForm,
  createAsset,
  updateAsset,
  getAvailableDRCode,
  getLooseItemsList,
  setLooseItemsFilter,
  getNeighboringAssets,
  setAsset,
  getSummaryCsvResource,
  getLocationHistoryCsv,
  getInspectionVerificationHistoryCsv
} from 'actions/assetActions';

export function useAssetActions() {
  const dispatch = useDispatch();

  const getAssetsAction = (q) => dispatch(getAssetsList(q));
  const getCountingAction = (q) => dispatch(getAssetsCounting(q));
  const getFilterCriteriaAction = () => dispatch(getFilterCriteria());

  const setFilterAction = (data) => dispatch(setAssetFilter(data));

  const updateAssetFromListAction = (data) => dispatch(updateAssetFromList(data));
  const updateAssetFieldAction = (data) => dispatch(updateAssetField(data));

  const getCreationFormAction = () => dispatch(getCreationForm());
  const getAvailableDRCodeAction = (q) => dispatch(getAvailableDRCode(q));

  const getSummaryCsvResourceAction = (q) => dispatch(getSummaryCsvResource(q));

  const getAssetAction = (id) => dispatch(getAsset(id));
  const setAssetAction = (data) => dispatch(setAsset(data));
  const getNeighboringAssetsAction = (q) => dispatch(getNeighboringAssets(q));

  const createAssetAction = (data) => dispatch(createAsset(data));
  const updateAssetAction = (data) => dispatch(updateAsset(data));
  const deleteAssetAction = (id) => dispatch(deleteAsset(id));
  const restoreAssetAction = (id) => dispatch(restoreAsset(id));

  const getInspectionStatusesAction = (q) => dispatch(getAssetInspectionStatuses(q));

  const setAssetInspectionStatusAction = (q) => dispatch(setAssetInspectionStatus(q));

  const setTablesTabAction = (tab) => dispatch(setTablesTab(tab));

  const getUpdatedFilter = (tableFilter, res) => ({
    ...tableFilter,
    pagination: { limit: res.limit, page: res.pageNumber, totalPages: res.totalPages },
    isDataReceived: true
  });

  const getLocationHistoryAction = (filter, param) =>
    dispatch(getLocationHistoryData(filter, param));
  const setLocationHistoryFilterAction = (filter) => dispatch(setLocationHistoryFilter(filter));
  const getLocationHistory = (tableFilter, param) => {
    getLocationHistoryAction(tableFilter, param).then((res) => {
      setLocationHistoryFilterAction(getUpdatedFilter(tableFilter, res));
    });
  };

  const getInspectionHistoryDataAction = (filter, param) =>
    dispatch(getInspectionHistoryData(filter, param));
  const setInspectionHistoryFilterAction = (filter) => dispatch(setInspectionHistoryFilter(filter));
  const getInspectionHistory = (tableFilter, param) => {
    getInspectionHistoryDataAction(tableFilter, param).then((res) => {
      setInspectionHistoryFilterAction(getUpdatedFilter(tableFilter, res));
    });
  };

  const getVerificationHistoryDataAction = (filter, param) =>
    dispatch(getVerificationHistoryData(filter, param));
  const setVerificationHistoryFilterAction = (filter) =>
    dispatch(setVerificationHistoryFilter(filter));
  const getVerificationHistory = (tableFilter, param) => {
    getVerificationHistoryDataAction(tableFilter, param).then((res) => {
      setVerificationHistoryFilterAction(getUpdatedFilter(tableFilter, res));
    });
  };

  const getOpenedTicketsDataAction = (filter, param) =>
    dispatch(getAssetOpenedTicketsList(filter, param));
  const setOpenedTicketsFilterAction = (filter) => dispatch(setOpenedTicketsFilter(filter));
  const getOpenedTickets = (tableFilter, param) => {
    getOpenedTicketsDataAction(tableFilter, param).then((res) => {
      setOpenedTicketsFilterAction(getUpdatedFilter(tableFilter, res));
    });
  };

  const getClosedTicketsDataAction = (filter, param) =>
    dispatch(getClosedTicketsList(filter, param));
  const setClosedTicketsFilterAction = (filter) => dispatch(setClosedTicketsFilter(filter));
  const getClosedTickets = (tableFilter, param) => {
    getClosedTicketsDataAction(tableFilter, param).then((res) => {
      setClosedTicketsFilterAction(getUpdatedFilter(tableFilter, res));
    });
  };

  const getLooseItemsDataAction = (filter, param) => dispatch(getLooseItemsList(filter, param));
  const setLooseItemsFilterAction = (filter) => dispatch(setLooseItemsFilter(filter));
  const getLooseItems = (tableFilter, param) => {
    getLooseItemsDataAction(tableFilter, param).then((res) => {
      setLooseItemsFilterAction(getUpdatedFilter(tableFilter, res));
    });
  };

  const clearStateAction = () => dispatch(clearAssetsState());

  const clearAllStateAction = () => dispatch(clearAllAssetState());

  const getLocationHistoryCsvAction = (q) => dispatch(getLocationHistoryCsv(q));
  const getInspectionVerificationHistoryCsvAction = (q) =>
    dispatch(getInspectionVerificationHistoryCsv(q));

  return {
    getAssetsAction,
    getCountingAction,
    getFilterCriteriaAction,
    setFilterAction,
    getInspectionStatusesAction,
    getAssetAction,
    setAssetAction,
    getNeighboringAssetsAction,
    deleteAssetAction,
    restoreAssetAction,
    getSummaryCsvResourceAction,
    setAssetInspectionStatusAction,
    getInspectionHistoryDataAction,
    getVerificationHistoryDataAction,
    setTablesTabAction,
    getLocationHistory,
    getInspectionHistory,
    getVerificationHistory,
    getOpenedTickets,
    getClosedTickets,
    getLooseItems,
    updateAssetFieldAction,
    updateAssetFromListAction,
    clearStateAction,
    clearAllStateAction,
    getCreationFormAction,
    getAvailableDRCodeAction,
    createAssetAction,
    updateAssetAction,
    getLocationHistoryCsvAction,
    getInspectionVerificationHistoryCsvAction
  };
}
