import { useSelector } from 'react-redux';

import {
  selectLoadMoreFlag,
  selectSessionIdData,
  selectUnsavedFormData
} from 'pages/commonSelectors';

export function useCommonSelector() {
  const loadMoreFlag = useSelector(selectLoadMoreFlag());

  const unsavedFormData = useSelector(selectUnsavedFormData());

  const sessionId = useSelector(selectSessionIdData());

  return {
    loadMoreFlag,
    unsavedFormData,
    sessionId
  };
}
