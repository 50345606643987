import React, { useState } from 'react';
import styles from './Form.module.scss';

import FormSearchInput from 'components/FormComponents/FormSearchInput/FormSearchInput';

import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { Button, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import { usePrefixSelector } from 'hooks/Prefix';
import { useFieldArray, useFormContext } from 'react-hook-form';

export default function SubcategoryBlock() {
  const [renderCount, setRenderCount] = useState(0);

  const { control, getValues, setValue } = useFormContext();
  const { fields, remove, append } = useFieldArray({ control, name: 'assetSubcategories' });

  const { creationForm } = usePrefixSelector();
  const { categories } = creationForm;

  const removeSubcategory = (index) => {
    if (fields?.length === 1) {
      setValue(`assetSubcategories[0]`, {});
      return;
    }
    remove(index);
  };
  const addSubcategory = () => append({});

  const filterCategories = (index) => {
    if (!categories?.length) return [];

    const currentSubcategoryId = getValues('assetSubcategories')?.[index]?.id;

    const selectedSubcategoryIds = getValues('assetSubcategories')
      .map(({ id }) => id)
      .filter((id) => (currentSubcategoryId ? id !== currentSubcategoryId : true));

    return categories
      .filter(({ id }) => id !== getValues('assetCategory')?.id)
      .filter((el) => !selectedSubcategoryIds.includes(el.id));
  };

  const selectSubcategory = (name, value) => {
    setValue(name, value, { shouldDirty: true });
    setRenderCount(renderCount + 1);
  };

  return (
    <div className={styles.general__block_row}>
      <label>Subcategory</label>
      <div className={styles.subcategories}>
        {fields.map((subcategory, index) => (
          <div key={subcategory.id} className={styles.subcategories__item}>
            <FormSearchInput
              name={`assetSubcategories[${index}]`}
              options={filterCategories(index) || []}
              onSelect={selectSubcategory}
            />
            {fields?.length > 1 && (
              <IconButton
                className={styles.subcategories__clear}
                onClick={() => removeSubcategory(index)}>
                <CancelRoundedIcon />
              </IconButton>
            )}
          </div>
        ))}
        <Button
          className={styles.subcategories__add}
          onClick={addSubcategory}
          disabled={fields?.length >= 5}>
          <AddIcon />
        </Button>
      </div>
    </div>
  );
}
