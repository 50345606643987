export const TEAM_MEMBERS_TABLE_PARAMETER = 'TeamMemberSummary';

export const PICKLIST_TABLE_PARAMETER = 'PickListSummary';

export const TICKET_LIST_TABLE_PARAMETER = 'TicketsSummary';

export const LEAVELIST_TABLE_PARAMETER = 'LeaveListSummary';

export const ASSEMBLE_LIST_TABLE_PARAMETER = 'AssembledTentList';

export const PROJECT_LIST_TABLE_PARAMETER = 'ProjectSummary';

export const LOCATION_LIST_TABLE_PARAMETER = 'LocationsSummary';

export const ASSET_LIST_TABLE_PARAMETER = 'AssetSummary';

export const TEAM_MEMBER_TRACKER_TABLE_PARAMETER = 'TeamMemberTracker';

export const LOOSE_ITEMS_TABLE_PARAMETER = 'LooseItemTentPartSummary';

export const REQUEST_ORDERS_TABLE_PARAMETER = 'RequestOrdersSummary';

export const USER_LIST_TABLE_PARAMETER = 'UsersList';

export const ASSET_CALENDAR_TABLE_PARAMETER = 'AssetCalendar';

export const BILL_OF_LADING_TABLE_PARAMETER = 'BillOfLadingSummary';

export const LOCATION_REPORTS_TABLE_PARAMETER = 'LocationReportSummary';

export const ASSET_REPORTS_TABLE_PARAMETER = 'AssetReportSummary';

export const GENERAL_REPORTS_TABLE_PARAMETER = 'GeneralReportSummary';

export const USER_LOGS_TABLE_PARAMETER = 'UserLogSummary';

export const EXTERNAL_TRAININGS_TABLE_PARAMETER = 'ExternalTrainingsSummary';

export const TEAM_MEMBER_HISTORY_TABLE_PARAMETER = 'TeamMemberHistory';

export const LOCATION_DETAILS_CLOSED_TICKETS_TABLE_PARAMETER = 'LocationDetailedClosedAssetTickets';
export const LOCATION_DETAILS_OPENED_TICKETS_TABLE_PARAMETER = 'LocationDetailedOpenAssetTickets';
export const LOCATION_DETAILS_LOOSE_ITEMS_TABLE_PARAMETER = 'LocationDetailedLooseItems';
export const LOCATION_DETAILS_ASSETS_TABLE_PARAMETER = 'LocationDetailedAssetsAtLocation';

export const ASSET_LOCATION_HISTORY_TABLE_PARAMETER = 'AssetDetailedLocationHistory';
export const ASSET_INSPECTION_HISTORY_TABLE_PARAMETER = 'AssetDetailedInspectionHistory';
export const ASSET_VERIFICATION_HISTORY_TABLE_PARAMETER = 'AssetDetailedVerificationHistory';
export const ASSET_OPENED_TICKETS_TABLE_PARAMETER = 'AssetDetailedOpenAssetsTickets';
export const ASSET_CLOSED_TICKETS_TABLE_PARAMETER = 'AssetDetailedClosedAssetsTickets';
export const ASSET_LOOSE_ITEMS_TABLE_PARAMETER = 'AssetDetailedLooseItems';

export const NOTIFICATION_CENTER_ASSET_REPORT_TABLE_PARAMETER = 'NotificationCenterAssetReportTab';
export const NOTIFICATION_CENTER_GENERAL_REPORT_TABLE_PARAMETER =
  'NotificationCenterGeneralReportTab';
export const NOTIFICATION_CENTER_LOOSE_ITEMS_TABLE_PARAMETER = 'NotificationCenterLooseItemTab';
export const NOTIFICATION_CENTER_REQUEST_ORDER_TABLE_PARAMETER =
  'NotificationCenterRequestOrderTab';
export const NOTIFICATION_CENTER_LOCATION_REPORT_TABLE_PARAMETER =
  'NotificationCenterLocationReportTab';
export const NOTIFICATION_CENTER_TEAM_MEMBER_STATUS_TABLE_PARAMETER =
  'NotificationCenterTeamMemberStatusTab';
export const NOTIFICATION_CENTER_TICKET_AND_ASSET_UPDATE_TABLE_PARAMETER =
  'NotificationCenterTicketAndAssetUpdateTab';

export const TICKET_TYPES_TABLE_PARAMETER = 'TicketTypeSummary';

export const TICKET_ROOT_CAUSES_TABLE_PARAMETER = 'RootCauseSummary';

export const BOL_LOGS_TABLE_PARAMETER = 'BolLogSummary';

export const REPORT_TEMPLATE_TABLE_PARAMETER = 'ReportTemplateSummary';
