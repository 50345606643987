import {
  SET_USER_CONFIG,
  SET_UNSAVED_FORM_DATA,
  SET_TOP_POSITION,
  SET_LOAD_MORE_FLAG,
  SET_SESSION_ID
} from 'constants/reduceConstants';

const initialState = {
  userConfig: {},
  topPosition: 0,
  unsavedFormData: false,
  loadMoreFlag: false,
  sessionId: ''
};

const commonReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_CONFIG:
      return {
        ...state,
        userConfig: payload
      };
    case SET_SESSION_ID:
      return { ...state, sessionId: payload };
    case SET_UNSAVED_FORM_DATA:
      return {
        ...state,
        unsavedFormData: payload
      };
    case SET_TOP_POSITION:
      return {
        ...state,
        topPosition: payload
      };
    case SET_LOAD_MORE_FLAG:
      return {
        ...state,
        loadMoreFlag: payload
      };

    default:
      return state;
  }
};

export default commonReducer;
