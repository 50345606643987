import { queryToString } from 'helpers/AppHelpers';
import api from '../api';

export const getDashboardConfigApi = () =>
  api.get(`/User/AssetDashboardsOptions`).then((res) => res.data);

export const updateDashboardConfigApi = (data) =>
  api.put(`/User/AssetDashboardsOptions`, data).then((res) => res.data);

export const getDashboardTablesDataApi = (query) =>
  api.get(`/AssetDashboards?${queryToString(query)}`).then((res) => res.data);

export const getCsvResourceApi = (q) =>
  api.get(`/AssetDashboards/Serialized?${queryToString(q)}`).then((res) => res.data);
