import React, { useState } from 'react';
import styles from './QRCodeScanner.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import BackButton from 'components/BackButton/BackButton';

import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useMobileViewport } from 'hooks/useMobileViewport';

import { QrReader } from 'react-qr-reader';

const DRAT_DOMAIN = 'deployedresources';
const LOCAL_DOMAIN = 'localhost';

export default function QRCodeScanner({ open, setOpen, onScan }) {
  const isMobile = useMobileViewport();

  const [error, setError] = useState('');

  const closePopup = () => setOpen(false);

  const handleScan = (result, error) => {
    if (result) {
      const { text } = result;
      if (!(text.includes(DRAT_DOMAIN) || text.includes(LOCAL_DOMAIN))) {
        setError('QR Code is incorrect');
        return;
      }

      onScan(text);
      closePopup();
    }

    if (error) {
      console.info(error);
    }
  };

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.popup}>
        <div className={styles.header}>
          <h2>QR Scanner</h2>
          {!isMobile ? (
            <IconButton className={styles.header__close} onClick={closePopup}>
              <CloseIcon />
            </IconButton>
          ) : (
            <BackButton onCancel={closePopup} />
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.scanner}>
            <QrReader
              scanDelay={500}
              onResult={handleScan}
              constraints={{ facingMode: isMobile ? 'environment' : 'user' }}
            />
            <div className={styles.frame}></div>
          </div>
          <p>{error ? error : 'Data not scanned'}</p>
        </div>
      </div>
    </DialogWrapper>
  );
}
