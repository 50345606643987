import React, { useEffect, useRef, useState } from 'react';
import styles from './components.module.scss';

import DialogPopup from 'components/DialogPopup/DialogPopup';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import BackButton from 'components/BackButton/BackButton';
import MainButton from 'components/StyledComponents/MainButton';

import { useHistory, useParams } from 'react-router-dom';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useFormContext } from 'react-hook-form';
import { useLocationActions, useLocationSelector } from 'hooks/Location';
import { useUserConfig } from 'hooks/useUserConfig';

import { LOCATION_DETAILED_PATH, LOCATIONS_LIST_PATH, ROOT_PATH } from 'constants/routeConstants';
import {
  CREATE_NEW_LOCATION,
  EXIT_WITHOUT_SAVING,
  NEW_LOCATION_TYPE,
  UPDATE_LOCATION,
  UPDATE_LOCATION_TYPE,
  WITHOUT_SAVING_BY_NAV_TYPE
} from 'constants/dialogPopupsData';

import { getErrorsProperties, isParameterInvalid } from 'helpers/ErrorValidator';
import { getBackendErrors, truncateString } from 'helpers/AppHelpers';
import {
  checkMandatoryParametersForGettingCoordinates,
  extractCoordinates,
  getCoordinatesByApi,
  handleEmptyCoordinates
} from 'helpers/GeoCodingHelpers';
import { getStatusType } from './helpers';

export default function LocationEditWrapper({ children }) {
  const { id } = useParams();
  const history = useHistory();
  const isMobile = useMobileViewport();

  const { currentLocation, activeProject, unsavedFormData } = useLocationSelector();
  const {
    getLocationAction,
    getProjectListAction,
    getSiteTypesListAction,
    getStatesListAction,
    setUnsavedFormDataAction,
    createLocationAction,
    updateLocationAction,
    clearStateAction
  } = useLocationActions();

  const {
    isConfigReceived,
    isAdminUser,
    isOperationsManagerUser,
    isLocationInConfig
  } = useUserConfig();

  const breadcrumbs = useRef([{ name: 'Add Location' }]);

  const { formState, getValues, reset, setError } = useFormContext();
  const { isDirty, errors } = formState;

  const [modalData, setModalData] = useState({});

  useEffect(() => {
    if (id) {
      getLocationAction(id);
    }
  }, [id]);

  useEffect(() => {
    getSiteTypesListAction();
  }, []);

  useEffect(() => {
    getStatesListAction();
  }, []);

  useEffect(() => {
    if (id && currentLocation?.id) {
      reset({ ...currentLocation, status: getStatusType(currentLocation.active) });
    }
  }, [currentLocation]);

  useEffect(() => {
    if (!isConfigReceived) return;
    if (isAdminUser || (isOperationsManagerUser && isLocationInConfig(id))) return;
    history.push(ROOT_PATH);
  }, [isConfigReceived]);

  useEffect(() => {
    if (id && currentLocation?.id) {
      breadcrumbs.current = [
        {
          path: `${LOCATION_DETAILED_PATH}/${id}`,
          name: truncateString(currentLocation.siteCode, 45)
        },
        { name: 'Edit Location' }
      ];
    }
  }, [currentLocation]);

  useEffect(() => {
    if (activeProject?.id) {
      reset({ project: activeProject });
    }
  }, [activeProject]);

  useEffect(() => {
    getProjectListAction({ filters: { active: true } });
  }, []);

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      setUnsavedFormDataAction(true);
    }
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [isDirty]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const isFormInvalid = () => !!Object.values(errors).filter(Boolean).length;
  const closeModal = () => setModalData({ isOpened: false });
  const getBack = () => history.push(id ? `${LOCATION_DETAILED_PATH}/${id}` : LOCATIONS_LIST_PATH);

  const handleBackClick = () => {
    if (isDirty) {
      setModalData({ ...EXIT_WITHOUT_SAVING, isOpened: true });
    } else {
      getBack();
    }
  };

  const handleResponse = (err) =>
    err?.errors &&
    getBackendErrors(err).forEach(({ name, type, message }) => setError(name, { type, message }));

  const handleLocationAction = (actionFunction) => {
    if (
      checkMandatoryParametersForGettingCoordinates(getValues()) &&
      !getValues('addressAndCoordinatesNotMatched')
    ) {
      getCoordinatesByApi(getValues()).then((locations) => {
        if (locations.length) {
          const [latitude, longitude] = extractCoordinates(locations);
          actionFunction({ ...getValues(), latitude, longitude }).then(handleResponse);
        } else {
          const [lat, lon] = handleEmptyCoordinates(getValues('latitude'), getValues('longitude'));
          actionFunction({ ...getValues(), latitude: lat, longitude: lon }).then(handleResponse);
        }
      });
    } else {
      const [lat, lon] = handleEmptyCoordinates(getValues('latitude'), getValues('longitude'));
      actionFunction({ ...getValues(), latitude: lat, longitude: lon }).then(handleResponse);
    }
  };

  const agreeModal = () => {
    switch (modalData.type) {
      case NEW_LOCATION_TYPE: {
        closeModal();
        handleLocationAction(createLocationAction);
        break;
      }
      case UPDATE_LOCATION_TYPE: {
        closeModal();
        handleLocationAction(updateLocationAction);
        break;
      }
      case WITHOUT_SAVING_BY_NAV_TYPE:
        history.push(modalData.selectedRouteUrl);
        break;
      default:
        getBack();
        break;
    }
  };

  const handleSaveClick = () => {
    if (!validateForm()) return;
    setModalData(id ? UPDATE_LOCATION : CREATE_NEW_LOCATION);
  };

  const validationRules = [
    { name: 'siteCode', inputType: 'text', errorMessage: 'Site code field is required' },
    { name: 'locationJobNumber', inputType: 'text', errorMessage: 'Job Number field is required' },
    { name: 'status', inputType: 'object', errorMessage: 'Status field is required' },
    { name: 'siteType', inputType: 'object', errorMessage: 'Site type field is required' }
  ];
  const validateForm = () => {
    let isFormValid = true;
    validationRules.forEach(({ name, inputType, errorMessage }) => {
      if (isParameterInvalid(getValues(name), inputType)) {
        setError(name, getErrorsProperties(errorMessage), { shouldFocus: true });
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  return (
    <section className={styles.wrapper}>
      <DialogPopup data={modalData} onAgree={agreeModal} onDissmiss={closeModal} />
      <div className={styles.header}>
        {!isMobile && (
          <BreadcrumbsNav
            itemsArray={breadcrumbs.current}
            setDialogModalData={setModalData}
            formIsChanged={isDirty}
          />
        )}
        <div className={styles.header__block}>
          <h1>{id ? 'Edit Location' : 'Add Location'}</h1>
          {isMobile && <BackButton onCancel={handleBackClick} />}
        </div>
      </div>
      {children}
      <div className={styles.footer}>
        <MainButton text="cancel" type="secondary" action={handleBackClick} />
        <MainButton
          text={id ? 'save' : 'create'}
          type="primary"
          action={handleSaveClick}
          isDisabled={isFormInvalid()}
        />
      </div>
    </section>
  );
}
