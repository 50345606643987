import {
  SET_BOM_CONFIGURATIONS_SEARCH_VALUE,
  SET_CURRENT_BOM_CONFIGURATION,
  SET_BOM_CONFIGURATIONS_CREATION_FORM,
  CLEAR_BOM_CONFIGURATIONS_STATE
} from 'constants/reduceConstants';

const initialState = {
  searchValue: '',
  currentConfiguration: {},
  creationForm: {}
};

const bomConfigurationsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_BOM_CONFIGURATION:
      return { ...state, currentConfiguration: payload };
    case SET_BOM_CONFIGURATIONS_CREATION_FORM:
      return { ...state, creationForm: payload };
    case SET_BOM_CONFIGURATIONS_SEARCH_VALUE:
      return { ...state, searchValue: payload };
    case CLEAR_BOM_CONFIGURATIONS_STATE:
      return { ...initialState, searchValue: state.searchValue };
    default:
      return state;
  }
};

export default bomConfigurationsReducer;
