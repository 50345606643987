import { createSelector } from 'reselect';

const commonData = (state) => state.commonData;

export const selectUserConfig = () => createSelector([commonData], (state) => state.userConfig);

export const selectUnsavedFormData = () =>
  createSelector([commonData], (state) => state.unsavedFormData);

export const selectLoadMoreFlag = () => createSelector([commonData], (state) => state.loadMoreFlag);

export const selectSessionIdData = () => createSelector([commonData], (state) => state.sessionId);
