import React, { useRef } from 'react';
import styles from './LooseItemsTable.module.scss';
import { Paper, Table, TableBody, TableContainer } from '@material-ui/core';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { LOOSE_ITEM_DETAILED_PATH } from 'constants/routeConstants';
import LinkComponent from 'components/LinkComponent/LinkComponent';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useLocationSelector } from '../../../../../hooks/useLocationSelector';
import TablePaginationRefactored from 'components/TablePaginationRefactored/TablePaginationRefactored';
import { LOCATION_DETAILS_LOOSE_ITEMS_TABLE_PARAMETER } from 'constants/configTableConstants';
import { useLocationActions } from '../../../../../hooks/useLocationActions';
import { useUserConfig } from 'hooks/useUserConfig';
import { getChangedByPageFilter, getChangedBySortFilter } from 'helpers/SummaryPagesHelpers';
import { tableHeadConfig } from './tableConfig';
import TableHeadComponent from 'components/SummaryComponents/TableHeadComponent/TableHeadComponent';
import { DownloadCSVButton } from 'components/Buttons';
import { useLooseItemActions } from 'hooks/LooseItems';
import { fileDownload } from 'helpers/AppHelpers';

export default function LooseItemsTable() {
  const isMobile = useMobileViewport();
  const tableRef = useRef(null);
  const { getTableLimit } = useUserConfig();

  const { looseItemsList, looseItemsFilter } = useLocationSelector();
  const { getLooseItems } = useLocationActions();
  const { getSummaryCsvResourceAction } = useLooseItemActions();

  const emptyResponse = () =>
    !looseItemsList?.items?.length &&
    looseItemsFilter?.pagination?.page === looseItemsList?.pageNumber;

  const changePage = (page) => getLooseItems(getChangedByPageFilter(looseItemsFilter, page));
  const getUpdatedByLimitFilter = () => ({
    ...looseItemsFilter,
    pagination: {
      ...looseItemsFilter.pagination,
      limit: getTableLimit(LOCATION_DETAILS_LOOSE_ITEMS_TABLE_PARAMETER)
    },
    isDataReceived: false
  });

  const sortColumn = (name) => getLooseItems(getChangedBySortFilter(looseItemsFilter, name));

  const downloadCsv = () =>
    getSummaryCsvResourceAction({ ...looseItemsFilter, pagination: null }).then(
      (file) => file?.link && fileDownload(file)
    );

  return (
    <>
      {!isMobile && (
        <div className={styles.header}>
          <DownloadCSVButton onClick={downloadCsv} />
        </div>
      )}
      <TableContainer ref={tableRef} className={styles.table} component={Paper}>
        <Table stickyHeader={!isMobile}>
          <TableHeadComponent
            config={tableHeadConfig}
            sortRules={looseItemsFilter.sortRules}
            onSortApply={sortColumn}
          />
          <TableBody>
            {looseItemsList?.items?.length && looseItemsFilter?.isDataReceived ? (
              looseItemsList.items.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell>
                    <LinkComponent
                      name={row?.looseItem?.name}
                      path={`${LOOSE_ITEM_DETAILED_PATH}/${row?.looseItem?.id}`}
                      boldText
                    />
                  </StyledTableCell>
                  <StyledTableCell>{row?.status?.displayName || ''}</StyledTableCell>
                  <StyledTableCell>{row?.sublocation?.name || ''}</StyledTableCell>
                  <StyledTableCell>{row?.notes || ''}</StyledTableCell>
                  <StyledTableCell>
                    {row?.quantity || 0}
                    {!!row?.byAheadAccount && `(${row.byAheadAccount})`}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                {!!looseItemsFilter?.isDataReceived && (
                  <StyledTableCell colSpan={1000} className={styles.empty}>
                    No available data in the table.
                  </StyledTableCell>
                )}
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.footer}>
        <div className={styles.footer__left}>
          <label className={styles.footer__left_tip}>[1]Buy Ahead Account</label>
        </div>

        <TablePaginationRefactored
          isMobile={isMobile}
          rows={looseItemsList?.items?.length}
          currentPage={looseItemsFilter?.pagination?.page}
          totalPages={looseItemsFilter?.pagination?.totalPages}
          selectAllOption
          onPageSelect={changePage}
          pageLimit={looseItemsFilter?.pagination?.limit}
          hide={emptyResponse()}
          tableListParameter={LOCATION_DETAILS_LOOSE_ITEMS_TABLE_PARAMETER}
          getTableData={() => getLooseItems(getUpdatedByLimitFilter())}
          tableRef={tableRef}
        />
      </div>
    </>
  );
}
