import api from '../api';
import {
  CLEAR_ASSEMBLED_BOM_STATE,
  SET_ASSEMBLED_BOM_STATUSES,
  SET_ASSEMBLED_BOM_FILTER,
  SET_CURRENT_ASSEMBLED_BOM,
  SET_LOAD_MORE_FLAG,
  SET_ASSEMBLED_BOM_FILTER_CRITERIA,
  SET_ASSEMBLED_BOM_CREATION_FORM,
  SET_ASSEMBLED_BOM_LOCATIONS
} from '../constants/reduceConstants';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../helpers/AppHelpers';
import {
  SUCCESS_DELETE_ASSEMBLY,
  SUCCESS_CREATE_ASSEMBLE,
  SUCCESS_UPDATE_ASSEMBLE
} from '../constants/infoSnackbarData';
import { browserHistory } from '../browserHistory';
import { ASSEMBLED_BOM_DETAILED_PATH, ASSEMBLED_BOM_LIST_PATH } from '../constants/routeConstants';

// Summary
export const setFilter = (payload) => (dispatch) =>
  dispatch({ type: SET_ASSEMBLED_BOM_FILTER, payload });

export const setFilterCriteria = (payload) => (dispatch) =>
  dispatch({ type: SET_ASSEMBLED_BOM_FILTER_CRITERIA, payload });

export const getAssembledBOMList = (query) => (dispatch) =>
  api.assembleBOM
    .getAssembledBOMListApi(query)
    .then((res) => {
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));

export const getLocations = (query) => () =>
  api.assembleBOM
    .getLocationsApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getLooseItemConfigurations = () => () =>
  api.assembleBOM
    .getLooseItemConfigurationsApi()
    .then((res) => res)
    .catch((err) => console.log(err));

export const getCsvResource = (q) => () =>
  api.assembleBOM
    .getCsvResourceApi(q)
    .then((res) => res)
    .catch((err) => console.log(err));

// Create Screen
export const setCreationForm = (payload) => (dispatch) =>
  dispatch({ type: SET_ASSEMBLED_BOM_CREATION_FORM, payload });

export const getLocationsWithSublocations = (query) => (dispatch) =>
  api.assembleBOM
    .getLocationsWithSublocationsApi(query)
    .then((res) => {
      dispatch({ type: SET_ASSEMBLED_BOM_LOCATIONS, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const getConfigurations = (query) => () =>
  api.assembleBOM
    .getLooseItemConfigurationsApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getConfigurationData = (query) => () =>
  api.assembleBOM
    .getConfigurationDataApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const createAssemble = (data) => () =>
  api.assembleBOM.createAssembleApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_CREATE_ASSEMBLE);
      browserHistory.push(`${ASSEMBLED_BOM_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );

export const editAssemble = (data) => () =>
  api.assembleBOM.updateAssembleApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_UPDATE_ASSEMBLE);
      browserHistory.push(`${ASSEMBLED_BOM_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      if (!err?.request?.response) return;
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );

// Details
export const getStatuses = (query) => (dispatch) =>
  api.assembleBOM
    .getStatusesApi(query)
    .then((res) => {
      dispatch({ type: SET_ASSEMBLED_BOM_STATUSES, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const getSingleAssemble = (id) => (dispatch) =>
  api.assembleBOM
    .getSingleAssembleApi(id)
    .then((res) => dispatch({ type: SET_CURRENT_ASSEMBLED_BOM, payload: res }))
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      browserHistory.push(ASSEMBLED_BOM_LIST_PATH);
    });

export const deleteAssemble = (id) => () =>
  api.assembleBOM.deleteAssembleApi(id).then(
    () => {
      enqueueSuccessSnackbar(SUCCESS_DELETE_ASSEMBLY);
      browserHistory.push(ASSEMBLED_BOM_LIST_PATH);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );

export const updateAssemble = (data) => (dispatch) =>
  api.assembleBOM.updateAssembleApi(data).then(
    (res) => {
      dispatch({ type: CLEAR_ASSEMBLED_BOM_STATE });
      dispatch({ type: SET_CURRENT_ASSEMBLED_BOM, payload: res });
      browserHistory.push(`${ASSEMBLED_BOM_DETAILED_PATH}/${res.id}`);
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );

// Common
export const clearState = () => (dispatch) => dispatch({ type: CLEAR_ASSEMBLED_BOM_STATE });
