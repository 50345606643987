import React from 'react';
import styles from './SelectInput.module.scss';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Select, MenuItem, IconButton } from '@material-ui/core';
import { StyledSelect } from 'components/StyledComponents/';
import { useMobileViewport } from '../../hooks/useMobileViewport';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

const ITEM_HEIGHT = 36;
const ITEM_PADDING_TOP = 8;

export default function SelectInput({
  name,
  value,
  menuItems,
  onBlur,
  onSelect,
  isInvalid,
  disabled,
  index,
  tableView,
  clearable
}) {
  const isMobile = useMobileViewport();

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 8 + 2 * ITEM_PADDING_TOP,
        minWidth: 250,
        maxWidth: isMobile ? 'calc(100vw - 40px)' : 600,
        width: 'auto',
        marginTop: 38
      }
    },
    getContentAnchorEl: () => null
  };

  const onChange = (e) => {
    if (menuItems.length) {
      const selectedItem = menuItems?.find((item) => item.id === e.target.value);
      onSelect({ name: e.target.name, value: selectedItem }, index);
    }
  };

  const handleClearClick = () => {
    onSelect({ name, value: null }, index);
  };

  const getOptionLabel = (option) =>
    option?.firstName && option?.lastName
      ? option.firstName + ' ' + option.lastName
      : option.jobNumber && option.name
      ? option.jobNumber + ' ' + option.name
      : option.displayName ||
        option.name ||
        option.siteCode ||
        option.drCode ||
        option.initials ||
        option.method ||
        option.role ||
        '';

  const findOption = (optionId) => {
    if (!optionId || !menuItems?.length) return {};

    const normalizeId = (id) => (typeof menuItems[0].id === 'string' ? id : +id);
    return menuItems.find(({ id }) => id === normalizeId(optionId));
  };

  return (
    <div className={styles.wrapper}>
      <Select
        classes={{
          root: clsx(
            styles.selectInput,
            isInvalid && styles.error,
            tableView && styles.table_view,
            clearable && styles.clearable_input
          ),
          icon: styles.selectInput__icon
        }}
        name={name}
        IconComponent={ExpandMore}
        value={`${menuItems.length ? value : ''}`}
        onChange={onChange}
        onBlur={onBlur}
        MenuProps={MenuProps}
        disabled={disabled}
        renderValue={(optionId) => (
          <span className={styles.inputValueSpan}>{getOptionLabel(findOption(optionId))}</span>
        )}
        input={<StyledSelect />}>
        {menuItems.length ? (
          menuItems.map((item) => (
            <MenuItem classes={{ root: styles.selectInput__menu }} value={item.id} key={item.id}>
              {getOptionLabel(item)}
            </MenuItem>
          ))
        ) : (
          <MenuItem classes={{ root: styles.selectInput__emptyMenu }}>No available items</MenuItem>
        )}
      </Select>
      {clearable && !!value && (
        <IconButton className={styles.clear} onClick={handleClearClick}>
          <CloseIcon />
        </IconButton>
      )}
    </div>
  );
}
