import React, { useEffect, useState } from 'react';
import styles from './AssetsStatusUpdate.module.scss';

import { useBatchAssetUpdatesActions, useBatchAssetUpdatesSelector } from 'hooks/BatchAssetUpdates';
import { useFormContext } from 'react-hook-form';

import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import MainButton from 'components/StyledComponents/MainButton';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import {
  FormLabel,
  FormMultipleAssetSelect,
  FormSelectInput,
  FormSearchInput,
  FormDatePicker
} from 'components/FormComponents';

import moment from 'moment/moment';

import { UPDATE_ASSETS_STATUS } from 'constants/dialogPopupsData';

import { enrichNameToId, isSuccessfulStatus } from 'helpers/AppHelpers';
import { getOtherTabNames, getUpdatedTabValues, tabs } from '../helpers';
import { getErrorsProperties } from 'helpers/ErrorValidator';

export default function AssetsStatusUpdate() {
  const tabName = tabs.assetsStatusUpdate;

  const { setError, watch, clearErrors, setValue, getValues, formState } = useFormContext();

  const getFieldName = (name) => `${tabName}.${name}`;

  const { errors } = formState;
  const isFormInvalid = () =>
    !!errors?.[tabName]?.['assets'] || !!errors?.[tabName]?.['rental']?.['rentalStatus'];

  const { creationForm } = useBatchAssetUpdatesSelector();
  const { updateAssetsStatusAction } = useBatchAssetUpdatesActions();

  const { locations, projects, rentalStatuses } = creationForm;

  const [modalData, setModalData] = useState({});

  useEffect(() => {
    return () => {
      clearErrors(tabName);
    };
  }, []);

  const validateForm = () => {
    const fieldsToValidate = [
      {
        name: getFieldName('assets'),
        errorMessage: 'Assets field is required',
        isInvalid: !getValues(getFieldName('assets'))?.length
      },
      {
        name: getFieldName('rental.rentalStatus'),
        errorMessage: 'Status field is required',
        isInvalid: !getValues(getFieldName('rental.rentalStatus'))?.name
      }
    ];

    let isFormValid = true;
    fieldsToValidate.forEach(({ name, errorMessage, isInvalid }) => {
      if (isInvalid) {
        setError(name, getErrorsProperties(errorMessage));
        isFormValid = false;
      }
    });
    return isFormValid;
  };

  const clearForm = () => {
    setValue(getFieldName('assets'), []);
    setValue(getFieldName('rental.rentalStatus'), {});
    setValue(getFieldName('rental.endDate'), null);
    setValue(getFieldName('rental.project'), null);
    setValue(getFieldName('rental.destination'), null);
    setValue(getFieldName('filterLocationIds'), []);
    getOtherTabNames(tabName).forEach((name) =>
      setValue(name, getUpdatedTabValues(name, [], getValues()))
    );
  };

  const closeModal = () => setModalData({ isOpened: false });
  const agreeModal = () => {
    const values = getValues(tabName);

    updateAssetsStatusAction(values).then((res) => {
      if (!isSuccessfulStatus(res.status)) return;

      clearForm();
      closeModal();
    });
  };

  const handleUpdateClick = () => {
    if (!validateForm()) return;

    setModalData(UPDATE_ASSETS_STATUS);
  };

  const handleAssetSelect = (assets) => {
    getOtherTabNames(tabName).forEach((name) =>
      setValue(name, getUpdatedTabValues(name, assets, getValues()))
    );
    errors?.[tabName]?.['assets']?.message && clearErrors(getFieldName('assets'));
  };

  const selectRental = ({ name, value }) => {
    errors?.[tabName]?.['rental']?.['rentalStatus']?.message &&
      clearErrors(getFieldName('rental.rentalStatus'));
    ['rental.destination', 'rental.project', 'rental.endDate'].forEach((name) =>
      setValue(getFieldName(name), null)
    );
    setValue(name, value);
  };
  const selectOption = (name, value) => setValue(name, value);

  const rentalWatcher = watch(getFieldName('rental.rentalStatus'));
  const locationIdsWatcher = watch(getFieldName('filterLocationIds'));

  const conditions = {
    project: ['Reserved', 'HoldForLow', 'HoldForHigh'].includes(rentalWatcher?.name),
    duration: ['HoldForLow', 'HoldForHigh'].includes(rentalWatcher?.name),
    destination: ['InTransit'].includes(rentalWatcher?.name)
  };

  return (
    <section className={styles.wrapper}>
      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
      <div className={styles.form}>
        <div className={styles.form__row}>
          <FormLabel>Narrow Assets by Location</FormLabel>
          <FormAutoMultiSelect
            name={getFieldName('filterLocationIds')}
            menuItems={locations || []}
            options={{
              label: 'siteCode',
              disableByObjectTracker: true,
              disableLabel: true
            }}
          />
        </div>
        <div className={styles.form__assets}>
          <FormLabel>Enter Asset DR Codes; codes must be valid DR Codes*</FormLabel>
          <FormMultipleAssetSelect
            withQr
            name={getFieldName('assets')}
            onSelectTriggered={handleAssetSelect}
            queryOptions={{ locationIds: locationIdsWatcher }}
          />
        </div>
        <div className={styles.form__row}>
          <FormLabel>Assign rental status to these assets*</FormLabel>
          <FormSelectInput
            name={getFieldName('rental.rentalStatus')}
            onSelect={selectRental}
            options={enrichNameToId(rentalStatuses) || []}
          />
        </div>
        {conditions.destination && (
          <div className={styles.form__row}>
            <FormLabel>Destination</FormLabel>
            <FormSearchInput
              name={getFieldName('rental.destination')}
              options={locations || []}
              onSelect={selectOption}
              clearable
            />
          </div>
        )}
        {conditions.project && (
          <div className={styles.form__row}>
            <FormLabel>Project</FormLabel>
            <FormSearchInput
              name={getFieldName('rental.project')}
              options={projects || []}
              onSelect={selectOption}
              clearable
            />
          </div>
        )}
        {conditions.duration && (
          <div className={styles.form__row}>
            <FormLabel>Duration</FormLabel>
            <FormDatePicker
              name={getFieldName('rental.endDate')}
              min={moment(new Date()).add(-1, 'd')}
              placement="top"
            />
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <MainButton
          text="update"
          type="primary"
          isDisabled={isFormInvalid()}
          action={handleUpdateClick}
        />
      </div>
    </section>
  );
}
