import { useDispatch } from 'react-redux';
import {
  clearState,
  getHistory,
  getFilterCriteria,
  getHistoryConfig,
  setFilterCriteria,
  setFilter,
  getSummaryCsvResource,
  updateConfig
} from 'actions/teamMemberHistoryActions';

export function useHistoryActions() {
  const dispatch = useDispatch();

  const getHistoryAction = (q) => dispatch(getHistory(q));

  const getHistoryConfigAction = () => dispatch(getHistoryConfig());
  const updateConfigAction = (q) => dispatch(updateConfig(q));

  const setFilterAction = (data) => dispatch(setFilter(data));

  const setFilterCriteriaAction = (data) => dispatch(setFilterCriteria(data));
  const getFilterCriteriaAction = (q) => dispatch(getFilterCriteria(q));

  const getSummaryCsvResourceAction = (q) => dispatch(getSummaryCsvResource(q));

  const clearStateAction = () => dispatch(clearState());

  return {
    getHistoryConfigAction,
    updateConfigAction,
    setFilterAction,
    getHistoryAction,
    getFilterCriteriaAction,
    setFilterCriteriaAction,
    getSummaryCsvResourceAction,
    clearStateAction
  };
}
