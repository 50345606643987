import React, { useEffect, useRef, useState } from 'react';
import styles from './RequestOrderSummary.module.scss';

import {
  NoDataTableRow,
  Pagination,
  SearchField,
  SummaryHeaderWrapper,
  SummaryPageHeader,
  SummaryTableControls,
  SummaryWrapper,
  TableWrapper
} from 'components/SummaryComponents';
import TableHeadComponent from 'components/SummaryComponents/TableHeadComponent/TableHeadComponent';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import { FilterButton, FilterChips, FilterWrapper } from 'components/FilterComponentsV2';
import { StyledButton } from 'components/Buttons';
import StyledLink from 'components/StyledLink';

import { IconButton, Table, TableBody } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { useUserConfig } from 'hooks/useUserConfig';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useCommonActions, useCommonSelector } from 'hooks/Common';
import { useRequestOrderActions, useRequestOrderSelector } from 'hooks/RequestOrder';
import { useHistory } from 'react-router-dom';
import useScrollManager from 'hooks/useScrollManager';

import { cloneObj, dateTimeFormat, formatDate, getUserFullName } from 'helpers/AppHelpers';
import {
  getChangedByApplyFilter,
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';

import {
  REQUEST_ORDER_DETAILED_PATH,
  LOCATION_DETAILED_PATH,
  USER_DETAILED_PATH,
  REQUEST_ORDER_EDIT_PATH
} from 'constants/routeConstants';
import { REQUEST_ORDERS_TABLE_PARAMETER } from 'constants/configTableConstants';
import { DELETE_REQUEST_ORDER_FROM_SUMMARY } from 'constants/dialogPopupsData';

import { getTableHeadConfig } from '../helpers';

import FilterModal from './Filter';
import { generateChips } from './helpers';

export default function RequestOrderSummary() {
  const isMobile = useMobileViewport();
  const history = useHistory();
  const tableRef = useRef(null);

  const [tableState, setTableState] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [chips, setChips] = useState([]);
  const [modalData, setModalData] = useState({});

  const { filter, filterCriteria } = useRequestOrderSelector();
  const {
    getRequestOrdersAction,
    getFilterCriteriaAction,
    setFilterAction,
    clearStateAction,
    deleteRequestOrderAction
  } = useRequestOrderActions();

  const { isAdminUser, isConfigReceived, getTableLimit } = useUserConfig();
  const { setLoadMoreFlagAction } = useCommonActions();
  const { loadMoreFlag } = useCommonSelector();

  const { filters, pagination, responseReceived, sortRules } = filter;

  const { saveScrollPosition, removeScrollPosition } = useScrollManager({
    tableKey: REQUEST_ORDERS_TABLE_PARAMETER,
    isDataLoaded: responseReceived
  });

  const generateInitialQuery = () => ({
    ...filter,
    pagination: { ...pagination, limit: getTableLimit(REQUEST_ORDERS_TABLE_PARAMETER) }
  });

  const deleteChip = (chip) => {
    const newFilters = cloneObj(filters);

    switch (chip.key) {
      case 'isDeleted':
        newFilters[chip.key] = false;
        break;
      case 'statusNames':
      case 'entityNames':
        newFilters[chip.key] = newFilters[chip.key].filter((name) => name !== chip.itemName);
        break;
      case 'duration':
        newFilters.dateTo = null;
        newFilters.dateFrom = null;
        break;
      case 'dateTo':
      case 'dateFrom':
        newFilters[chip.key] = null;
        break;
      default:
        newFilters[chip.key] = newFilters[chip.key].filter((id) => id !== chip.itemId);
        break;
    }

    applyFilter(newFilters);
  };

  const getRequestOrders = (query, isAutoload) => {
    setFilterAction(query);

    getRequestOrdersAction(query).then((tableData) => {
      setRequestOrders(tableData, query, isAutoload);
      !loadMoreFlag && setLoadMoreFlagAction(false);
    });
  };

  const setRequestOrders = (data, query, isAutoload) => {
    if (!isAutoload) {
      setTableState(data?.items || []);
    } else {
      setTableState(tableState.concat(data?.items || []));
    }

    const updatedFilter = {
      ...query,
      pagination: { limit: data.limit, page: data.pageNumber, totalPages: data.totalPages },
      responseReceived: true
    };
    setFilterAction(updatedFilter);
  };

  useEffect(() => {
    if (!isConfigReceived) return;

    getFilterCriteriaAction().then((criteria) => {
      getRequestOrders(generateInitialQuery());
      setChips(generateChips(criteria, filters));
    });
  }, [isConfigReceived]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const handleDeleteClick = (deletedRowId) =>
    setModalData({ isOpened: true, ...DELETE_REQUEST_ORDER_FROM_SUMMARY, id: deletedRowId });

  const closeModal = () => setModalData({});
  const agreeModal = () => {
    deleteRequestOrderAction(modalData.id).then(() => {
      getRequestOrders(filter);
      closeModal();
    });
  };

  const findMatches = (value) => getRequestOrders(getChangedBySearchFilter(filter, value));
  const sortTable = (rowName) => getRequestOrders(getChangedBySortFilter(filter, rowName));
  const applyFilter = (values) => {
    getRequestOrders(getChangedByApplyFilter(filter, values));
    setChips(generateChips(filterCriteria, values));
  };
  const changePage = (page, param) =>
    getRequestOrders(getChangedByPageFilter(filter, page), param === 'AUTOLOAD');
  const changeLimit = () =>
    getRequestOrders(
      getChangedByLimitFilter(filter, getTableLimit(REQUEST_ORDERS_TABLE_PARAMETER))
    );

  const openFilterModal = () => setOpenFilter(true);

  const createRequest = () => {
    removeScrollPosition();
    history.push(REQUEST_ORDER_EDIT_PATH);
  };

  return (
    <SummaryWrapper>
      <SummaryHeaderWrapper>
        <SummaryPageHeader title="Request Orders">
          {!isMobile && (
            <StyledButton
              label="Create request"
              onClick={createRequest}
              classes={styles.main_button}
            />
          )}
        </SummaryPageHeader>
        <SummaryTableControls>
          <FilterWrapper>
            <FilterButton onClick={openFilterModal} isActive={openFilter || !!chips?.length} />
            {!isMobile && <FilterChips chips={chips} onDelete={deleteChip} />}
          </FilterWrapper>
          <SearchField filterValue={filters.searchQuery} onSearch={findMatches} />
        </SummaryTableControls>
      </SummaryHeaderWrapper>

      {isMobile && (
        <StyledButton label="Create request" onClick={createRequest} classes={styles.main_button} />
      )}

      <TableWrapper ref={tableRef}>
        <Table stickyHeader={!isMobile}>
          <TableHeadComponent
            config={getTableHeadConfig({ isAdminUser, isDeleted: filters.isDeleted })}
            sortRules={sortRules}
            onSortApply={sortTable}
          />
          <TableBody>
            {tableState?.length
              ? tableState.map((row) => (
                  <StyledTableRow key={row.id} className={styles.ro_table_row}>
                    <StyledTableCell className={styles.idCell}>
                      <StyledLink
                        bold
                        to={`${REQUEST_ORDER_DETAILED_PATH}/${row.id}`}
                        onClick={saveScrollPosition}>
                        {row.id}
                      </StyledLink>
                      {row?.location?.locationJobNumber
                        ? `${' - '}${row?.location?.locationJobNumber}`
                        : ''}
                    </StyledTableCell>
                    <StyledTableCell className={styles.statusCell}>
                      {row?.status?.displayName || ''}
                    </StyledTableCell>
                    <StyledTableCell className={styles.userCell}>
                      <StyledLink
                        to={`${USER_DETAILED_PATH}/${row.endUser?.id}`}
                        onClick={saveScrollPosition}>
                        {getUserFullName(row.endUser)}
                      </StyledLink>
                    </StyledTableCell>
                    <StyledTableCell className={styles.locationCell}>
                      <StyledLink
                        to={`${LOCATION_DETAILED_PATH}/${row.location?.id}`}
                        isRedirectAvailable={!row.location?.deleted}
                        onClick={saveScrollPosition}>
                        {row.location?.siteCode}
                      </StyledLink>
                    </StyledTableCell>
                    <StyledTableCell className={styles.entitiesCell}>
                      {row?.requestEntities?.map(({ displayName }) => displayName).join(', ') || ''}
                    </StyledTableCell>
                    <StyledTableCell className={styles.createdByCell}>
                      {row?.createdByUser?.firstName + ' ' + row?.createdByUser?.lastName || ''}
                    </StyledTableCell>
                    <StyledTableCell className={styles.createdAtCell}>
                      {formatDate(row?.createdAtUtc, dateTimeFormat) || ''}
                    </StyledTableCell>
                    {isAdminUser && !filters.isDeleted && (
                      <StyledTableCell className={styles.controlsCell}>
                        <div className={styles.controls}>
                          <IconButton
                            onClick={() => handleDeleteClick(row.id)}
                            className={styles.icon_button}>
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                ))
              : responseReceived && (
                  <NoDataTableRow isTableFiltered={!!chips?.length || !!filters?.searchQuery} />
                )}
          </TableBody>
        </Table>
      </TableWrapper>

      <Pagination
        withOptionAll
        rowsNumber={tableState?.length}
        currentPage={pagination?.page}
        totalPages={pagination.totalPages}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        selectedLimit={pagination.limit}
        tableName={REQUEST_ORDERS_TABLE_PARAMETER}
        tableRef={tableRef}
      />

      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />

      {openFilter && (
        <FilterModal open={openFilter} setOpen={setOpenFilter} onApply={applyFilter} />
      )}
    </SummaryWrapper>
  );
}
