export const SET_USER_CONFIG = 'SET_USER_CONFIG';
export const SET_UNSAVED_FORM_DATA = 'SET_UNSAVED_FORM_DATA';
export const SET_TOP_POSITION = 'SET_TOP_POSITION';
export const SET_LOAD_MORE_FLAG = 'SET_LOAD_MORE_FLAG';
export const SET_SESSION_ID = 'SET_SESSION_ID';

export const SET_IS_LOGGED_IN = 'SET_IS_LOGGED_IN';
export const SET_IS_SIGN_OUT = 'SET_IS_SIGN_OUT';
export const SET_AUTH_LINK = 'SET_AUTH_LINK';

export const SET_CATEGORIES_SEARCH_VALUE = 'PREFIX_CATEGORIES/SET_SEARCH_VALUE';
export const SET_CURRENT_CATEGORY = 'PREFIX_CATEGORIES/SET_CURRENT';
export const SET_ACTIVE_CATEGORY = 'PREFIX_CATEGORIES/SET_ACTIVE';
export const CLEAR_CATEGORIES_STATE = 'PREFIX_CATEGORIES/CLEAR_STATE';

export const SET_CURRENT_PREFIX = 'PREFIX/SET_CURRENT';
export const SET_PREFIX_CREATION_FORM = 'PREFIX/SET_CREATION_FORM';
export const CLEAR_PREFIX_STATE = 'PREFIX/CLEAR_STATE';

export const SET_LOCATION_LIST_FILTER = 'SET_LOCATION_LIST_FILTER';
export const SET_LOCATION_LIST_FILTER_CRITERIA = 'SET_LOCATION_LIST_FILTER_CRITERIA';
export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION';
export const SET_LOCATION_DETAILS_TAB = 'SET_LOCATION_DETAILS_TAB';
export const SET_LOCATION_LOOSE_ITEMS_AND_PARTS = 'SET_LOCATION_LOOSE_ITEMS_AND_PARTS';
export const SET_LOCATION_LOOSE_ITEMS_FILTER = 'SET_LOCATION_LOOSE_ITEMS_FILTER';
export const SET_LOCATION_ASSETS_LIST = 'SET_LOCATION_ASSETS_LIST';
export const SET_LOCATION_ASSETS_LIST_FILTER = 'SET_LOCATION_ASSETS_LIST_FILTER';
export const SET_LOCATION_OPENED_TICKET_LIST = 'SET_LOCATION_OPENED_TICKET_LIST';
export const SET_LOCATION_OPENED_TICKET_LIST_FILTER = 'SET_LOCATION_OPENED_TICKET_LIST_FILTER';
export const SET_LOCATION_CLOSED_TICKET_LIST = 'SET_LOCATION_CLOSED_TICKET_LIST';
export const SET_LOCATION_CLOSED_TICKET_LIST_FILTER = 'SET_LOCATION_CLOSED_TICKET_LIST_FILTER';
export const SET_SITE_TYPES_LIST = 'SET_SITE_TYPES_LIST';
export const SET_STATES_LIST = 'SET_STATES_LIST';
export const CLEAR_LOCATION_LIST_STATE = 'CLEAR_LOCATION_LIST_STATE';
export const SET_ACTIVE_PROJECT_STATE = 'SET_ACTIVE_PROJECT_STATE';
export const SET_MAP_FILTER_CRITERIA = 'SET_MAP_FILTER_CRITERIA';
export const SET_MAP_ADVANCED_PREFILTER = 'SET_MAP_ADVANCED_PREFILTER';
export const SET_NEAREST_LOCATIONS_DATA = 'SET_NEAREST_LOCATIONS_DATA';
export const SET_ASSET_RENTAL_STATUSES_DATA = 'SET_ASSET_RENTAL_STATUSES_DATA';
export const CLEAR_ACTIVE_PROJECT_STATE = 'CLEAR_ACTIVE_PROJECT_STATE';

export const SET_CLIENT_LIST = 'SET_CLIENT_LIST';
export const SET_CURRENT_CLIENT = 'SET_CURRENT_CLIENT';
export const SET_CLIENTS_PREFILTER_DATA = 'SET_CLIENTS_PREFILTER_DATA';
export const CLEAR_CLIENT_LIST_STATE = 'CLEAR_CLIENT_LIST_STATE';

// Summary
export const SET_PROJECTS_FILTER = 'PROJECTS/SET_FILTER';
export const SET_PROJECTS_FILTER_CRITERIA = 'PROJECTS/SET_FILTER_CRITERIA';
// Edit
export const SET_PROJECTS_STATES = 'PROJECTS/SET_STATES';
// Common
export const SET_CURRENT_PROJECT = 'PROJECTS/SET_CURRENT';
export const CLEAR_PROJECTS_STATE = 'PROJECTS/CLEAR_STATE';

export const SET_ACTIVE_LOCATIONS_LIST = 'SET_ACTIVE_LOCATIONS_LIST';
export const SET_PROJECT_FILTER_LIST = 'SET_PROJECT_FILTER_LIST';
export const CLEAR_LOCATION_STATE = 'CLEAR_LOCATION_STATE';

export const SET_ACTIVE_LOCATIONS_LOCATION_REPORT = 'SET_ACTIVE_LOCATIONS_LOCATION_REPORT';
export const SET_ACTIVE_REPORT_TEMPLATE = 'SET_ACTIVE_REPORT_TEMPLATE';
export const SET_REPORT_LIST_FILTER_CRITERIA = 'SET_REPORT_LIST_FILTER_CRITERIA';
export const SET_REPORT_LIST_CREATION_FORM = 'SET_REPORT_LIST_CREATION_FORM';
export const SET_REPORT_TEMPLATE_CREATION_FORM = 'SET_REPORT_TEMPLATE_CREATION_FORM';
export const SET_CURRENT_REPORT_TEMPLATE = 'SET_CURRENT_REPORT_TEMPLATE';
export const SET_REPORT_DATA_FROM_SEARCH = 'SET_REPORT_DATA_FROM_SEARCH';
export const SET_LOCATION_REPORT_FILTER = 'SET_LOCATION_REPORT_FILTER';
export const SET_ASSET_REPORT_FILTER = 'SET_ASSET_REPORT_FILTER';
export const SET_GENERAL_REPORT_FILTER = 'SET_GENERAL_REPORT_FILTER';
export const SET_CURRENT_GENERAL_REPORT = 'SET_CURRENT_GENERAL_REPORT';
export const SET_COMAPRE_FIELDS_DATA = 'SET_COMAPRE_FIELDS_DATA';
export const SET_REPORT_COMPARED_DATA = 'SET_REPORT_COMPARED_DATA';
export const SET_COMPARE_PRESELECT_DATA = 'SET_COMPARE_PRESELECT_DATA';
export const CLEAR_ACTIVE_TEMPLATE = 'CLEAR_ACTIVE_TEMPLATE';
export const CLEAR_REPORT_LIST_STATE = 'CLEAR_REPORT_LIST_STATE';

export const SET_ASSETS_FILTER = 'SET_ASSETS_FILTER';
export const SET_ASSETS_PREFILTER = 'SET_ASSETS_PREFILTER';
export const SET_ASSETS_COUNTING = 'SET_ASSETS_COUNTING';
export const SET_CURRENT_ASSET = 'SET_CURRENT_ASSET';
export const SET_ASSETS_FILTER_CRITERIA = 'SET_ASSETS_FILTER_CRITERIA';
export const SET_ASSETS_TABLE_FIELDS = 'SET_ASSETS_TABLE_FIELDS';
export const SET_ASSET_OPENED_TICKETS = 'SET_ASSET_OPENED_TICKETS';
export const SET_ASSET_CLOSED_TICKETS = 'SET_ASSET_CLOSED_TICKETS';
export const SET_ASSET_LOOSE_ITEMS = 'SET_ASSET_LOOSE_ITEMS';
export const SET_ASSET_LOOSE_ITEMS_FILTER = 'SET_ASSET_LOOSE_ITEMS_FILTER';
export const SET_ASSET_OPENED_TICKETS_FILTER = 'SET_ASSET_OPENED_TICKETS_FILTER';
export const SET_ASSET_CLOSED_TICKETS_FILTER = 'SET_ASSET_CLOSED_TICKETS_FILTER';
export const SET_ASSET_LOCATION_HISTORY_DATA = 'SET_ASSET_LOCATION_HISTORY_DATA';
export const SET_ASSET_LOCATION_HISTORY_FILTER = 'SET_ASSET_LOCATION_HISTORY_FILTER';
export const SET_ASSET_INSPECTION_HISTORY_FILTER = 'SET_ASSET_INSPECTION_HISTORY_FILTER';
export const SET_ASSET_VERIFICATION_HISTORY_FILTER = 'SET_ASSET_VERIFICATION_HISTORY_FILTER';
export const SET_INSPECTION_HISTORY_DATA = 'SET_INSPECTION_HISTORY_DATA';
export const SET_VERIFICATION_HISTORY_DATA = 'SET_VERIFICATION_HISTORY_DATA';
export const SET_ASSET_CREATION_FORM_DATA = 'SET_ASSET_CREATION_FORM_DATA';
export const SET_ASSET_INSPECTION_STATUSES = 'SET_ASSET_INSPECTION_STATUSES';
export const SET_ASSET_TABLES_SELECTED_TAB = 'SET_ASSET_TABLES_SELECTED_TAB';
export const CLEAR_ASSETS_STATE = 'CLEAR_ASSETS_STATE';
export const CLEAR_FULL_ASSETS_STATE = 'CLEAR_FULL_ASSETS_STATE';

export const SET_TICKET_LIST_FILTER = 'SET_TICKET_LIST_FILTER';
export const SET_CLOSED_SERVICE_TICKETS_LIST = 'SET_CLOSED_SERVICE_TICKETS_LIST';
export const SET_TICKETS_COUNTING = 'SET_TICKETS_COUNTING';
export const SET_CURRENT_TICKET = 'SET_CURRENT_TICKET';
export const SET_TICKETS_FILTER_CRITERIA = 'SET_TICKETS_FILTER_CRITERIA';
export const SET_TICKETS_CREATION_FORM = 'SET_TICKETS_CREATION_FORM';
export const SET_TICKET_PREFILLED_ASSET = 'SET_TICKET_PREFILLED_ASSET';
export const CLEAR_TICKETS_STATE = 'CLEAR_TICKETS_STATE';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLEAR_USER_LIST_STATE = 'CLEAR_USER_LIST_STATE';
export const SET_USER_ROLE_LIST = 'SET_USER_ROLE_LIST';
export const SET_USER_DEPARTMENTS_LIST = 'SET_USER_DEPARTMENTS_LIST';
export const SET_USER_PREFIX_LIST = 'SET_USER_PREFIX_LIST';
export const SET_ACTIVE_LOCATIONS_USER = 'SET_ACTIVE_LOCATIONS_USER';
export const SET_USER_LIST_FILTER = 'SET_USER_LIST_FILTER';
export const SET_USER_LIST_FILTER_CRITERIA = 'SET_USER_LIST_FILTER_CRITERIA';
export const SET_USER_EDIT_TAB = 'SET_USER_EDIT_TAB';

export const SET_USER_LOGS_FILTER = 'USER_LOGS/SET_FILTER';
export const CLEAR_USER_LOGS_STATE = 'USER_LOGS/CLEAR_STATE';

export const SET_TRAININGS_FILTER = 'TRAININGS/SET_FILTER';
export const SET_TRAININGS_FILTER_CRITERIA = 'TRAININGS/SET_FILTER_CRITERIA';
export const CLEAR_TRAININGS_STATE = 'TRAININGS/CLEAR_STATE';

export const SET_BATCH_ASSET_UPDATES_TAB = 'BATCH_ASSET_UPDATES/SET_TAB';
export const SET_BATCH_ASSET_UPDATES_CREATION_FORM = 'BATCH_ASSET_UPDATES/SET_CREATION_FORM';
export const CLEAR_BATCH_ASSET_UPDATES_STATE = 'BATCH_ASSET_UPDATES/CLEAR_STATE';

export const SET_DASHBOARD_CONFIG = 'SET_DASHBOARD_CONFIG';
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA';
export const CLEAR_DASHBOARD_DATA = 'CLEAR_DASHBOARD_DATA';

export const SET_LOCATIONS_FOR_LOOSE_ITEM_CREATE = 'SET_LOCATIONS_FOR_LOOSE_ITEM_CREATE';
export const SET_CATEGORIES_FOR_LOOSE_ITEM_CREATE = 'SET_CATEGORIES_FOR_ITEMS_AND_PARTS_CREATE';
export const SET_LOOSE_ITEM_CATEGORY_FIELDS = 'SET_LOOSE_ITEM_CATEGORY_FIELDS';
export const SET_LOOSE_ITEMS_FILTER = 'SET_LOOSE_ITEMS_FILTER';
export const SET_LOOSE_ITEMS_FILTER_CRITERIA = 'SET_LOOSE_ITEMS_FILTER_CRITERIA';
export const SET_CURRENT_SINGLE_ITEM = 'SET_CURRENT_SINGLE_ITEM';
export const SET_CHECKED_LOOSE_ITEMS = 'SET_CHECKED_LOOSE_ITEMS';
export const SET_LOOSE_ITEMS_TABLE_CUSTOM_COLUMNS = 'SET_LOOSE_ITEMS_TABLE_CUSTOM_COLUMNS';
export const CLEAR_LOOSE_ITEMS_STATE = 'CLEAR_LOOSE_ITEMS_STATE';

export const SET_LOOSE_ITEM_DASHBOARD_TABLES_DATA = 'SET_LOOSE_ITEM_DASHBOARD_TABLES_DATA';
export const SET_LOOSE_ITEM_DASHBOARD_CONFIG = 'SET_LOOSE_ITEM_DASHBOARD_CONFIG';
export const CLEAR_LOOSE_ITEM_DASHBOARD_STATE = 'CLEAR_LOOSE_ITEM_DASHBOARD_STATE';

export const SET_MOVED_LOOSE_ITEMS_DATA = 'SET_MOVED_LOOSE_ITEMS_DATA';
export const CLEAR_MOVED_LOOSE_ITEMS_STATE = 'CLEAR_MOVED_LOOSE_ITEMS_STATE';

export const SET_BOM_CONFIGURATIONS_SEARCH_VALUE = 'BOM_CONFIGURATIONS/SET_SEARCH_VALUE';
export const SET_CURRENT_BOM_CONFIGURATION = 'BOM_CONFIGURATIONS/SET_CURRENT';
export const SET_BOM_CONFIGURATIONS_CREATION_FORM = 'BOM_CONFIGURATIONS/SET_CREATION_FORM';
export const CLEAR_BOM_CONFIGURATIONS_STATE = 'BOM_CONFIGURATIONS/CLEAR_STATE';

// Summary
export const SET_CURRENT_ASSEMBLED_BOM = 'ASSEMBLED_BOM/SET_CURRENT';
export const SET_ASSEMBLED_BOM_FILTER = 'ASSEMBLED_BOM/SET_FILTER';
export const SET_ASSEMBLED_BOM_FILTER_CRITERIA = 'ASSEMBLED_BOM/SET_FILTER_CRITERIA';
// Create screen
export const SET_ASSEMBLED_BOM_CREATION_FORM = 'ASSEMBLED_BOM/SET_CREATION_FORM';
// Details screen
export const SET_ASSEMBLED_BOM_LOCATIONS = 'ASSEMBLED_BOM/SET_LOCATIONS';
export const SET_ASSEMBLED_BOM_STATUSES = 'ASSEMBLED_BOM/SET_STATUSES';
// Common
export const CLEAR_ASSEMBLED_BOM_STATE = 'ASSEMBLED_BOM/CLEAR_STATE';

export const SET_PICKLIST_FILTER = 'PICKLIST/SET_FILTER';
export const SET_CURRENT_PICKLIST = 'PICKLIST/SET_CURRENT';
export const SET_PICKLIST_ASSETS_COUNTING = 'PICKLIST/SET_COUNTING';
export const CLEAR_PICKLIST_STATE = 'PICKLIST/CLEAR_STATE';
export const SET_LOCATIONS_FOR_PICK_LIST = 'PICKLIST/SET_LOCATIONS_FOR_PICK_LIST';
export const SET_STATUSES_FOR_PICK_LIST = 'PICKLIST/SET_STATUSES_FOR_PICK_LIST';
export const SET_PROJECTS_FOR_PICK_LIST = 'PICKLIST/SET_PROJECTS_FOR_PICK_LIST';
export const SET_PICKLIST_STATUSES = 'PICKLIST/SET_PICKLIST_STATUSES';
export const SET_PICKLIST_TRUCK_OR_TRAILERS = 'PICKLIST/SET_PICKLIST_TRUCK_OR_TRAILERS';
export const SET_PICKLIST_DR_CODES = 'PICKLIST/SET_PICKLIST_DR_CODES';

export const SET_ACTIVE_PICKLIST_ADD_BOM = 'ADD_BOM/SET_ACTIVE_PICKLIST';
export const SET_CONFIGURATION_LIST_ADD_BOM = 'ADD_BOM/SET_CONFIGURATION_LIST';
export const SET_CONFIGURATION_DETAILS_ADD_BOM = 'ADD_BOM/SET_CONFIGURATION_DETAILS';
export const CLEAR_STATE_ADD_BOM = 'ADD_BOM/CLEAR_STATE';

export const SET_CREATE_BATCH_OF_ASSETS_CREATION_FORM = 'CREATE_BATCH_OF_ASSETS/SET_CREATION_FORM';
export const SET_PREVIEW_BATCH_OF_ASSETS = 'CREATE_BATCH_OF_ASSETS/SET_PREVIEW_ASSETS';
export const SET_BATCH_OF_ASSETS_FORM_STATE = 'CREATE_BATCH_OF_ASSETS/SET_FORM_STATE';
export const TOGGLE_CREATION_SCREEN = 'CREATE_BATCH_OF_ASSETS/TOGGLE_CREATION_SCREEN';
export const CLEAR_CREATE_BATCH_OF_ASSETS_STATE = 'CREATE_BATCH_OF_ASSETS/CLEAR_STATE';

export const SET_LOCATIONS_FOR_LEAVE_LIST = 'LEAVELIST/SET_LOCATIONS';
export const SET_STATUSES_FOR_LEAVE_LIST = 'LEAVELIST/SET_STATUSES';
export const SET_PROJECTS_FOR_LEAVE_LIST = 'LEAVELIST/SET_PROJECTS';
export const SET_LEAVE_LIST_FILTER = 'LEAVELIST/SET_FILTER';
export const SET_CURRENT_LEAVE_LIST = 'LEAVELIST/SET_CURRENT';
export const SET_LEAVE_LIST_STATUSES = 'LEAVELIST/SET_LEAVELIST_STATUSES';
export const SET_TRUCK_OR_TRAILERS = 'LEAVELIST/SET_TRUCK_OR_TRAILERS';
export const CLEAR_LEAVE_LIST_STATE = 'LEAVELIST/CLEAR_STATE';

export const SET_USER_CALENDAR_OPTIONS = 'SET_USER_CALENDAR_OPTIONS';
export const SET_LOCATION_LIST_FOR_CALENDAR = 'SET_LOCATION_LIST_FOR_CALENDAR';
export const SET_ASSET_PREFIXES_LIST_FOR_CALENDAR = 'SET_ASSET_PREFIXES_LIST_FOR_CALENDAR';
export const SET_PROJECT_LIST_FOR_CALENDAR = 'SET_PROJECT_LIST_FOR_CALENDAR';
export const SET_ASSET_STATUSES_FOR_CALENDAR = 'SET_ASSET_STATUSES_FOR_CALENDAR';
export const CLEAR_CALENDAR_STATE = 'CLEAR_CALENDAR_STATE';
export const CLEAR_CALENDAR_ASSET_OPTIONS = 'CLEAR_CALENDAR_ASSET_OPTIONS';

export const SET_CURRENT_TEAM_MEMBER = 'TEAM_MEMBERS/SET_CURRENT';
export const SET_TEAM_MEMBERS_FILTER_CRITERIA = 'TEAM_MEMBERS/SET_FILTER_CRITERIA';
export const SET_TEAM_MEMBERS_FILTER = 'TEAM_MEMBERS/SET_FILTER';
export const SET_TEAM_MEMBER_CREATION_FORM = 'TEAM_MEMBERS/SET_CREATION_FORM';
export const CLEAR_TEAM_MEMBER_STATE = 'TEAM_MEMBERS/CLEAR_STATE';

export const SET_TEAM_MEMBER_TRACKER_FILTER_CRITERIA = 'SET_TEAM_MEMBER_TRACKER_FILTER_CRITERIA';
export const SET_TEAM_MEMBER_TRACKER_TOGGLE_TAB = 'SET_TEAM_MEMBER_TRACKER_TOGGLE_TAB';
export const SET_TRACKER_MASTER_FILTER_DATA = 'SET_TRACKER_MASTER_FILTER_DATA';
export const SET_TRACKER_BREAKDOWN_FILTER_DATA = 'SET_TRACKER_BREAKDOWN_FILTER_DATA';
export const CLEAR_TEAM_MEMBER_TRACKER_STATE = 'CLEAR_TEAM_MEMBER_TRACKER_STATE';
export const SET_TRACKER_FILTER_DATA = 'SET_TRACKER_FILTER_DATA';
export const SET_TRACKER_RESPONSE_RECEIVED = 'SET_TRACKER_RESPONSE_RECEIVED';
export const SET_TRACKER_TABLE_DATA = 'SET_TRACKER_TABLE_DATA';

export const SET_ASSET_DASHBOARD_DATA = 'SET_ASSET_DASHBOARD_DATA';
export const SET_ASSET_DASHBOARD_PREFIXES_DATA = 'SET_ASSET_DASHBOARD_PREFIXES_DATA';
export const SET_ASSET_DASHBOARD_FIELDS_DATA = 'SET_ASSET_DASHBOARD_FIELDS_DATA';
export const SET_ASSET_DASHBOARD_PREFILTER_DATA = 'SET_ASSET_DASHBOARD_PREFILTER_DATA';
export const CLEAR_ASSET_FIELDS_DASHBOARD_DATA = 'CLEAR_ASSET_FIELDS_DASHBOARD_DATA';

export const SET_CURRENT_REQUEST_ORDER = 'SET_CURRENT_REQUEST_ORDER';
export const SET_REQUEST_ORDER_FILTER_DATA = 'SET_REQUEST_ORDER_FILTER_DATA';
export const SET_REQUEST_ORDER_FILTER_CRITERIA_DATA = 'SET_REQUEST_ORDER_FILTER_CRITERIA_DATA';
export const SET_REQUEST_ORDER_CREATION_FORM_DATA = 'SET_REQUEST_ORDER_CREATION_FORM_DATA';
export const CLEAR_REQUEST_ORDER_STATE = 'CLEAR_REQUEST_ORDER_STATE';

export const SET_EMPLOYMENT_STATUSES = 'SET_EMPLOYMENT_STATUSES';
export const CLEAR_EMPLOYMENT_STATUSES_STATE = 'CLEAR_EMPLOYMENT_STATUSES_STATE';

export const SET_LOOSE_ITEMS_FOR_ADD_LOOSE_ITEMS_POPUP = 'ADD_LOOSE_ITEMS_POPUP/SET_LOOSE_ITEMS';
export const SET_PICKLISTS_FOR_ADD_LOOSE_ITEMS_POPUP = 'ADD_LOOSE_ITEMS_POPUP/SET_LIST_OF_PICKLIST';
export const SET_LOCATIONS_FOR_ADD_LOOSE_ITEMS_POPUP = 'ADD_LOOSE_ITEMS_POPUP/SET_LOCATIONS';
export const CLEAR_ADD_LOOSE_ITEMS_POPUP_STATE = 'ADD_LOOSE_ITEMS_POPUP/CLEAR_STATE';

export const SET_CURRENT_BILL_OF_LADING = 'BILL_OF_LADING/SET_CURRENT';
export const SET_BILL_OF_LADING_FILTER = 'BILL_OF_LADING/SET_FILTER';
export const SET_BILL_OF_LADING_FILTER_CRITERIA = 'BILL_OF_LADING/SET_FILTER_CRITERIA';
export const SET_BILL_OF_LADING_CREATION_FORM = 'BILL_OF_LADING/SET_CREATION_FORM';
export const CLEAR_BILL_OF_LADING_STATE = 'BILL_OF_LADING/CLEAR_STATE';

export const SET_TEAM_MEMBER_HISTORY_FILTER = 'TEAM_MEMBER_HISTORY/SET_FILTER';
export const SET_TEAM_MEMBER_HISTORY_FILTER_CRITERIA = 'TEAM_MEMBER_HISTORY/SET_FILTER_CRITERIA';
export const CLEAR_TEAM_MEMBER_HISTORY_STATE = 'TEAM_MEMBER_HISTORY/CLEAR_STATE';

export const SET_BATCH_ITEMS_UPDATES_CATEGORIES = 'BATCH_ITEM_UPDATES/SET_LOOSE_ITEM_CATEGORIES';
export const SET_BATCH_ITEMS_UPDATES_FIELDS = 'BATCH_ITEM_UPDATES/SET_CUSTOM_FIELDS';
export const SET_BATCH_ITEMS_UPDATES_LOOSE_ITEMS = 'BATCH_ITEM_UPDATES/SET_LOOSE_ITEMS';
export const SAVE_BATCH_ITEMS_UPDATES_FORM_STATE = 'BATCH_ITEM_UPDATES/SAVE_FORM_STATE';
export const CLEAR_BATCH_ITEMS_UPDATES_STATE = 'BATCH_ITEM_UPDATES/CLEAR_STATE';

export const SET_SEARCH_QUERY = 'SEARCH_RESULTS/SET_SEARCH_QUERY';
export const SET_SEARCH_RESULTS = 'SEARCH_RESULTS/SET_RESULTS';
export const SET_SEARCH_DATA_LOADED_FLAG = 'SEARCH_RESULTS/SET_LOADED_FLAG';
export const CLEAR_SEARCH_RESULTS_STATE = 'SEARCH_RESULTS/CLEAR_STATE';

export const SET_NOTIFICATION_CENTER_TAB = 'NOTIFICATION_CENTER/SET_TAB';
export const CLEAR_NOTIFICATION_CENTER_STATE = 'NOTIFICATION_CENTER/CLEAR_STATE';

export const SET_TICKETS_DASHBOARD_TAB = 'TICKETS_DASHBOARD/SET_TAB';
export const SET_TICKETS_DASHBOARD_TAB_CONFIG = 'TICKETS_DASHBOARD/SET_TAB_CONFIG';
export const SET_TICKETS_DASHBOARD_TAB_FILTER_CRITERIA =
  'TICKETS_DASHBOARD/SET_TAB_FILTER_CRITERIA';
export const SET_TICKETS_DASHBOARD_BASE_STATISTICS = 'TICKETS_DASHBOARD/SET_BASE_STATISTICS';
export const SET_TICKETS_DASHBOARD_TURNOVER_STATISTICS =
  'TICKETS_DASHBOARD/SET_TURNOVER_STATISTICS';
export const SET_TICKETS_DASHBOARD_FLOW_STATISTICS = 'TICKETS_DASHBOARD/SET_FLOW_STATISTICS';
export const CLEAR_TICKETS_DASHBOARD_TAB_STATE = 'TICKETS_DASHBOARD/CLEAR_TAB_STATE';
export const CLEAR_TICKETS_DASHBOARD_STATE = 'TICKETS_DASHBOARD/CLEAR_STATE';

export const SET_ASSETS_DASHBOARDS_TAB = 'ASSETS_DASHBOARDS/SET_TAB';
export const SET_ASSETS_DASHBOARDS_TAB_CONFIG = 'ASSETS_DASHBOARDS/SET_TAB_CONFIG';
export const SET_ASSETS_DASHBOARDS_VERIFICATION_STATISTIC =
  'ASSETS_DASHBOARDS/SET_VERIFICATION_STATISTIC';
export const SET_ASSETS_DASHBOARDS_FILTER_CRITERIA = 'ASSETS_DASHBOARDS/SET_FILTER_CRITERIA';
export const SET_ASSETS_DASHBOARDS_INSPECTION_STATISTIC =
  'ASSETS_DASHBOARDS/SET_INSPECTION_STATISTIC';
export const CLEAR_ASSETS_DASHBOARDS_STATE = 'ASSETS_DASHBOARDS/CLEAR_STATE';
export const CLEAR_ASSETS_DASHBOARDS_TAB_STATE = 'ASSETS_DASHBOARDS/CLEAR_TAB_STATE';

export const SET_MANAGE_LOOSE_ITEMS_ACTIVE_ENTITY_DATA = 'MANAGE_LOOSE_ITEMS/SET_ENTITY_DATA';
export const CLEAR_MANAGE_LOOSE_ITEMS_STATE = 'MANAGE_LOOSE_ITEMS/CLEAR_STATE';

export const SET_TICKET_TYPES_FILTER = 'TICKET_TYPES/SET_FILTER';
export const SET_TICKET_TYPE_CREATION_FORM = 'TICKET_TYPES/SET_CREATION_FORM';
export const CLEAR_TICKET_TYPES_STATE = 'TICKET_TYPES/CLEAR_STATE';

export const SET_ROOT_CAUSES_FILTER = 'ROOT_CAUSES/SET_FILTER';
export const SET_ROOT_CAUSE_CREATION_FORM = 'ROOT_CAUSES/SET_CREATION_FORM';
export const CLEAR_ROOT_CAUSES_STATE = 'ROOT_CAUSES/CLEAR_STATE';

export const SET_TM_DASHBOARD_TAB_CONFIG = 'TM_DASHBOARD/SET_TAB_CONFIG';
export const SET_TM_DASHBOARD_FILTER_CRITERIA = 'TM_DASHBOARD/SET_FILTER_CRITERIA';
export const SET_TM_DASHBOARD_R_AND_R_DASHBOARD = 'TM_DASHBOARD/SET_R_&_R_DASHBOARD';
export const SET_TM_DASHBOARD_LEVELS_DASHBOARD = 'TM_DASHBOARD/SET_LEVELS_DASHBOARD';
export const SET_TM_DASHBOARD_SNAPSHOT_DASHBOARD = 'TM_DASHBOARD/SET_SNAPSHOT_DASHBOARD';
export const SET_TM_DASHBOARD_TAB = 'TM_DASHBOARD/SET_TAB';
export const CLEAR_TM_DASHBOARD_STATE = 'TM_DASHBOARD/CLEAR_STATE';
export const CLEAR_TM_DASHBOARD_TAB_STATE = 'TM_DASHBOARD/CLEAR_TAB_STATE';

export const SET_BOL_LOGS_FILTER = 'BOL_LOGS/SET_FILTER';
export const SET_BOL_LOGS_CREATION_FORM = 'BOL_LOGS/SET_CREATION_FORM';
export const SET_BOL_LOGS_FILTER_CRITERIA = 'BOL_LOGS/SET_FILTER_CRITERIA';
export const CLEAR_BOL_LOGS_STATE = 'BOL_LOGS/CLEAR_STATE';

export const SET_UPLOAD_BOL_LOGS_CONFIGURATION = 'UPLOAD_BOL_LOGS/SET_CONFIGURATION';
export const CLEAR_UPLOAD_BOL_LOGS_STATE = 'UPLOAD_BOL_LOGS/CLEAR_STATE';

export const SET_REPORT_TEMPLATES_FILTER = 'REPORT_TEMPLATES/SET_FILTER';
export const SET_REPORT_TEMPLATES_TYPES = 'REPORT_TEMPLATES/SET_TYPES';
export const CLEAR_REPORT_TEMPLATES_STATE = 'REPORT_TEMPLATES/CLEAR_STATE';

export const SET_ACTIVE_LOCATIONS_MAP_FILTER = 'ACTIVE_LOCATIONS_MAP/SET_FILTER';
export const SET_ACTIVE_LOCATIONS_MAP_FILTER_CRITERIA = 'ACTIVE_LOCATIONS_MAP/SET_FILTER_CRITERIA';
export const CLEAR_ACTIVE_LOCATIONS_MAP_STATE = 'ACTIVE_LOCATIONS_MAP/CLEAR_STATE';
