import { useSelector } from 'react-redux';
import { selectSearchValueData } from 'pages/LooseItems/BOMConfigurations/selectors';

export function useBOMConfigurationSelector() {
  const searchValue = useSelector(selectSearchValueData());

  return {
    searchValue
  };
}
