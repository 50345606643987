import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import styles from './FormMultiSelect.module.scss';
import clsx from 'clsx';
import Multiselect from 'components/Multiselect/Multiselect';

export default function FormMultiSelect({
  name,
  options,
  onSelect,
  isDisabled,
  classes,
  selectAll,
  trackById,
  clearable
}) {
  const { control } = useFormContext();

  const handleOptionSelect = (e, name) => {
    const value = trackById
      ? [...e.target.value]
      : [...e.target.value.map((id) => options.find((item) => item.id === id))];
    onSelect(name, value);
  };

  const clearValue = () => {
    onSelect(name, null);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value }, fieldState: { error } }) => (
        <div className={clsx(!!classes && classes, styles.inputWrapper)}>
          <Multiselect
            name={name}
            valuesArray={trackById ? value || [] : value?.map(({ id }) => id) || []}
            options={options}
            onFilterSelect={handleOptionSelect}
            isInvalid={!!error}
            disabled={isDisabled}
            selectAll={selectAll}
            clearable={clearable}
            onClear={clearValue}
          />
          {!!error && <span>{error.message}</span>}
        </div>
      )}
    />
  );
}
