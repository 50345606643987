import React, { useEffect, useRef, useState } from 'react';
import { Button, IconButton, Paper, Table, TableContainer } from '@material-ui/core';
import styles from './RequestTable.module.scss';
import RequestTableHead from './RequestTableHead/RequestTableHead';
import RequestTableBody from './RequestTableBody/RequestTableBody';
import EditEntityPopup from '../EditEntityPopup/EditEntityPopup';
import CreateIcon from '@material-ui/icons/Create';
import { DELETE_REQUEST_ORDER_ENTITY } from 'constants/dialogPopupsData';
import { getPermissions, itemStatuses } from '../../../helpers';
import SelectInput from 'components/SelectInput/SelectInput';
import CheckIcon from '@material-ui/icons/Check';
import { useMobileViewport } from 'hooks/useMobileViewport';

function RequestTable({
  requestEntitiesData,
  updateSingleEntity,
  updateMultipleEntities,
  isStatus,
  userRole,
  requestOrderRole,
  setDialogModalData,
  isRequestOrderDeleted,
  isROCreatorUser
}) {
  const isMobile = useMobileViewport();
  const permissions = getPermissions(
    requestOrderRole,
    userRole,
    isStatus,
    isRequestOrderDeleted(),
    isROCreatorUser
  );

  const [status, setStatus] = useState({});

  const [open, setOpen] = useState(false);
  const [popupData, setPopupData] = useState({ isOpen: false });
  const [checked, setChecked] = useState([]);

  // to not clear checked state after clearing a several rows in the popup or changing request order status
  const shouldClearCheckedState = useRef(true);

  useEffect(() => {
    if (shouldClearCheckedState.current) {
      setChecked([]);
    } else {
      shouldClearCheckedState.current = true;
    }
  }, [requestEntitiesData]);

  useEffect(() => {
    if (checked.length === 0) {
      setStatus({});
    }
  }, [checked]);

  const onApply = (data, type) => {
    if (type === 'single') {
      updateSingleEntity(data);
    } else {
      updateMultipleEntities(data);
    }
  };

  const onDelete = (ids) =>
    setDialogModalData({ ...DELETE_REQUEST_ORDER_ENTITY, isOpened: true, deletedRows: ids });

  const handleCheckbox = (e, rowId) => {
    let newCheckedArray = [...checked];
    if (e.target.checked) {
      newCheckedArray.push(rowId);
    } else {
      newCheckedArray = newCheckedArray.filter((id) => id !== rowId);
    }
    setChecked(newCheckedArray);
  };
  const handleAllCheckbox = () => {
    if (checked.length) {
      setChecked([]);
    } else {
      setChecked([...requestEntitiesData.map(({ id }) => id)]);
    }
  };

  const handleEditClick = () => {
    const data = {};
    if (checked.length === 1) {
      const row = requestEntitiesData.find(({ id }) => id === checked[0]);
      data.rowData = { ...row };
      data.entityType = 'single';
      data.ids = [row.id];
    } else {
      data.rowData = {};
      data.entityType = 'multiple';
      data.ids = [...checked];
      data.checkedEntities = [...requestEntitiesData.filter(({ id }) => checked.includes(id))];
    }
    setOpen(true);
    setPopupData(data);
  };

  const selectStatus = (selectedItem) => {
    setStatus(selectedItem.value);
  };

  const changeStatusClick = () => {
    const updatedItems = requestEntitiesData
      .filter(({ id }) => checked.includes(id))
      .map((el) => ({ ...el, status }));
    updateMultipleEntities(updatedItems);
  };

  return (
    <>
      {open && (
        <EditEntityPopup
          open={open}
          setOpen={setOpen}
          data={popupData}
          setData={setPopupData}
          onApply={onApply}
          permissions={permissions}
          shouldClearCheckedState={shouldClearCheckedState}
          displayClearButton={isStatus('Pending')}
        />
      )}
      {!!checked.length && (
        <div className={styles.tableControls}>
          {!isStatus('InDelivery') && (
            <IconButton onClick={handleEditClick} className={styles.note_editor}>
              <CreateIcon />
            </IconButton>
          )}

          {isStatus('InDelivery') && (
            <div className={styles.statusField}>
              <SelectInput
                name="status"
                value={status?.id || ''}
                menuItems={itemStatuses || []}
                onSelect={selectStatus}
              />
              <Button className={styles.status_button} onClick={changeStatusClick}>
                {isMobile ? <CheckIcon /> : <span>Apply</span>}
              </Button>
            </div>
          )}
        </div>
      )}
      <TableContainer component={Paper} className={styles.tableContainer}>
        <Table aria-label="customized table">
          <RequestTableHead
            handleAllCheckbox={handleAllCheckbox}
            isAllChecked={checked.length === requestEntitiesData.length}
            permissions={permissions}
            isTableNotEmpty={!!requestEntitiesData.length}
          />
          <RequestTableBody
            entitiesData={requestEntitiesData}
            setOpen={setOpen}
            setPopupData={setPopupData}
            checked={checked}
            handleCheckbox={handleCheckbox}
            onDelete={onDelete}
            onApply={onApply}
            permissions={permissions}
            isStatus={isStatus}
          />
        </Table>
      </TableContainer>
      {isStatus('Approved') && <label className={styles.hintLabel}>*entities to approve</label>}
    </>
  );
}

export default RequestTable;
