import React, { useRef } from 'react';
import styles from './TicketTables.module.scss';
import { Paper, Table, TableBody, TableContainer } from '@material-ui/core';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useLocationSelector } from '../../../../../hooks/useLocationSelector';
import TablePaginationRefactored from 'components/TablePaginationRefactored/TablePaginationRefactored';
import { LOCATION_DETAILS_OPENED_TICKETS_TABLE_PARAMETER } from 'constants/configTableConstants';
import { useLocationActions } from '../../../../../hooks/useLocationActions';
import { useUserConfig } from 'hooks/useUserConfig';
import { getChangedByPageFilter, getChangedBySortFilter } from 'helpers/SummaryPagesHelpers';
import { getTicketsHeadConfig } from './tableConfig';
import TableHeadComponent from 'components/SummaryComponents/TableHeadComponent/TableHeadComponent';
import TicketTableRow from './TicketTableRow';
import { DownloadCSVButton } from 'components/Buttons';
import { useTicketActions } from 'hooks/Ticket';
import { fileDownload } from 'helpers/AppHelpers';

const TICKET_PARAM = 'opened';

export default function OpenedTicketsTable() {
  const isMobile = useMobileViewport();
  const tableRef = useRef(null);
  const { getTableLimit } = useUserConfig();

  const { openedTicketsList, openedTicketsListFilter } = useLocationSelector();
  const { getOpenedTickets } = useLocationActions();
  const { getSummaryCsvResourceAction } = useTicketActions();

  const emptyResponse = () =>
    !openedTicketsList?.items?.length &&
    openedTicketsListFilter?.pagination?.page === openedTicketsList?.pageNumber;

  const changePage = (page, param) =>
    getOpenedTickets(getChangedByPageFilter(openedTicketsListFilter, page), param);
  const sortColumn = (name) =>
    getOpenedTickets(getChangedBySortFilter(openedTicketsListFilter, name));
  const getUpdatedByLimitFilter = () => ({
    ...openedTicketsListFilter,
    pagination: {
      ...openedTicketsListFilter.pagination,
      page: 1,
      limit: getTableLimit(LOCATION_DETAILS_OPENED_TICKETS_TABLE_PARAMETER)
    },
    isDataReceived: false
  });

  const downloadCsv = () =>
    getSummaryCsvResourceAction({
      ...openedTicketsListFilter,
      filters: { ...openedTicketsListFilter.filters, isOpened: true },
      pagination: null,
      isCSVByLocation: true
    }).then((file) => file?.link && fileDownload(file));

  return (
    <>
      {!isMobile && (
        <div className={styles.header}>
          <DownloadCSVButton onClick={downloadCsv} />
        </div>
      )}
      <TableContainer ref={tableRef} className={styles.table} component={Paper}>
        <Table stickyHeader={!isMobile}>
          <TableHeadComponent
            config={getTicketsHeadConfig(TICKET_PARAM)}
            onSortApply={sortColumn}
            sortRules={openedTicketsListFilter.sortRules}
          />
          <TableBody>
            {openedTicketsList?.items?.length && openedTicketsListFilter?.isDataReceived ? (
              openedTicketsList.items.map((row, index) => (
                <TicketTableRow key={index} row={row} type={TICKET_PARAM} />
              ))
            ) : (
              <StyledTableRow>
                {!!openedTicketsListFilter?.isDataReceived && (
                  <StyledTableCell colSpan={1000} className={styles.empty}>
                    No available data in the table.
                  </StyledTableCell>
                )}
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={styles.footer}>
        <TablePaginationRefactored
          isMobile={isMobile}
          rows={openedTicketsList?.items?.length}
          currentPage={openedTicketsListFilter?.pagination?.page}
          totalPages={openedTicketsListFilter?.pagination?.totalPages}
          selectAllOption
          onPageSelect={changePage}
          pageLimit={openedTicketsListFilter?.pagination?.limit}
          hide={emptyResponse()}
          tableListParameter={LOCATION_DETAILS_OPENED_TICKETS_TABLE_PARAMETER}
          getTableData={() => getOpenedTickets(getUpdatedByLimitFilter())}
          tableRef={tableRef}
        />
      </div>
    </>
  );
}
