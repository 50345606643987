import { useDispatch } from 'react-redux';
import {
  getSinglePrefix,
  deletePrefix,
  getPrefixTypes,
  getAssetFields,
  clearState,
  createPrefix,
  updatePrefix,
  getAssetFieldsDeletePermissions,
  setCreationForm
} from 'actions/prefixActions';
import { setAssetsPrefilter } from 'actions/dashboardActions';
import { getCategoryList } from 'actions/categoryActions';
import { setUnsavedFormData } from 'actions/commonActions';

export function usePrefixActions() {
  const dispatch = useDispatch();

  const getPrefixAction = (id) => dispatch(getSinglePrefix(id));
  const setAssetsPrefilterAction = (options) => dispatch(setAssetsPrefilter(options));
  const deletePrefixAction = (id) => dispatch(deletePrefix(id));

  const getAssetFieldsAction = () => dispatch(getAssetFields());

  const getCreationFormAction = () =>
    Promise.all([
      dispatch(getCategoryList()),
      dispatch(getPrefixTypes()),
      dispatch(getAssetFields())
    ]).then(([categories, prefixTypes, assetFields]) => {
      const creationForm = { categories, prefixTypes, assetFields };
      dispatch(setCreationForm(creationForm));
      return creationForm;
    });

  const createPrefixAction = (data) => dispatch(createPrefix(data));
  const updatePrefixAction = (data) => dispatch(updatePrefix(data));

  const getFieldsDeletePermissionsAction = (prefixId, chipId) =>
    dispatch(getAssetFieldsDeletePermissions(prefixId, chipId));

  const clearStateAction = () => dispatch(clearState());

  const setUnsavedFormDataAction = (flag) => dispatch(setUnsavedFormData(flag));

  return {
    getPrefixAction,
    setAssetsPrefilterAction,
    deletePrefixAction,
    getCreationFormAction,
    createPrefixAction,
    updatePrefixAction,
    getFieldsDeletePermissionsAction,
    setUnsavedFormDataAction,
    getAssetFieldsAction,
    clearStateAction
  };
}
