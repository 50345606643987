import api from '../api';
import { browserHistory } from 'browserHistory';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from 'helpers/AppHelpers';

import {
  SET_ASSETS_FILTER,
  SET_ASSETS_COUNTING,
  SET_CURRENT_ASSET,
  SET_ASSETS_FILTER_CRITERIA,
  SET_ASSETS_TABLE_FIELDS,
  SET_ASSET_CREATION_FORM_DATA,
  SET_ASSET_INSPECTION_STATUSES,
  SET_ASSET_LOCATION_HISTORY_DATA,
  SET_INSPECTION_HISTORY_DATA,
  SET_VERIFICATION_HISTORY_DATA,
  CLEAR_ASSETS_STATE,
  SET_LOAD_MORE_FLAG,
  SET_ASSET_OPENED_TICKETS,
  SET_ASSET_TABLES_SELECTED_TAB,
  SET_ASSET_LOCATION_HISTORY_FILTER,
  SET_ASSET_INSPECTION_HISTORY_FILTER,
  SET_ASSET_VERIFICATION_HISTORY_FILTER,
  SET_ASSET_CLOSED_TICKETS,
  SET_ASSET_OPENED_TICKETS_FILTER,
  SET_ASSET_CLOSED_TICKETS_FILTER,
  CLEAR_FULL_ASSETS_STATE,
  SET_ASSET_LOOSE_ITEMS,
  SET_ASSET_LOOSE_ITEMS_FILTER
} from 'constants/reduceConstants';

import {
  ERROR_FIND_ASSET,
  SUCCESS_CREATE_ASSET,
  SUCCESS_DELETE_ASSET,
  SUCCESS_UPDATE_ASSET,
  SUCCESS_INSPECTED_STATUS,
  SUCCESS_NEED_TO_BE_INSPECTED_STATUS,
  SUCCESS_VERIFIED_STATUS,
  TRY_AGAIN_MESSAGE
} from '../constants/infoSnackbarData';

import { ASSET_DETAILED_PATH, ASSETS_LIST_PATH } from '../constants/routeConstants';

export const getAssetsList = (query) => () =>
  api.asset
    .getAssetsListApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getAssetsForQrPopup = (query) => () =>
  api.asset
    .getAssetsListApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getAssetsCounting = (query) => (dispatch) =>
  api.asset
    .getAssetsCountingApi(query)
    .then((res) => dispatch({ type: SET_ASSETS_COUNTING, payload: res }))
    .catch((err) => console.log(err));

export const updateAssetFromList = (data) => () =>
  api.asset.updateAssetFromListApi(data).then(
    (res) => res,
    () => enqueueErrorSnackbar(TRY_AGAIN_MESSAGE)
  );

export const updateAssetField = (data) => () =>
  api.asset.updateAssetFieldApi(data).then(
    (res) => res,
    () => enqueueErrorSnackbar(TRY_AGAIN_MESSAGE)
  );

export const getAsset = (id) => () =>
  api.asset
    .getAssetApi(id)
    .then((res) => res)
    .catch(() => {
      enqueueErrorSnackbar(ERROR_FIND_ASSET);
      browserHistory.push(ASSETS_LIST_PATH);
    });

export const setAsset = (data) => (dispatch) =>
  dispatch({ type: SET_CURRENT_ASSET, payload: data });

export const getNeighboringAssets = (q) => () =>
  api.asset
    .getNeighboringAssetsApi(q)
    .then((res) => res)
    .catch((err) => console.log(err));

export const createAsset = (data) => () => {
  return api.asset.createAssetApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_CREATE_ASSET);
      browserHistory.push(`${ASSET_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const updateAsset = (data) => () => {
  return api.asset.updateAssetApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_UPDATE_ASSET);
      browserHistory.push(`${ASSET_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const restoreAsset = (data) => (dispatch) =>
  api.asset.updateAssetApi(data).then(
    (res) => {
      dispatch({ type: SET_CURRENT_ASSET, payload: res });
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar(errors);
      return errors;
    }
  );

export const deleteAsset = (id) => () =>
  api.asset.deleteAssetApi(id).then(
    () => {
      enqueueSuccessSnackbar(SUCCESS_DELETE_ASSET);
      browserHistory.push(ASSETS_LIST_PATH);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );

export const getFilterCriteria = () => (dispatch) =>
  api.asset
    .getFilterCriteriaApi()
    .then((res) => {
      const sublocations = res?.locations?.flatMap(({ id, sublocations }) =>
        sublocations.map((el) => ({ ...el, parentLocationId: id }))
      );

      const assetPrefixes = res?.equipments?.map((item) => item.assetPrefixes) || [];
      const prefixes = [].concat.apply([], assetPrefixes);
      const sortedPrefixes = prefixes?.sort((a, b) => a.prefix.localeCompare(b.prefix)) || [];

      const options = { ...res, sublocations, prefixes: sortedPrefixes };

      dispatch({ type: SET_ASSETS_FILTER_CRITERIA, payload: options });
      return options;
    })
    .catch((err) => console.log(err));

export const getAssetsTableFields = (query) => (dispatch) =>
  api.asset
    .getAssetsTableFieldsApi(query)
    .then((res) => {
      dispatch({ type: SET_ASSETS_TABLE_FIELDS, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const getAssetInspectionStatuses = () => (dispatch) => {
  api.asset
    .getAssetInspectionStatusesApi()
    .then((res) => dispatch({ type: SET_ASSET_INSPECTION_STATUSES, payload: res }))
    .catch((err) => console.log(err));
};

const checkInspectionStatusResponse = (data) => {
  switch (data.inspectionStatus.name) {
    case 'Inspected':
      enqueueSuccessSnackbar(SUCCESS_INSPECTED_STATUS);
      break;
    case 'NeedToBeInspected':
      enqueueSuccessSnackbar(SUCCESS_NEED_TO_BE_INSPECTED_STATUS);
      break;
    case 'Verified':
      enqueueSuccessSnackbar(SUCCESS_VERIFIED_STATUS);
      break;
    default:
      break;
  }
};

export const setAssetInspectionStatus = (data) => () =>
  api.asset.setAssetInspectionStatusApi(data).then(
    () => checkInspectionStatusResponse(data),
    () => enqueueErrorSnackbar(TRY_AGAIN_MESSAGE)
  );

export const setAssetsInspectionStatus = (data) => () =>
  api.asset
    .setAssetsInspectionStatusApi(data)
    .then((res) => {
      checkInspectionStatusResponse(data);
      return res;
    })
    .catch(() => enqueueErrorSnackbar(TRY_AGAIN_MESSAGE));

export const getLocationHistoryData = (query, param) => (dispatch) =>
  api.asset
    .getLocationHistoryDataApi(query)
    .then((res) => {
      const isAutoload = param === 'AUTOLOAD';
      dispatch({ type: SET_ASSET_LOCATION_HISTORY_DATA, payload: { data: res, isAutoload } });
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));

export const getInspectionHistoryData = (query, param) => (dispatch) =>
  api.asset
    .getInspectionHistoryDataApi(query)
    .then((res) => {
      const isAutoload = param === 'AUTOLOAD';
      dispatch({ type: SET_INSPECTION_HISTORY_DATA, payload: { data: res, isAutoload } });
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));

export const getVerificationHistoryData = (query, param) => (dispatch) =>
  api.asset
    .getVerificationHistoryDataApi(query)
    .then((res) => {
      const isAutoload = param === 'AUTOLOAD';
      dispatch({ type: SET_VERIFICATION_HISTORY_DATA, payload: { data: res, isAutoload } });
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));

export const getCreationForm = () => (dispatch) =>
  api.asset
    .getCreationFormApi()
    .then((res) => {
      dispatch({ type: SET_ASSET_CREATION_FORM_DATA, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const getAvailableDRCode = (q) => () =>
  api.asset
    .getAvailableDRCodeApi(q)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getAssetOpenedTicketsList = (query, param) => (dispatch) =>
  api.asset
    .getTicketsListApi(query)
    .then((res) => {
      const isAutoload = param === 'AUTOLOAD';
      dispatch({ type: SET_ASSET_OPENED_TICKETS, payload: { data: res, isAutoload } });
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));

export const getClosedTicketsList = (query, param) => (dispatch) =>
  api.asset
    .getTicketsListApi({ ...query, filters: { ...query.filters, isOpened: false } })
    .then((res) => {
      const isAutoload = param === 'AUTOLOAD';
      dispatch({ type: SET_ASSET_CLOSED_TICKETS, payload: { data: res, isAutoload } });
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));

export const getLooseItemsList = (query, param) => (dispatch) =>
  api.asset
    .getLooseItemsListApi(query)
    .then((res) => {
      const isAutoload = param === 'AUTOLOAD';
      dispatch({ type: SET_ASSET_LOOSE_ITEMS, payload: { data: res, isAutoload } });
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));

export const getSummaryCsvResource = (q) => () =>
  api.asset
    .getSummaryCsvResourceApi(q)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getLocationHistoryCsv = (q) => () =>
  api.asset
    .getLocationHistoryCsvApi(q)
    .then((res) => res)
    .catch((err) => console.log(err));
export const getInspectionVerificationHistoryCsv = (q) => () =>
  api.asset
    .getInspectionVerificationHistoryCsvApi(q)
    .then((res) => res)
    .catch((err) => console.log(err));

export const setTablesTab = (payload) => (dispatch) => {
  dispatch({ type: SET_ASSET_TABLES_SELECTED_TAB, payload });
};

export const setLocationHistoryFilter = (payload) => (dispatch) => {
  dispatch({ type: SET_ASSET_LOCATION_HISTORY_FILTER, payload });
};
export const setInspectionHistoryFilter = (payload) => (dispatch) => {
  dispatch({ type: SET_ASSET_INSPECTION_HISTORY_FILTER, payload });
};
export const setVerificationHistoryFilter = (payload) => (dispatch) => {
  dispatch({ type: SET_ASSET_VERIFICATION_HISTORY_FILTER, payload });
};

export const setOpenedTicketsFilter = (payload) => (dispatch) => {
  dispatch({ type: SET_ASSET_OPENED_TICKETS_FILTER, payload });
};
export const setClosedTicketsFilter = (payload) => (dispatch) => {
  dispatch({ type: SET_ASSET_CLOSED_TICKETS_FILTER, payload });
};
export const setLooseItemsFilter = (payload) => (dispatch) => {
  dispatch({ type: SET_ASSET_LOOSE_ITEMS_FILTER, payload });
};

export const setAssetFilter = (payload) => (dispatch) => {
  dispatch({ type: SET_ASSETS_FILTER, payload });
};

export const clearAssetsState = () => (dispatch) => {
  dispatch({ type: CLEAR_ASSETS_STATE });
  dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
};

export const clearAllAssetState = () => (dispatch) => {
  dispatch({ type: CLEAR_FULL_ASSETS_STATE });
  dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
};
