import { useDispatch } from 'react-redux';
import {
  getLooseItems,
  getLooseItemCounting,
  setFilter,
  getFilterCriteria,
  clearState,
  createLooseItem,
  deleteLooseItem,
  getCategories,
  getCustomFields,
  getLocationsForCreateScreen,
  getLooseItem,
  setCustomColumns,
  updateLooseItem,
  setCheckedItems,
  updateLooseItemTableRow,
  getSummaryCsvResource
} from 'actions/looseItemActions';

import { setUnsavedFormData } from 'actions/commonActions';

export function useLooseItemActions() {
  const dispatch = useDispatch();

  const getLooseItemsAction = (q) => dispatch(getLooseItems(q));
  const getCountingAction = (q) => dispatch(getLooseItemCounting(q));

  const selectRowAction = (data) => dispatch(setCheckedItems(data));

  const setFilterAction = (data) => dispatch(setFilter(data));
  const getFilterCriteriaAction = () => dispatch(getFilterCriteria());

  const getItemAction = (id) => dispatch(getLooseItem(id));
  const createItemAction = (data, q) => dispatch(createLooseItem(data, q));
  const updateLooseItemAction = (data) => dispatch(updateLooseItem(data));
  const deleteItemAction = (id) => dispatch(deleteLooseItem(id));
  const clearStateAction = () => dispatch(clearState());

  const updateLooseItemTableRowAction = (data) => dispatch(updateLooseItemTableRow(data));

  const setUnsavedFormDataAction = (flag) => dispatch(setUnsavedFormData(flag));

  const getLocationListAction = (query) => dispatch(getLocationsForCreateScreen(query));
  const getCategoriesAction = () => dispatch(getCategories());
  const getLooseItemFieldsAction = (query, options) => dispatch(getCustomFields(query, options));

  const setCustomColumnsAction = (arr) => dispatch(setCustomColumns(arr));

  const getSummaryCsvResourceAction = (q) => dispatch(getSummaryCsvResource(q));

  return {
    getLooseItemsAction,
    getCountingAction,
    selectRowAction,
    setFilterAction,
    getFilterCriteriaAction,
    updateLooseItemTableRowAction,
    getItemAction,
    createItemAction,
    updateLooseItemAction,
    deleteItemAction,
    clearStateAction,
    setUnsavedFormDataAction,
    getLocationListAction,
    getCategoriesAction,
    getLooseItemFieldsAction,
    setCustomColumnsAction,
    getSummaryCsvResourceAction
  };
}
