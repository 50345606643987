// this const was created to handle when tabs should be displayed and which tab is active
const defaultTabState = { active: false, selected: '' };

// this is for tabs state
const LOOSE_ITEMS_TAB_VALUE = 'LOOSE_ITEMS';
const ASSET_TAB_VALUE = 'ASSETS';
const TOGGLE_BUTTONS = [
  { id: 1, value: LOOSE_ITEMS_TAB_VALUE, label: 'Loose Items' },
  { id: 2, value: ASSET_TAB_VALUE, label: 'Assets' }
];

// this error message is displayed when assets table has empty empty asset rows
const ASSETS_TABLE_ERROR_MESSAGE = 'Not enough assets';

const isValidNumber = (max) => typeof max === 'number' && max >= 1 && !isNaN(max);

// to generate dropdown options for autocomplete field
const defaultQuantityValue = { id: 1, name: '1', quantity: 1 };
const generateDisplayedQuantityOption = (qty) => ({ id: qty, name: qty.toString(), quantity: qty });
const generateQuantityOptions = (max) => {
  return Array.from({ length: max }, (_, i) => ({
    id: i + 1,
    name: `${i + 1}`,
    quantity: i + 1
  }));
};

// to validate the entire rows of loose items
const setLooseItemSectionId = (id) => `id_${id}`;
const getLooseItemSectionErrorName = (id) => `looseItemIds.${setLooseItemSectionId(id)}`;
// to validate selected quantities and by ahead account
const getLocationSelectedQuantityErrorName = (rowId) => `looseItems.${rowId}.selectedQuantity`;
const getLocationSelectedBaaErrorName = (rowId) => `looseItems.${rowId}.selectedByAheadAccount`;

// error messages for loose items table
const REQUIRED_EXCEEDS_SELECTED_ERROR =
  'The required value exceeds selected value. Please, change the configuration value.';
const SELECTED_EXCEEDS_AVAILABLE_ERROR = 'Exceeds maximum value.';
const SUM_DOES_NOT_MATCH_REQUIRED_ERROR = `Sum of selected quantity doesn't match the required.`;
const BAA_EXCEEDS_SELECTED_ERROR = '“From BAA*” value must not exceed “Selected quantity”';
const BAA_EXCEEDS_AVAILABLE_BAA_ERROR = 'The “From BAA” value must not exceed “Available BAA QTY”.';

// types to handle clearing rows in loose items table
const ERROR_RESET_TYPES = {
  CHANGE_LOCATION: 'CHANGE_LOCATION',
  REMOVE_TABLE_ROW: 'REMOVE_TABLE_ROW',
  ADD_TABLE_ROW: 'ADD_TABLE_ROW',
  CHANGE_SELECTED_QUANTITY: 'CHANGE_SELECTED_QUANTITY',
  CHANGE_SELECTED_BAA: 'CHANGE_SELECTED_BAA'
};

// this const was created to write default values in case when user change configuration, config quantity and add row
const DEFAULT_QUANTITIES = { selectedQuantity: 0, selectedByAheadAccount: 0 };

// default value for config quantity
const DEFAULT_MULTIPLIER = 1;
const transformLooseItems = (looseItems, multiplier = DEFAULT_MULTIPLIER) =>
  looseItems.flatMap(({ requiredQuantity, locations }) =>
    locations.map((item) => ({
      ...item,
      requiredQuantity: requiredQuantity * multiplier,
      ...DEFAULT_QUANTITIES
    }))
  );

const transformAssetPrefixes = (data, multiplier = DEFAULT_MULTIPLIER) => {
  return data.flatMap(({ requiredQuantity, id, name, prefix, assets }) => {
    const quantity = requiredQuantity * multiplier;
    return Array.from({ length: quantity }, () => ({
      assets,
      selectedAsset: {},
      requiredQuantity: quantity,
      assetPrefix: {
        id,
        name,
        prefix
      }
    }));
  });
};

// validation rules for general fields component
const getValidationFields = (values) => {
  const { name, looseItemConfiguration, location, selectedQuantityOption } = values;

  return [
    {
      fieldName: 'looseItemConfiguration',
      errorMessage: 'Configuration field is required',
      isInvalid: !looseItemConfiguration?.id,
      selector: `input[id="looseItemConfiguration"]`
    },
    {
      fieldName: 'name',
      errorMessage: 'Assembly name field is required',
      isInvalid: !name,
      selector: `input[name="name"]`
    },
    {
      fieldName: 'location',
      errorMessage: 'Location field is required',
      isInvalid: !location?.id,
      selector: `input[id="location"]`
    },
    {
      fieldName: 'selectedQuantityOption',
      errorMessage: 'Quantity field is required',
      isInvalid: !selectedQuantityOption?.id,
      selector: `input[id="selectedQuantityOption"]`
    }
  ];
};

// function to prepare assets for sending them to the backend
const groupAssetsByPrefix = (input) => {
  const result = [];

  input.forEach((item) => {
    const { assetPrefix, selectedAsset } = item;

    let group = result.find((g) => g.id === assetPrefix.id);

    if (!group) {
      group = {
        id: assetPrefix.id,
        name: assetPrefix.name,
        prefix: assetPrefix.prefix,
        assets: []
      };
      result.push(group);
    }

    group.assets.push(selectedAsset);
  });

  return result;
};

// function to prepare loose items for sending them to the backend
const groupLocationsByLooseItem = (input) => {
  const result = [];

  input.forEach((item) => {
    const { looseItem } = item;

    let group = result.find((g) => g.id === looseItem.id);

    if (!group) {
      group = {
        id: looseItem.id,
        name: looseItem.name,
        locations: []
      };
      result.push(group);
    }

    group.locations.push(item);
    group.locations = group.locations.filter(({ selectedQuantity }) => selectedQuantity);
  });

  return result;
};

// convert function to prepare payload for the backend
const transformPayload = ({
  looseItemConfiguration,
  location,
  sublocation,
  assetPrefixes,
  looseItems,
  name,
  notes,
  selectedQuantityOption
}) => {
  return {
    location,
    sublocation,
    name,
    notes,
    looseItemConfiguration: { id: looseItemConfiguration.id, name: looseItemConfiguration.name },
    quantity: selectedQuantityOption.quantity,
    looseItems: groupLocationsByLooseItem(looseItems),
    assetPrefixes: groupAssetsByPrefix(assetPrefixes)
  };
};

export {
  defaultTabState,
  LOOSE_ITEMS_TAB_VALUE,
  ASSET_TAB_VALUE,
  TOGGLE_BUTTONS,
  ASSETS_TABLE_ERROR_MESSAGE,
  isValidNumber,
  defaultQuantityValue,
  generateDisplayedQuantityOption,
  generateQuantityOptions,
  setLooseItemSectionId,
  getLooseItemSectionErrorName,
  getLocationSelectedQuantityErrorName,
  getLocationSelectedBaaErrorName,
  REQUIRED_EXCEEDS_SELECTED_ERROR,
  SELECTED_EXCEEDS_AVAILABLE_ERROR,
  SUM_DOES_NOT_MATCH_REQUIRED_ERROR,
  BAA_EXCEEDS_SELECTED_ERROR,
  BAA_EXCEEDS_AVAILABLE_BAA_ERROR,
  ERROR_RESET_TYPES,
  DEFAULT_QUANTITIES,
  transformLooseItems,
  transformAssetPrefixes,
  getValidationFields,
  transformPayload
};
