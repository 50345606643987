import api from '../api';

import {
  SET_CURRENT_BOM_CONFIGURATION,
  CLEAR_BOM_CONFIGURATIONS_STATE,
  SET_BOM_CONFIGURATIONS_SEARCH_VALUE,
  SET_BOM_CONFIGURATIONS_CREATION_FORM
} from 'constants/reduceConstants';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../helpers/AppHelpers';
import {
  SUCCESS_CREATE_BOM_CONFIGURATION,
  SUCCESS_UPDATE_BOM_CONFIGURATION,
  SUCCESS_DELETE_BOM_CONFIGURATION
} from '../constants/infoSnackbarData';
import { browserHistory } from '../browserHistory';
import {
  BOM_CONFIGURATION_DETAILED_PATH,
  BOM_CONFIGURATIONS_LIST_PATH
} from '../constants/routeConstants';

export const getConfigurationList = () => () =>
  api.bomConfigurations
    .getConfigurationListApi()
    .then((res) => res)
    .catch((err) => console.log(err));

export const setSearchValue = (value) => (dispatch) =>
  dispatch({ type: SET_BOM_CONFIGURATIONS_SEARCH_VALUE, payload: value });

export const getSingleConfiguration = (id) => (dispatch) =>
  api.bomConfigurations
    .getSingleConfigurationApi(id)
    .then((res) => dispatch({ type: SET_CURRENT_BOM_CONFIGURATION, payload: res }))
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      browserHistory.push(BOM_CONFIGURATIONS_LIST_PATH);
    });

export const createConfiguration = (data) => () =>
  api.bomConfigurations.createConfigurationApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_CREATE_BOM_CONFIGURATION);
      browserHistory.push(`${BOM_CONFIGURATION_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );

export const updateConfiguration = (data) => () =>
  api.bomConfigurations.updateConfigurationApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_UPDATE_BOM_CONFIGURATION);
      browserHistory.push(`${BOM_CONFIGURATION_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );

export const deleteConfiguration = (id) => () =>
  api.bomConfigurations.deleteConfigurationApi(id).then(
    () => {
      enqueueSuccessSnackbar(SUCCESS_DELETE_BOM_CONFIGURATION);
      browserHistory.push(BOM_CONFIGURATIONS_LIST_PATH);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );

export const getCreationForm = () => (dispatch) =>
  api.bomConfigurations
    .getCreationFormApi()
    .then((res) => dispatch({ type: SET_BOM_CONFIGURATIONS_CREATION_FORM, payload: res }))
    .catch((err) => console.log(err));

export const clearState = () => (dispatch) => {
  dispatch({ type: CLEAR_BOM_CONFIGURATIONS_STATE });
};
