import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useAssembledBOMActions, useAssembledBOMSelector } from 'hooks/AssembledBOM';

import Wrapper from './Wrapper';
import Form from './Form';
import { generateDisplayedQuantityOption } from './helpers';

export function AssembleEdit() {
  const { id } = useParams();

  const methods = useForm({
    defaultValues: {
      // quantity field properties
      quantityOptions: [],
      selectedQuantityOption: {},
      // assemble properties
      looseItemConfiguration: {},
      quantity: 0,
      name: '',
      location: {},
      sublocation: null,
      notes: '',
      looseItems: [],
      assetPrefixes: []
    },
    mode: 'onChange'
  });
  const { reset } = methods;

  const { currentAssembly, creationForm } = useAssembledBOMSelector();
  const { getSingleAssembleAction, getCreationFormAction, clearStateAction } =
    useAssembledBOMActions();

  useEffect(() => {
    if (id) {
      getSingleAssembleAction(id);
    }
  }, [id]);

  useEffect(() => {
    getCreationFormAction();
  }, []);

  useEffect(() => {
    if (id && currentAssembly?.id && creationForm?.locations?.length) {
      const qtyOption = generateDisplayedQuantityOption(currentAssembly?.quantity || 1);
      reset({
        ...currentAssembly,
        location:
          creationForm.locations?.find(({ id }) => id === currentAssembly.location.id) || {},
        quantityOptions: [qtyOption],
        selectedQuantityOption: qtyOption
      });
    }
  }, [currentAssembly, creationForm]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  return (
    <FormProvider {...methods}>
      <Wrapper>
        <Form />
      </Wrapper>
    </FormProvider>
  );
}
