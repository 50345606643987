import { useSelector } from 'react-redux';

import {
  selectAssetIspectionStatusesData,
  selectAssetsCounting,
  selectAssetsFilterData,
  selectClosedTicketsFilterData,
  selectClosedTicketsListData,
  selectFilterCriteriaData,
  selectInspectionHistoryData,
  selectInspectionHistoryFilterData,
  selectLocationHistoryData,
  selectLocationHistoryFilterData,
  selectOpenedTicketsFilterData,
  selectOpenedTicketsListData,
  selectSelectedTabData,
  selectSingleAssetData,
  selectVerificationHistoryData,
  selectVerificationHistoryFilterData,
  selectCreationFormData,
  selectLooseItemListData,
  selectLooseItemFilterData
} from 'pages/AssetSummary/selectors';

export function useAssetSelector() {
  const counting = useSelector(selectAssetsCounting());

  const currentAsset = useSelector(selectSingleAssetData());

  const creationForm = useSelector(selectCreationFormData());

  const filter = useSelector(selectAssetsFilterData());
  const filterCriteria = useSelector(selectFilterCriteriaData());

  const assetInspectionStatuses = useSelector(selectAssetIspectionStatusesData());

  const selectedTab = useSelector(selectSelectedTabData());

  // history tables
  const locationHistory = useSelector(selectLocationHistoryData());
  const locationHistoryFilter = useSelector(selectLocationHistoryFilterData());

  const inspectionHistory = useSelector(selectInspectionHistoryData());
  const inspectionHistoryFilter = useSelector(selectInspectionHistoryFilterData());

  const verificationHistory = useSelector(selectVerificationHistoryData());
  const verificationHistoryFilter = useSelector(selectVerificationHistoryFilterData());

  // ticket tables
  const openedTickets = useSelector(selectOpenedTicketsListData());
  const openedTicketsFilter = useSelector(selectOpenedTicketsFilterData());

  const closedTickets = useSelector(selectClosedTicketsListData());
  const closedTicketsFilter = useSelector(selectClosedTicketsFilterData());

  // loose items table
  const looseItems = useSelector(selectLooseItemListData());
  const looseItemsFilter = useSelector(selectLooseItemFilterData());

  return {
    counting,
    currentAsset,
    creationForm,
    filter,
    filterCriteria,
    assetInspectionStatuses,
    selectedTab,
    locationHistory,
    locationHistoryFilter,
    inspectionHistory,
    inspectionHistoryFilter,
    verificationHistory,
    verificationHistoryFilter,
    openedTickets,
    openedTicketsFilter,
    closedTickets,
    closedTicketsFilter,
    looseItems,
    looseItemsFilter
  };
}
