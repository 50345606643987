import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { ERROR_CANT_FIND_ASSETS } from 'constants/infoSnackbarData';
import { nanoid } from 'nanoid';
import variables from 'assets/styles/variables.scss';

export const enqueueErrorSnackbar = (message, options) =>
  enqueueSnackbar(message, { variant: 'error', ...options });
export const enqueueSuccessSnackbar = (message, options) =>
  enqueueSnackbar(message, { variant: 'success', ...options });
export const enqueueWarningSnackbar = (message, options) =>
  enqueueSnackbar(message, { variant: 'warning', ...options });

export const getValidationObj = (err) => {
  if (err?.errors)
    return Object.keys(err.errors).reduce((result, key) => {
      const objKey = key.charAt(0).toLowerCase() + key.slice(1);
      if (objKey === 'id') return;
      result[objKey] = err.errors[key];
      return result;
    }, {});
};

export const enrichNameToId = (array) => {
  return (
    array?.map((item) => {
      return { ...item, id: item?.name || item?.text };
    }) || []
  );
};

export const displayNameToName = (array) => {
  return (
    array?.map((item) => {
      return { ...item, name: item.displayName };
    }) || []
  );
};

export const enrichDescriptionToName = (array) => {
  return (
    array?.map((item) => {
      return { ...item, name: item.description };
    }) || []
  );
};

export const fileDownload = async (file) => {
  const blob = await axios.get(file.link, {
    headers: {
      'Content-Type': file['mime']
    },
    responseType: 'blob'
  });
  const a = document.createElement('a');
  a.href = window.URL.createObjectURL(blob.data);
  a.download = file.name;
  a.click();
};

export const getRandomId = () => (new Date().getTime() + Math.random()) * 10000;
export const getUniqueID = () => nanoid();

export const truncateString = (string, length) => {
  return string?.length > length ? string.substring(0, length) + '...' : string;
};

export const getPagesLimit = (config, tableName, isMobile) => {
  const params = config?.['tables']?.find(({ name }) => name === tableName);

  const limit = params?.fetchRowsCount || 10;

  const option100AllowedTables = ['TeamMemberSummary'];
  const isOption100Available = option100AllowedTables.includes(tableName);

  return limit === 100 && isMobile ? (isOption100Available ? 100 : 50) : limit;
};
export const isAllParameterEnabled = (config, tableName) => {
  return !!config?.['tables']?.find(({ name }) => name === tableName)?.isAll;
};

export const queryToString = (query) => {
  return `${qs.stringify(query, {
    allowDots: true,
    indices: false
  })}`;
};

export const reformatInputValue = (value, maxLength, rule) => {
  return value
    .substring(0, maxLength)
    .replace(rule || allKeyboardLatSymbols, '')
    .trimStart();
};
export const reformatNumberValue = (value) => {
  if (!value) return '';

  const numericValue = value.replace(onlyNumbersRegexp, '').trimStart();

  if (numericValue === '0') return 0;

  const cleanedValue = numericValue.replace(/^0+/, '');
  const numberValue = Number(cleanedValue.substring(0, 10));
  return numberValue > maxNumberLength ? maxNumberLength : numberValue;
};
export const reformatFractionValue = (value) => {
  const inputValue = Number(value).toString();
  if (+inputValue > maxNumberLength) return maxNumberLength;
  if (inputValue.includes('.')) {
    const str = inputValue.split('.');
    str[1] = str[1].length > 2 ? str[1].substring(0, 2) : str[1];
    return str[0] + '.' + str[1];
  }
  return inputValue;
};

export const reformatDateByOptions = (day, options) => {
  const date = new Date(removeCharacterZ(day));
  const newDate = date.toLocaleString('en', options);
  return options?.weekday ? date.getDate() + ' ' + newDate : newDate;
};

export const emptyValue = '\u2000';
export const maxNumberLength = 2147483647;

const r1 = /^(([^<>()\]\\.,;:\s@"#&`=а-яА-Я%?]+(\.[^<>()\]\\.,;:\s@"#&`=а-яА-Я%?]+)*)|(".+"))/;
const r2 = /@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const emailRegexp = new RegExp(r1.source + r2.source);
export const onlyNumbersRegexp = /[^0-9]/g;
export const allKeyboardLatSymbols = /[^a-zA-Z0-9\t\n ,./<>?~;:“"'`!@#$%^&*()\[\]{}_+=|\\-]/g;
export const allKeyboardLatSymbolsWithoutSpaces =
  /[^a-zA-Z0-9\t\n,./<>?;:"'`!@#$%^&*()\[\]{}_+=|\\-]/g;

export const startOfCurrentYear = moment().startOf('year').add(-1, 'd');
export const yesterdayDate = moment(new Date()).add(-1, 'd');

export const getDifferenceInDays = (date1, date2) => moment(date2).diff(moment(date1), 'days');

export const dateFormat = 'YYYY-MM-DD';
export const datePickerFormat = 'y-MM-dd';
export const dateTimeFormat = 'YYYY-MM-DD h:mm:ss A';
export const fullDateFormat = 'MMMM DD, YYYY';
export const isDate = (date) => date instanceof Date && !isNaN(date.valueOf());
export const formatDate = (date, format) => moment(date).format(format || dateFormat);
export const areDatesSame = (firstDate, secondDate) => moment(firstDate).isSame(secondDate);
export const removeCharacterZ = (date) => (date.includes('Z') ? date.replace('Z', '') : date);
export const getLastDayOfMonth = (date) => moment(date).endOf('month');
export const getStartDayOfWeek = (date) => moment(date).startOf('week');
export const getDatePickerRange = (current, min, max) =>
  min && max
    ? current.isAfter(min) && current.isBefore(max)
    : min
    ? current.isAfter(min)
    : max
    ? current.isBefore(max)
    : true;
export const getWeekRange = (date) => {
  const startOfWeek = moment(date).startOf('week');
  const endOfWeek = moment(date).endOf('week');
  const monthName = startOfWeek.format('MMMM');
  const startOfWeekDay = startOfWeek.format('D');
  const endOfWeekDay = endOfWeek.format('D');

  return `${monthName} ${startOfWeekDay}-${endOfWeekDay}`;
};
export const getMonthYear = (date) => {
  const monthName = moment(date).format('MMMM');
  const year = moment(date).format('YYYY');

  return `${monthName} ${year}`;
};
export const getYear = (date) => `${moment(date).format('YYYY')}`;

export const isAdminUser = (userRole) => userRole?.id === 1;
export const isFieldTechUser = (userRole) => userRole?.id === 2;
export const isTeamMemberUser = (userRole) => userRole?.id === 3;
export const isOperationsManagerUser = (userRole) => userRole?.id === 4;
export const isPersonnelManagerUser = (userRole) => userRole?.id === 5;
export const isLocationInConfig = (userRole, locations, id) =>
  isAdminUser(userRole) ||
  (!isAdminUser(userRole) && locations?.some((location) => location?.id === +id));
export const isCreatorUser = (entityCreatorEmail, email) => entityCreatorEmail === email;
export const isLocationAssignedToUser = (list, userLocationId) =>
  list?.some((location) => location?.id === +userLocationId);

export const isDecisionMakerUser = (role) => role?.name === 'DecisionMaker';
export const isResponsibleForAccountUser = (role) => role?.name === 'ResponsibleForAccount';

export const getProjectName = (project) => {
  if (project?.jobNumber && project?.name) return project.jobNumber + ' ' + project.name;
  else if (project?.name) return project.name;
  else return '';
};

export const getLocationWithSublocationAndAssetName = ({
  location = {},
  sublocation = {},
  containedInAsset = {}
}) => {
  const locationLabel = location?.siteCode || '';
  const sublocationLabel = sublocation?.id ? `(${sublocation.name})` : '';
  const assetLabel = containedInAsset?.id ? `[${containedInAsset.drCode}]` : '';

  return (
    [locationLabel, sublocationLabel, assetLabel]
      // do map to avoid unnecessary space if sublocation is empty (e.g. SiteCode  [drCode])
      .map((label) => label.trim())
      .filter(Boolean)
      .join(' ')
  );
};

export const getAddress = (address1, address2) =>
  address1 && address2 ? address1 + ' ' + address2 : address1 || address2 || '';
export const getUserFullName = (user) => {
  if (user?.firstName && user?.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }
  if (user?.firstName) {
    return user.firstName;
  }
  return '';
};
export const getDate = (date, rule) => (date ? formatDate(date, rule) : '');
export const getTeamMemberFullName = (teamMember) =>
  teamMember?.lastName && teamMember?.firstName
    ? teamMember.lastName + ', ' + teamMember.firstName
    : teamMember?.lastName || teamMember?.firstName || '';
export const getLocationWithSublocationName = (location, sublocation) =>
  sublocation?.id ? location?.siteCode + ' (' + sublocation.name + ')' : location?.siteCode || '';
export const getQtyWithBaaValue = (qty, baa) => (baa ? qty + '(' + baa + ')' : qty || 0);

export const areDatesAvailable = (date1, date2) => date1 || date2;
export const getDatesLabel = (date1, date2) =>
  date1 && date2
    ? `${formatDate(date1)} to ${formatDate(date2)}`
    : date1 || date2
    ? `${date1 ? formatDate(date1) : ''}${date2 ? 'To ' + formatDate(date2) : ''}`
    : '';

export const getBackendErrors = (err) => {
  const getKey = (key) => key.charAt(0).toLowerCase() + key.slice(1);
  if (err?.errors) {
    return Object.keys(err.errors)
      .map((key) => {
        if (getKey(key) === 'id') return;
        return {
          type: 'custom',
          name: getKey(key),
          message: err.errors[key][0]
        };
      })
      .filter(Boolean);
  }
};

export const getClearedFilterByUnmount = (filter, isAutoloadAllowed = true) => ({
  ...filter,
  pagination: {
    ...filter.pagination,
    page: filter.pagination.limit === 100 && isAutoloadAllowed ? 1 : filter.pagination.page
  },
  responseReceived: false
});

export const getOptionsWithHyphen = (items) => {
  return items?.length ? [...items, { id: '-', name: '-', displayName: '-' }] : [];
};

export const getAssetIdFromUrl = (url) => {
  const assetURLArray = url.split('/');
  return assetURLArray[assetURLArray.length - 1];
};

export const getErrorFindAssetMessage = (drCode) => ERROR_CANT_FIND_ASSETS + ' ' + drCode;

export const getErrorAssetInPicklistMessage = (drCode) =>
  `Can’t add the asset. Asset ${drCode} is in Picklist`;

export const getErrorAssetInLeavelistMessage = (drCode) =>
  `Can’t add the asset. Asset ${drCode} is in Leavelist`;

export const getErrorAssetIsNotAvailableMessage = (drCode) =>
  `Can’t add the asset. Status of Asset ${drCode} is not “Available”.`;

export const sortByParam = (arr, param) => {
  const copy = cloneObj(arr);
  copy.sort((a, b) => {
    const paramA = a[param].toUpperCase();
    const paramB = b[param].toUpperCase();

    if (paramA < paramB) {
      return -1;
    }
    if (paramA > paramB) {
      return 1;
    }
    return 0;
  });

  return copy;
};

export const doNotDriveId = 1;
export const doDriveId = 2;
export const driveStatuses = [
  { id: doNotDriveId, displayName: 'Do Not Drive', name: 'doNotDrive', value: false },
  { id: doDriveId, displayName: 'Do Drive', name: 'doDrive', value: true }
];

export const wait = async (milliseconds) =>
  new Promise((resolve) => setTimeout(resolve, milliseconds));
export const pluck = (objs, key) => objs.map((obj) => obj[key]);
export const removeDuplicates = (arr) => [...new Set(arr)];
export const isEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);
export const cloneObj = (obj) => JSON.parse(JSON.stringify(obj));
export const getSum = (arr) => arr.reduce((sum, cur) => sum + (cur ? +cur : 0), 0);
export const merge = (arr1, arr2) => arr1.concat(arr2);
export const joinWithComma = (arr) => arr.join(', ');
export const isSuccessfulStatus = (status) => [200, 201].includes(status);

export const getSeverityColor = (severityName) => {
  const colors = {
    Operational: variables.operationalColor,
    NonCritical: variables.nonCriticalColor,
    Critical: variables.criticalColor,
    BeyondRepair: variables.beyondRepairColor,
    Total: variables.totalColor
  };
  return severityName ? colors[severityName] : '#fff';
};

export const getAssetFieldValue = (assetFields, columnId) => {
  const item = assetFields.find(({ assetField }) => assetField.id === columnId);

  if (!item?.assetField) return '';

  const { assetField, value } = item;

  switch (assetField.type) {
    case 'Bool':
      return 'value' in item ? (item?.value ? 'Yes' : 'No') : '';
    case 'DateTime':
      return value ? formatDate(item?.value) : '';
    case 'DateTimeFull':
      return value ? formatDate(item?.value, dateTimeFormat) : '';
    default:
      return value?.displayName || value || '';
  }
};

export const getDefaultColumnWidth = (length) => (window.innerWidth - 180) / length;

export const serializeError = (err) => {
  try {
    JSON.stringify(err, (_, value) => value, 2);
  } catch (e) {
    return 'Error serialization failed';
  }
};

export const NAVIGATED_BY_BREADCRUMB = 'breadcrumb';
