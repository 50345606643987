import { useDispatch } from 'react-redux';
import {
  getCategoryList,
  setSearchValue,
  setActiveCategory,
  clearState
} from 'actions/categoryActions';

export function usePrefixCategoryActions() {
  const dispatch = useDispatch();

  const getCategoryListAction = () => dispatch(getCategoryList());

  const setActiveCategoryAction = (category) => dispatch(setActiveCategory(category));

  const setSearchValueAction = (v) => dispatch(setSearchValue(v));

  const clearStateAction = () => dispatch(clearState());

  return {
    getCategoryListAction,
    setActiveCategoryAction,
    setSearchValueAction,
    clearStateAction
  };
}
