import { TEAM_MEMBER_HISTORY_TABLE_PARAMETER } from 'constants/configTableConstants';
import { cloneObj, getProjectName, getTeamMemberFullName } from 'helpers/AppHelpers';
import {
  handleBooleanFilter,
  handleIdsFilter,
  handleNamesFilter,
  handleDateFilter
} from 'helpers/ChipGenerator';

const defaultFilter = {
  filters: {
    searchQuery: '',
    teamMemberCapacityIds: [],
    timeOnProjectNames: [],
    teamMemberDepartmentIds: [],
    teamMemberIds: [],
    teamMemberStatusIds: [],
    projectIds: [],
    fromDayIn: null,
    toDayIn: null,
    fromDayOut: null,
    toDayOut: null,
    showAllHistory: false
  },
  pagination: {
    page: 1,
    totalPages: 1,
    limit: 10
  },
  sortRules: [],
  responseReceived: false
};

const generateInitialQuery = ({ getTableLimit, isAllLimitEnabled, filter, config }) => {
  const { departmentIds = [], projectIds = [] } = config;

  const defaultPage = defaultFilter.pagination.page;
  const limit = getTableLimit(TEAM_MEMBER_HISTORY_TABLE_PARAMETER);

  const isAll = isAllLimitEnabled(TEAM_MEMBER_HISTORY_TABLE_PARAMETER);
  const page = limit === 100 && isAll ? defaultPage : filter?.pagination?.page || defaultPage;

  return {
    filters: { ...defaultFilter.filters, ...filter?.filters, departmentIds, projectIds },
    pagination: { limit, page },
    sortRules: filter?.sortRules || defaultFilter.sortRules,
    responseReceived: false
  };
};

const DAY_IN_PREFIX = 'Date In:';
const DAY_OUT_PREFIX = 'Date Out:';

const generateChips = (criteria, filters = {}) => {
  const {
    teamMemberDepartments,
    teamMembers,
    teamMemberCapacities,
    teamMemberStatuses,
    projects,
    timeOnProjects
  } = criteria;

  const {
    timeOnProjectNames = [],
    showAllHistory = false,
    fromDayIn,
    toDayIn,
    fromDayOut,
    toDayOut
  } = filters;

  let newChips = [];

  const idsConfig = [
    { options: teamMemberDepartments, getLabel: (el) => el.name, key: 'teamMemberDepartmentIds' },
    { options: teamMembers, getLabel: (el) => getTeamMemberFullName(el), key: 'teamMemberIds' },
    { options: teamMemberCapacities, getLabel: (el) => el.name, key: 'teamMemberCapacityIds' },
    { options: teamMemberStatuses, getLabel: (el) => el.name, key: 'teamMemberStatusIds' },
    { options: projects, getLabel: (el) => getProjectName(el), key: 'projectIds' }
  ];

  idsConfig.forEach(({ options, getLabel, key }) => {
    if (filters?.[key]?.length) {
      newChips = newChips.concat(handleIdsFilter(filters[key], options, getLabel, key));
    }
  });

  if (timeOnProjectNames?.length) {
    newChips = newChips.concat(
      handleNamesFilter(filters.timeOnProjectNames, timeOnProjects, 'timeOnProjectNames')
    );
  }

  if (showAllHistory) {
    newChips = newChips.concat(handleBooleanFilter('showAllHistory', 'See all history'));
  }

  if (fromDayIn || toDayIn) {
    newChips = newChips.concat(handleDateFilter(fromDayIn, toDayIn, DAY_IN_PREFIX));
  }

  if (fromDayOut || toDayOut) {
    newChips = newChips.concat(handleDateFilter(fromDayOut, toDayOut, DAY_OUT_PREFIX));
  }

  return newChips.filter(Boolean);
};

const removeChip = (filters, deletedChip) => {
  const newFilters = cloneObj(filters);
  const { key, itemId, itemName } = deletedChip;

  switch (key) {
    case 'showAllHistory':
      newFilters[key] = false;
      break;
    case 'timeOnProjectNames':
      newFilters[key] = newFilters[key].filter((name) => name !== itemName);
      break;
    case `duration_${DAY_IN_PREFIX}`:
      newFilters.fromDayIn = null;
      newFilters.toDayIn = null;
      break;
    case `dateFrom_${DAY_IN_PREFIX}`:
      newFilters.fromDayIn = null;
      break;
    case `dateTo_${DAY_IN_PREFIX}`:
      newFilters.toDayIn = null;
      break;
    case `duration_${DAY_OUT_PREFIX}`:
      newFilters.fromDayOut = null;
      newFilters.toDayOut = null;
      break;
    case `dateFrom_${DAY_OUT_PREFIX}`:
      newFilters.fromDayOut = null;
      break;
    case `dateTo_${DAY_OUT_PREFIX}`:
      newFilters.toDayOut = null;
      break;
    default:
      newFilters[key] = newFilters[key].filter((id) => id !== itemId);
      break;
  }

  return newFilters;
};

const parseQuery = (query) => {
  const payload = {
    filters: { searchQuery: '' },
    pagination: { page: query.pagination.page, limit: query.pagination.limit }
  };

  const setFilters = (key, value) => {
    if (value?.length || value) {
      payload.filters[key] = value;
    }
  };

  const filterKeys = Object.keys(defaultFilter.filters);

  filterKeys.forEach((key) => setFilters(key, query.filters[key]));

  if (query.sortRules) {
    payload.sortRules = query.sortRules;
  }

  return payload;
};

const tableHeadConfig = [
  { id: 1, label: 'Team Member', isSortable: true, parameter: 'lastName' },
  { id: 2, label: 'Date In', isSortable: true, parameter: 'dateIn' },
  { id: 3, label: 'Date Out', isSortable: true, parameter: 'dateOut' },
  { id: 4, label: 'Project', isSortable: true, parameter: 'project' },
  { id: 5, label: 'Days on Road', isSortable: true, parameter: 'daysOnRoad' },
  { id: 6, label: 'Weeks on Road', isSortable: true, parameter: 'weeksOnRoad' },
  { id: 7, label: 'Scheduled', isSortable: true, parameter: 'timeSchedule' }
];

const mobileTableConfigOrder = [
  'lastName',
  'daysOnRoad',
  'weeksOnRoad',
  'dateIn',
  'dateOut',
  'project',
  'timeSchedule'
];

const getTableHeadConfig = (isMobile) =>
  isMobile
    ? mobileTableConfigOrder.map((parameter) =>
        tableHeadConfig.find((col) => col.parameter === parameter)
      )
    : tableHeadConfig;

const DEPARTMENT_TOOLTIP_TEXT =
  'By selecting Department, lists of Capacity, Team Member will be narrowed.';

export {
  defaultFilter,
  generateInitialQuery,
  generateChips,
  removeChip,
  parseQuery,
  getTableHeadConfig,
  DEPARTMENT_TOOLTIP_TEXT
};
