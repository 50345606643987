import { useDispatch } from 'react-redux';

import { setUnsavedFormData } from 'actions/commonActions';
import {
  getLocations,
  clearLocationState,
  createLocation,
  getProjects,
  getSingleLocation,
  getSiteTypesList,
  getStatesList,
  setFilter,
  setFilterCriteria,
  updateLocation,
  getLocationJobNumbers,
  getSiteTypes,
  getSummaryCsvResource
} from 'actions/locationsActions';

export function useLocationActions() {
  const dispatch = useDispatch();

  const getLocationsAction = (q) => dispatch(getLocations(q));

  const setFilterAction = (data) => dispatch(setFilter(data));

  const getFilterCriteriaAction = () =>
    Promise.all([dispatch(getLocationJobNumbers()), dispatch(getSiteTypes())]).then(
      ([locations, typeSites]) => {
        const criteria = { locations, typeSites };
        dispatch(setFilterCriteria(criteria));
        return criteria;
      }
    );

  const getLocationAction = (id) => dispatch(getSingleLocation(id));

  const createLocationAction = (data) => dispatch(createLocation(data));
  const updateLocationAction = (data) => dispatch(updateLocation(data));

  const getSiteTypesListAction = () => dispatch(getSiteTypesList());
  const getStatesListAction = () => dispatch(getStatesList());
  const getProjectListAction = (q) => dispatch(getProjects(q));

  const clearStateAction = () => dispatch(clearLocationState());

  const setUnsavedFormDataAction = (flag) => dispatch(setUnsavedFormData(flag));

  const getSummaryCsvResourceAction = (q) => dispatch(getSummaryCsvResource(q));

  return {
    getLocationsAction,
    setFilterAction,
    getFilterCriteriaAction,
    getLocationAction,
    createLocationAction,
    updateLocationAction,
    getSiteTypesListAction,
    getStatesListAction,
    getProjectListAction,
    getSummaryCsvResourceAction,
    clearStateAction,
    setUnsavedFormDataAction
  };
}
