import React from 'react';
import styles from './AddDeliveryItems.module.scss';
import PropTypes from 'prop-types';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import DialogHeader from 'components/DialogComponents/DialogHeader';
import { MainButton } from 'components/StyledComponents';
import Separator from 'components/Separator/Separator';

import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useMobileViewport } from 'hooks/useMobileViewport';

import { getErrorsProperties } from 'helpers/ErrorValidator';

import { convertItemsToBOLItems } from 'actions/billOfLadingActions';

import { NonDratItems, Assets, LooseItems } from './Blocks';
import { generatePayload } from './helpers';

/**
 * @typedef {Object} AddDeliveryItemsPopupProps
 * @property {boolean} open
 * @property {() => void} setOpen
 * @property {{}} data
 * @property {() => void} onAdd
 */

/**
 * @param {AddDeliveryItemsPopupProps} props
 */

function AddDeliveryItemsPopup({ open, setOpen, data, onAdd }) {
  const isMobile = useMobileViewport();
  const dispatch = useDispatch();

  const methods = useForm({
    defaultValues: {
      // loose items
      narrowLooseItemsByLocations: [],
      narrowLooseItemsBySublocationIds: [],
      narrowLooseItemsByCategoryIds: [],
      // assets
      narrowAssetsByLocations: [],
      narrowAssetsBySublocationIds: [],
      narrowAssetsByPrefixIds: [],
      // items
      assets: [],
      picklistAssets: [],
      leavelistAssets: [],
      looseItems: [],
      picklistLooseItems: [],
      leavelistLooseItems: [],
      nonDratItems: []
    },
    mode: 'onChange'
  });
  const { getValues, formState, setError } = methods;
  const { errors } = formState;
  const isFormInvalid = !!Object.values(errors).filter(Boolean).length;

  const { tableState, bolType, bolId } = data;

  const closePopup = () => setOpen(false);

  const validateForm = () => {
    let isFormValid = true;

    const arrays = {
      ['CUSTOM']: ['assets', 'looseItems', 'nonDratItems'],
      ['PICKLIST']: ['picklistAssets', 'picklistLooseItems', 'nonDratItems'],
      ['LEAVELIST']: ['leavelistAssets', 'leavelistLooseItems', 'nonDratItems']
    };

    const fields = arrays[bolType] || [];
    const values = fields.map((name) => getValues(name));

    if (values.every((arr) => arr.length === 0)) {
      isFormValid = false;
      fields.forEach((name) => {
        setError(name, getErrorsProperties('At least one field is required'));
      });
    }

    return isFormValid;
  };

  const handleAddClick = () => {
    if (!validateForm()) return;

    const payload = generatePayload(getValues(), bolType, bolId);

    dispatch(convertItemsToBOLItems(payload)).then((res) => {
      if (!res?.length) return;

      onAdd(res);
      closePopup();
    });
  };

  const getExcludedIds = (tableState, key) => tableState.map((item) => item[key]).filter(Boolean);

  const excludedPicklistAssetIds = getExcludedIds(tableState, 'picklistAssetId');
  const excludedLeavelistAssetIds = getExcludedIds(tableState, 'leavelistAssetId');
  const excludedAssetIds = getExcludedIds(tableState, 'assetId');
  const excludedLooseItemIds = getExcludedIds(tableState, 'looseItemId');

  const assetExcludedOptions = {
    excludedPicklistAssetIds,
    excludedLeavelistAssetIds,
    excludedAssetIds
  };

  const looseItemExcludedOptions = {
    excludedPicklistAssetIds,
    excludedLeavelistAssetIds,
    excludedLooseItemIds
  };

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.popup}>
        <DialogHeader
          title={isMobile ? 'Add items' : 'Add items to Bill of Lading'}
          onClose={closePopup}
        />
        <FormProvider {...methods}>
          <div className={styles.form}>
            <Assets type={bolType} excludedOptions={assetExcludedOptions} bolId={bolId} />

            <Separator />

            <LooseItems type={bolType} excludedOptions={looseItemExcludedOptions} />

            <Separator />

            <NonDratItems />
          </div>
        </FormProvider>
        <div className={styles.footer}>
          <MainButton text="Cancel" action={closePopup} type="secondary" />
          <MainButton
            text="Add"
            action={handleAddClick}
            type="primary"
            isDisabled={isFormInvalid}
          />
          <div className={styles.footer__error}>
            {errors?.nonDratItems && (
              <div className={styles.footer__error_message}>{errors.nonDratItems.message}</div>
            )}
          </div>
        </div>
      </div>
    </DialogWrapper>
  );
}

AddDeliveryItemsPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

AddDeliveryItemsPopup.defaultProps = {
  open: false,
  data: { tableState: [], bolType: 'CUSTOM', bolId: null }
};

export { AddDeliveryItemsPopup };
