import {
  LOCATIONS_LIST_PATH,
  CATEGORY_LIST_PATH,
  CLIENT_LIST_PATH,
  PROJECT_LIST_PATH,
  ASSETS_LIST_PATH,
  TICKETS_LIST_PATH,
  USER_LIST_PATH,
  BOM_CONFIGURATIONS_LIST_PATH,
  ASSEMBLED_BOM_LIST_PATH,
  PICKLIST_SUMMARY_PATH,
  LEAVELIST_SUMMARY_PATH,
  TICKET_TYPES_PATH,
  TICKET_ROOT_CAUSES_PATH,
  TEAM_MEMBER_LIST_PATH,
  LOOSE_ITEMS_LIST_PATH,
  REQUEST_ORDER_SUMMARY_PATH,
  BILL_OF_LADING_SUMMARY_PATH,
  GENERAL_REPORT_LIST_PATH,
  LOCATION_REPORT_LIST_PATH,
  ASSET_REPORT_LIST_PATH,
  BILL_OF_LADING_LOGS_PATH
} from './routeConstants';

export const routesList = [
  {
    link: 'location_detailed',
    name: 'Location Summary',
    path: LOCATIONS_LIST_PATH
  },
  {
    link: 'location_edit',
    name: 'Location Summary',
    path: LOCATIONS_LIST_PATH
  },
  {
    link: 'category_edit',
    name: 'Prefix/category list',
    path: CATEGORY_LIST_PATH
  },
  {
    link: 'category_detailed',
    name: 'Prefix/category list',
    path: CATEGORY_LIST_PATH
  },
  {
    link: 'prefix_edit',
    name: 'Prefix/category list',
    path: CATEGORY_LIST_PATH
  },
  {
    link: 'prefix_detailed',
    name: 'Prefix/category list',
    path: CATEGORY_LIST_PATH
  },
  {
    link: 'client_edit',
    name: 'Client list',
    path: CLIENT_LIST_PATH
  },
  {
    link: 'client_detailed',
    name: 'Client list',
    path: CLIENT_LIST_PATH
  },
  {
    link: 'project_detailed',
    name: 'Project Summary',
    path: PROJECT_LIST_PATH
  },
  {
    link: 'project_edit',
    name: 'Project Summary',
    path: PROJECT_LIST_PATH
  },
  {
    link: 'asset_detailed',
    name: 'Asset Summary',
    path: ASSETS_LIST_PATH
  },
  {
    link: 'asset_edit',
    name: 'Asset Summary',
    path: ASSETS_LIST_PATH
  },
  {
    link: 'ticket_detailed',
    name: 'Ticket summary',
    path: TICKETS_LIST_PATH
  },
  {
    link: 'ticket_edit',
    name: 'Ticket summary',
    path: TICKETS_LIST_PATH
  },
  {
    link: 'user_detailed',
    name: 'User List',
    path: USER_LIST_PATH
  },
  {
    link: 'user_edit',
    name: 'User List',
    path: USER_LIST_PATH
  },
  {
    link: 'bom_configuration_detailed',
    name: 'Configuration list',
    path: BOM_CONFIGURATIONS_LIST_PATH
  },
  {
    link: 'bom_configuration_edit',
    name: 'Configuration list',
    path: BOM_CONFIGURATIONS_LIST_PATH
  },
  {
    link: 'assembly_edit',
    name: 'Assembled BOM list',
    path: ASSEMBLED_BOM_LIST_PATH
  },
  {
    link: 'assembly_detailed',
    name: 'Assembled BOM list',
    path: ASSEMBLED_BOM_LIST_PATH
  },
  {
    link: 'picklist_edit',
    name: 'Picklist Summary',
    path: PICKLIST_SUMMARY_PATH
  },
  {
    link: 'picklist_detailed',
    name: 'Picklist Summary',
    path: PICKLIST_SUMMARY_PATH
  },
  {
    link: 'leavelist_edit',
    name: 'Leavelist Summary',
    path: LEAVELIST_SUMMARY_PATH
  },
  {
    link: 'leavelist_detailed',
    name: 'Leavelist Summary',
    path: LEAVELIST_SUMMARY_PATH
  },
  {
    link: 'leavelist_detailed',
    name: 'Leavelist Summary',
    path: LEAVELIST_SUMMARY_PATH
  },
  {
    link: 'ticket_type_edit',
    name: 'Ticket Type list',
    path: TICKET_TYPES_PATH
  },
  {
    link: 'root_cause_edit',
    name: 'Root Cause list',
    path: TICKET_ROOT_CAUSES_PATH
  },
  {
    link: 'team_member_edit',
    name: 'Team list',
    path: TEAM_MEMBER_LIST_PATH
  },
  {
    link: 'loose_item_category_detailed',
    name: 'Loose Items List',
    path: LOOSE_ITEMS_LIST_PATH
  },
  {
    link: 'loose_item_category_edit',
    name: 'Loose Items List',
    path: LOOSE_ITEMS_LIST_PATH
  },
  {
    link: 'loose_item_create',
    name: 'Loose Items List',
    path: LOOSE_ITEMS_LIST_PATH
  },
  {
    link: 'loose_item_detailed',
    name: 'Loose Items List',
    path: LOOSE_ITEMS_LIST_PATH
  },
  {
    link: 'loose_item_edit',
    name: 'Loose Items List',
    path: LOOSE_ITEMS_LIST_PATH
  },
  {
    link: 'request_order_edit',
    name: 'Request Orders',
    path: REQUEST_ORDER_SUMMARY_PATH
  },
  {
    link: 'request_order_detailed',
    name: 'Request Orders',
    path: REQUEST_ORDER_SUMMARY_PATH
  },
  {
    link: 'bill_of_lading_edit',
    name: 'Bill of Lading Summary',
    path: BILL_OF_LADING_SUMMARY_PATH
  },
  {
    link: 'bill_of_lading_detailed',
    name: 'Bill of Lading Summary',
    path: BILL_OF_LADING_SUMMARY_PATH
  },
  {
    link: 'general_report_edit',
    name: 'Report Summary',
    path: GENERAL_REPORT_LIST_PATH
  },
  {
    link: 'location_report_edit',
    name: 'Report Summary',
    path: LOCATION_REPORT_LIST_PATH
  },
  {
    link: 'asset_report_edit',
    name: 'Report Summary',
    path: ASSET_REPORT_LIST_PATH
  },
  {
    link: 'bill_of_lading_log_edit',
    name: 'BOL Logs',
    path: BILL_OF_LADING_LOGS_PATH
  },
  {
    link: 'upload_bill_of_lading_logs',
    name: 'BOL Logs',
    path: BILL_OF_LADING_LOGS_PATH
  }
];
