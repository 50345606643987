import {
  CLEAR_BOL_LOGS_STATE,
  SET_BOL_LOGS_CREATION_FORM,
  SET_BOL_LOGS_FILTER_CRITERIA,
  SET_BOL_LOGS_FILTER
} from 'constants/reduceConstants';

const initialState = {
  filter: { responseReceived: false },
  filterCriteria: {},
  creationForm: {}
};

const billOfLadingLogsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_BOL_LOGS_FILTER:
      return { ...state, filter: payload };
    case SET_BOL_LOGS_CREATION_FORM:
      return { ...state, creationForm: payload };
    case SET_BOL_LOGS_FILTER_CRITERIA:
      return { ...state, filterCriteria: payload };
    case CLEAR_BOL_LOGS_STATE:
      return { ...initialState, filter: { ...state.filter, responseReceived: false } };
    default:
      return state;
  }
};

export default billOfLadingLogsReducer;
