import { useSelector } from 'react-redux';
import { selectSearchValueData } from 'pages/PrefixCategoryList/selectors';

export function usePrefixCategorySelector() {
  const searchValue = useSelector(selectSearchValueData());

  return {
    searchValue
  };
}
