import React, { useEffect } from 'react';
import styles from './PaginationLayout.module.scss';

import { useContainerRef } from 'hooks/useContainerRef';
import { useDispatch } from 'react-redux';

import clsx from 'clsx';

import { setLoadMoreFlag } from 'actions/commonActions';

export default function PaginationLayout({ isAllSelected, loadMoreFlag, children }) {
  const dispatch = useDispatch();

  const { getRef } = useContainerRef();
  const container = getRef?.()?.current;

  const handleScroll = () => {
    if (!container || loadMoreFlag) return;

    const currentHeight =
      container.scrollHeight - Math.abs(container.scrollTop) - Math.abs(container.clientHeight);

    if (currentHeight !== 0 && currentHeight < 200) {
      dispatch(setLoadMoreFlag(true));
    }
  };

  useEffect(() => {
    if (!container) return;

    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [container]);

  return <div className={clsx(styles.pagination, isAllSelected && styles.fixed)}>{children}</div>;
}
