import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getHistoryApi = (query) =>
  api.get(`/TeamMemberHistory?${queryToString(query)}`).then((res) => res.data);

export const getFilterCriteriaApi = (q) =>
  api.get(`/TeamMemberHistory/FilterCriteria?${queryToString(q)}`).then((res) => res.data);

export const getHistoryConfigApi = () =>
  api.get(`/User/TeamMemberHistoryConfig`).then((res) => res.data);

export const updateUserConfigApi = (params) =>
  api.put('/User/TeamMemberHistoryConfig', params).then((res) => res.data);

export const getSummaryCsvResourceApi = (q) =>
  api.get(`/TeamMemberHistory/Serialized?${queryToString(q)}`).then((res) => res.data);
