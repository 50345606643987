import {
  SET_ASSEMBLED_BOM_CREATION_FORM,
  SET_ASSEMBLED_BOM_STATUSES,
  SET_ASSEMBLED_BOM_FILTER,
  SET_ASSEMBLED_BOM_FILTER_CRITERIA,
  SET_CURRENT_ASSEMBLED_BOM,
  CLEAR_ASSEMBLED_BOM_STATE,
  SET_ASSEMBLED_BOM_LOCATIONS
} from 'constants/reduceConstants';

const initialState = {
  // Summary
  filter: { responseReceived: false },
  filterCriteria: {},
  // Details
  currentAssemble: {},
  statusListFilter: [],
  locationList: [],
  // Create Screen
  creationForm: {}
};

const assembleBOMReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    // Summary
    case SET_ASSEMBLED_BOM_FILTER:
      return { ...state, filter: payload };
    case SET_ASSEMBLED_BOM_FILTER_CRITERIA:
      return { ...state, filterCriteria: payload };
    // Details
    case SET_CURRENT_ASSEMBLED_BOM:
      return { ...state, currentAssemble: payload };
    case SET_ASSEMBLED_BOM_STATUSES:
      return { ...state, statusListFilter: payload };
    case SET_ASSEMBLED_BOM_LOCATIONS:
      return { ...state, locationList: payload };
    // Create screen
    case SET_ASSEMBLED_BOM_CREATION_FORM:
      return { ...state, creationForm: payload };
    // Common
    case CLEAR_ASSEMBLED_BOM_STATE:
      return { ...initialState, filter: { ...state.filter, responseReceived: false } };
    default:
      return state;
  }
};

export default assembleBOMReducer;
