import React from 'react';
import styles from './Buttons.module.scss';

import { Button } from '@material-ui/core';

import clsx from 'clsx';

export default function StyledButton({
  onClick,
  label,
  color = 'yellow',
  disabled,
  classes,
  children
}) {
  return (
    <Button
      onClick={onClick}
      className={clsx(
        classes && classes,
        styles.styled_button,
        styles[color],
        disabled && styles.disabled
      )}>
      {children}
      {label && <span>{label}</span>}
    </Button>
  );
}
