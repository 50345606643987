import React, { useEffect, useRef, useState } from 'react';
import styles from './BOMConfigurations.module.scss';

import {
  AccordionSummaryWrapper,
  DebouncedSearchField,
  SummaryHeaderWrapper,
  SummaryPageHeader,
  SummaryTableControls
} from 'components/SummaryComponents';
import { BlueButton, StyledButton } from 'components/Buttons';
import { Accordion, AccordionSummary, AccordionDetails } from 'components/StyledComponents';
import StyledLink from 'components/StyledLink';
import HighlightedText from 'components/HighlightedText';

import { useHistory } from 'react-router-dom';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';
import { useBOMConfigurationActions, useBOMConfigurationSelector } from 'hooks/BOMConfigurations';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  BOM_CONFIGURATION_DETAILED_PATH,
  BOM_CONFIGURATION_EDIT_PATH,
  LOOSE_ITEM_DETAILED_PATH
} from 'constants/routeConstants';

const AccordionContent = ({ quantity, children }) => (
  <div className={styles.accordion__content}>
    <div className={styles.accordion__name}>{children}</div>
    <div className={styles.accordion__quantity}>{quantity}</div>
  </div>
);

export default function BOMConfigurations() {
  const history = useHistory();
  const isMobile = useMobileViewport();
  const allConfigurations = useRef([]);

  const { isAdminUser, isOperationsManagerUser } = useUserConfig();

  const { searchValue } = useBOMConfigurationSelector();
  const { getConfigurationListAction, setSearchValueAction, clearStateAction } =
    useBOMConfigurationActions();

  const [configurations, setConfigurations] = useState([]);
  const [filteredConfigurations, setFilteredConfigurations] = useState([]);
  const [openAccordionIds, setOpenAccordionIds] = useState([]);

  useEffect(() => {
    getConfigurationListAction().then((res) => {
      allConfigurations.current = res;
      setConfigurations(res);
    });
  }, []);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const filterConfigurationsAndToggleAccordions = (searchTerm) => {
    if (!searchTerm) {
      return {
        processedConfigurations: allConfigurations.current,
        filteredProcessedConfigurations: allConfigurations.current,
        openAccordionIds: []
      };
    }

    const lowercasedTerm = searchTerm.toLowerCase();

    const processedConfigurations = allConfigurations.current.map((configuration) => {
      const looseItems = configuration.looseItems.filter(({ name }) =>
        name.toLowerCase().includes(lowercasedTerm)
      );

      const assetPrefixes = configuration.assetPrefixes.filter(({ prefix }) =>
        prefix.toLowerCase().includes(lowercasedTerm)
      );

      return { ...configuration, assetPrefixes, looseItems };
    });

    const filteredProcessedConfigurations = processedConfigurations.filter(
      ({ assetPrefixes, looseItems }) => assetPrefixes.length || looseItems.length
    );
    const openAccordionIds = filteredProcessedConfigurations.map(
      (configuration) => configuration.id
    );

    return { processedConfigurations, filteredProcessedConfigurations, openAccordionIds };
  };

  const searchItemsAndAssets = (value) => {
    if ((!value && !searchValue) || value === searchValue) return;

    const { processedConfigurations, filteredProcessedConfigurations, openAccordionIds } =
      filterConfigurationsAndToggleAccordions(value);

    setSearchValueAction(value);
    setConfigurations(processedConfigurations);
    setFilteredConfigurations(filteredProcessedConfigurations);
    setOpenAccordionIds(openAccordionIds);
  };

  const isAccordionOpen = (id) => openAccordionIds.includes(id);

  const toggleAccordion = (id) => {
    if (isAccordionOpen(id)) {
      setOpenAccordionIds(openAccordionIds.filter((openId) => openId !== id));
    } else {
      setOpenAccordionIds([...openAccordionIds, id]);
    }
  };

  const editConfiguration = (id) => history.push(`${BOM_CONFIGURATION_EDIT_PATH}/${id}`);
  const addConfiguration = () => history.push(BOM_CONFIGURATION_EDIT_PATH);

  const AddConfigurationButton = () =>
    isAdminUser || isOperationsManagerUser ? (
      <StyledButton
        label="Add new configuration"
        onClick={addConfiguration}
        classes={styles.main_button}
      />
    ) : null;

  return (
    <AccordionSummaryWrapper>
      <SummaryHeaderWrapper>
        <SummaryPageHeader title="BOM configuration">
          {!isMobile && <AddConfigurationButton />}
        </SummaryPageHeader>

        <SummaryTableControls>
          <div className={styles.searchBlock}>
            <DebouncedSearchField filterValue={searchValue} onSearch={searchItemsAndAssets} />
            {!filteredConfigurations?.length && searchValue?.length > 1 && (
              <span className={styles.searchBlock__hint}>No Matches</span>
            )}
          </div>
        </SummaryTableControls>
      </SummaryHeaderWrapper>

      {isMobile && <AddConfigurationButton />}

      {!!configurations.length &&
        configurations.map(({ id, name, looseItems, assetPrefixes }) => (
          <Accordion key={id} expanded={isAccordionOpen(id)} className={styles.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={styles.accordion__icon} />}
              IconButtonProps={{ onClick: () => toggleAccordion(id) }}>
              <div className={styles.accordion__summary}>
                <div className={styles.accordion__title}>
                  <StyledLink bold to={`${BOM_CONFIGURATION_DETAILED_PATH}/${id}`}>
                    {name}
                  </StyledLink>
                </div>
                {(isAdminUser || isOperationsManagerUser) && (
                  <BlueButton
                    label="Edit"
                    className={styles.accordion__button}
                    onClick={() => editConfiguration(id)}
                  />
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={styles.accordion__details}>
                {looseItems?.length || assetPrefixes?.length ? (
                  <>
                    {looseItems?.map(({ id, name, quantity }) => (
                      <AccordionContent key={`loose-item-${id}`} quantity={quantity}>
                        <StyledLink to={`${LOOSE_ITEM_DETAILED_PATH}/${id}`}>
                          <HighlightedText text={name} searchTerm={searchValue} />
                        </StyledLink>
                      </AccordionContent>
                    ))}

                    {assetPrefixes?.map(({ id, prefix, quantity }) => (
                      <AccordionContent key={`asset-${id}`} quantity={quantity}>
                        <HighlightedText text={prefix} searchTerm={searchValue} />
                      </AccordionContent>
                    ))}
                  </>
                ) : (
                  <div className={styles.accordion__empty}>
                    {searchValue ? 'No matches' : 'No items'}
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
    </AccordionSummaryWrapper>
  );
}
