import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getPicklistsApi = (query) =>
  api.get(`/Picklists?${queryToString(query)}`).then((res) => res.data);

export const getFilterCriteriaApi = () =>
  api.get(`/Picklists/FilterCriteria`).then((res) => res.data);

export const getSinglePicklistApi = (id) => api.get(`/Picklist?id=${id}`).then((res) => res.data);

export const getLocationsApi = (query) =>
  api.get(`/Locations/ShortedWithSublocations?${queryToString(query)}`).then((res) => res.data);

export const getStatusesApi = () => api.get('/PicklistStatuses').then((res) => res.data);

export const getPicklistAssetsCountingApi = (query) =>
  api.get(`/PicklistAssets/Counting?${queryToString(query)}`).then((res) => res.data);

export const getProjectsApi = (query) =>
  api.get(`/ShortedProjects?${queryToString(query)}`).then((res) => res.data);

export const createPicklistApi = (params) => api.post('/Picklist', params).then((res) => res.data);

export const updatePicklistApi = (params) => api.put('/Picklist', params).then((res) => res.data);

export const updatePicklistAssetApi = (params) =>
  api.put('/PicklistAsset', params).then((res) => res.data);

export const deletePicklistApi = (id) => api.delete(`/Picklist?id=${id}`).then((res) => res.data);

export const addAssetsToPicklistApi = (params) =>
  api.post('/PicklistAssets', params).then((res) => res);

export const deleteItemsApi = (query) =>
  api.delete(`/PicklistAssets?${queryToString(query)}`).then((res) => res);

export const addAssetsToLeavelistApi = (params) =>
  api.post('/LeavelistAssets', params).then((res) => res);

export const getAssetsApi = (query) =>
  api.get(`/PicklistAssets?${queryToString(query)}`).then((res) => res.data);

export const getPicklistDetailsFilterCriteriaApi = (query) =>
  api.get(`/PicklistAssets/FilterCriteria?${queryToString(query)}`).then((res) => res.data);

export const checkBillOfLadingItemsApi = (params) =>
  api.get(`/BillOfLading/CheckItems?${queryToString(params)}`).then((res) => res);

export const searchPicklistAssetsApi = (query) =>
  api.get(`/PicklistAssets/Search?${queryToString(query)}`);

export const getAssetStatusesListApi = (q) =>
  api.get(`/PicklistAssetStatuses?${queryToString(q)}`).then((res) => res.data);

export const getListOfPicklistApi = () => api.get('/ShortedPicklists').then((res) => res.data);

export const checkPicklistAssetsLocationApi = (q) =>
  api.get(`/PicklistAssets/LocationCheck?${queryToString(q)}`).then((res) => res.data);

export const getSummaryCsvResourceApi = (q) =>
  api.get(`/Picklists/Serialized?${queryToString(q)}`).then((res) => res.data);

export const getPicklistCsvResourceApi = (q) =>
  api.get(`/Picklist/Serialized?${queryToString(q)}`).then((res) => res.data);
