import { useSelector } from 'react-redux';
import {
  selectFilterData,
  selectFilterCriteriaData
} from 'pages/TeamSchedule/TeamMemberHistory/selectors';

export function useHistorySelector() {
  const filter = useSelector(selectFilterData());
  const filterCriteria = useSelector(selectFilterCriteriaData());

  return { filter, filterCriteria };
}
