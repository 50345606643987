import React, { useEffect, useState } from 'react';
import styles from './Form.module.scss';

import { Controller, useFormContext } from 'react-hook-form';
import { usePrefixActions, usePrefixSelector } from 'hooks/Prefix';
import { useParams } from 'react-router-dom';
import { useUserConfig } from 'hooks/useUserConfig';

import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import InputSearchWithCreation from 'components/InputSearchWithCreation/InputSearchWithCreation';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';

import { IconButton } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';

import { DELETE_PREFIX_FIELD } from 'constants/dialogPopupsData';

import FieldsContainer from './FieldsContainer';
import EditFieldPopup from './EditFieldPopup';

const IsNumericBlock = (options, setOptions, inputValue) => {
  const handleCheckboxClick = (name, value) => {
    const updatedValues = { ...options, [name]: value };
    setOptions(updatedValues);
  };

  return (
    !!inputValue && (
      <div className={styles.is_numeric}>
        <label>Is numeric?</label>
        <CustomCheckbox name="isNumeric" value={options.isNumeric} onChange={handleCheckboxClick} />
      </div>
    )
  );
};

function OptionComponent({ option, selected, isEditAvailable, onEdit }) {
  const handleEditClick = (e) => {
    e.stopPropagation();
    onEdit(option);
  };

  return (
    <div className={styles.option}>
      <CustomCheckbox value={selected} makeRed={!!option?.willBeDeleted} />
      <div className={styles.option_label}>{option?.displayName || ''}</div>
      {isEditAvailable && (
        <IconButton className={styles.option_edit} onClick={handleEditClick}>
          <CreateIcon />
        </IconButton>
      )}
    </div>
  );
}

const FIELDS_PROPERTY_NAME = 'assetFields';

export default function FieldsDetails() {
  const { id } = useParams();
  const { control, clearErrors, getValues, setValue } = useFormContext();

  const [options, setOptions] = useState([]);

  const [modalData, setModalData] = useState({});

  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);

  const { isAdminUser } = useUserConfig();

  const { getFieldsDeletePermissionsAction, getAssetFieldsAction } = usePrefixActions();

  const { creationForm } = usePrefixSelector();
  const { assetFields } = creationForm;

  useEffect(() => {
    setOptions(assetFields || []);
  }, [assetFields]);

  const addField = (value, options, creationType, isInvalid) => {
    isInvalid && clearErrors(FIELDS_PROPERTY_NAME);
    setValue(
      FIELDS_PROPERTY_NAME,
      [
        ...getValues(FIELDS_PROPERTY_NAME),
        creationType === 'existed'
          ? value
          : { ...value, ...options, name: value?.displayName || '' }
      ],
      {
        shouldDirty: true
      }
    );
  };

  const removeOption = (option) => {
    const isCreateMode = !id;

    if (isCreateMode) {
      setValue(
        FIELDS_PROPERTY_NAME,
        getValues(FIELDS_PROPERTY_NAME).filter(({ id }) => id !== option.id),
        {
          shouldDirty: true
        }
      );
      return;
    }

    const index = getValues(FIELDS_PROPERTY_NAME).findIndex(({ id }) => id === option.id);
    const field = getValues(`assetFields[${index}]`);

    if (field?.deleted) {
      setValue(`assetFields[${index}]`, { ...field, deleted: false });
      refreshOptions({ ...field, deleted: false });
      return;
    }

    getFieldsDeletePermissionsAction(id, field.id).then((res) => {
      const name = `assetFields[${index}]`;
      const option = { ...field, deleted: true };
      if (res?.canBeDeleted) {
        setValue(name, option);
        refreshOptions(option);
      } else {
        showDeleteModal(name, option);
      }
    });
  };

  const selectOption = (name, value, selectionType, isInvalid) => {
    isInvalid && clearErrors(FIELDS_PROPERTY_NAME);

    if (selectionType === 'remove-option') {
      removeOption(value);
      return;
    }

    setValue(FIELDS_PROPERTY_NAME, [...getValues(FIELDS_PROPERTY_NAME), value], {
      shouldDirty: true
    });
  };

  const refreshOptions = (field) => {
    const updatedOptionId = options.find(({ id }) => id === field.id)?.id;

    const updatedOptions = options.map((item) => {
      if (item.id === updatedOptionId) {
        return { ...item, willBeDeleted: !!field.deleted };
      }
      return item;
    });

    setOptions(updatedOptions);
  };

  const editFieldOption = (option) => {
    setData(option);
    setOpen(true);
  };

  const handleEditField = ({ action, field }) => {
    getAssetFieldsAction();

    const isFieldSelected = !!getValues('assetFields').find(({ id }) => id === field.id)?.id;
    if (!isFieldSelected) return;

    if (action === 'UPDATE') {
      const index = getValues('assetFields').findIndex(({ id }) => id === field.id);
      setValue(`assetFields[${index}]`, {
        ...getValues(`assetFields[${index}]`),
        name: field.name,
        displayName: field.displayName
      });
    } else {
      const newFieldValue = getValues('assetFields').filter(({ id }) => id !== field.id);
      setValue('assetFields', newFieldValue);
    }
  };

  const showDeleteModal = (name, option) => {
    setModalData({ ...DELETE_PREFIX_FIELD, isOpened: true, option, optionName: name });
  };

  const agreeModal = () => {
    setValue(modalData.optionName, modalData.option);
    refreshOptions(modalData.option);
    closeModal();
  };

  const closeModal = () => setModalData({});

  return (
    <>
      {open && (
        <EditFieldPopup open={open} setOpen={setOpen} data={data} onUpdate={handleEditField} />
      )}

      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />

      <Controller
        name="prefixType"
        render={({ field: { value } }) => <h2>{value?.name ? value.name + ' ' : ''} Details</h2>}
      />
      <div className={styles.general__block_selector}>
        <label>Fields*</label>
        <Controller
          name="assetFields"
          control={control}
          render={({ field: { value, name }, fieldState: { error } }) => (
            <InputSearchWithCreation
              name={name}
              options={options || []}
              value={value}
              onSelect={(name, value, type) => selectOption(name, value, type, !!error)}
              getOptionLabel={(option) => option.displayName}
              customLabel="displayName"
              max={60}
              isInvalid={!!error?.message}
              invalidError={error?.message}
              onAdd={(value, options, creationType) =>
                addField(value, options, creationType, !!error)
              }
              CustomElement={IsNumericBlock}
              OptionComponent={(option, selected) => (
                <OptionComponent
                  option={option}
                  selected={selected}
                  isEditAvailable={isAdminUser && !option?.isDefault}
                  onEdit={editFieldOption}
                />
              )}
            />
          )}
        />
      </div>
      <FieldsContainer refreshOptions={refreshOptions} showDeleteModal={showDeleteModal} />
    </>
  );
}
