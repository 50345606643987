import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './ProjectList.module.scss';

import {
  NoDataTableRow,
  Pagination,
  SearchField,
  SummaryHeaderWrapper,
  SummaryPageHeader,
  SummaryTableControls,
  SummaryWrapper,
  TableWrapper,
  TableHeadComponent
} from 'components/SummaryComponents';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { StyledButton } from 'components/Buttons';
import { FilterButton, FilterChips, FilterWrapper } from 'components/FilterComponentsV2';
import StyledLink from 'components/StyledLink';

import { Table, TableBody } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';
import { useProjectActions, useProjectSelector } from 'hooks/Project';
import { useCommonActions, useCommonSelector } from 'hooks/Common';
import useScrollManager from 'hooks/useScrollManager';

import { PROJECT_DETAILED_PATH, PROJECT_EDIT_PATH } from 'constants/routeConstants';
import { PROJECT_LIST_TABLE_PARAMETER } from 'constants/configTableConstants';

import {
  getChangedByApplyFilter,
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';
import { getDate } from 'helpers/AppHelpers';

import {
  generateChips,
  generateInitialQuery,
  useSummaryParams,
  parseQuery,
  tableConfig,
  removeChip
} from './helpers';
import FilterModal from './Filter';

export function ProjectList() {
  const isMobile = useMobileViewport();
  const history = useHistory();
  const tableRef = useRef(null);

  const urlParams = useSummaryParams();

  const [tableState, setTableState] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [chips, setChips] = useState([]);

  const {
    isAdminUser,
    isOperationsManagerUser,
    isConfigReceived,
    getTableLimit,
    isAllLimitEnabled
  } = useUserConfig();
  const { setLoadMoreFlagAction } = useCommonActions();
  const { loadMoreFlag } = useCommonSelector();

  const { filter, filterCriteria } = useProjectSelector();
  const { getProjectsAction, getFilterCriteriaAction, setFilterAction, clearStateAction } =
    useProjectActions();

  const { filters, pagination, sortRules, responseReceived } = filter;

  const { saveScrollPosition, removeScrollPosition } = useScrollManager({
    tableKey: PROJECT_LIST_TABLE_PARAMETER,
    isDataLoaded: responseReceived
  });

  const getProjects = useCallback(
    (query, isAutoload) => {
      setFilterAction(query);

      getProjectsAction(parseQuery(query)).then((tableData) => {
        setProjects(tableData, query, isAutoload);
        !loadMoreFlag && setLoadMoreFlagAction(false);
      });
    },
    [setFilterAction, getProjectsAction]
  );

  const setProjects = useCallback(
    (data, query, isAutoload) => {
      setTableState((prevState) =>
        isAutoload ? prevState.concat(data?.items || []) : data?.items || []
      );

      const updatedFilter = {
        ...query,
        pagination: { limit: data.limit, page: data.pageNumber, totalPages: data.totalPages },
        responseReceived: true
      };
      setFilterAction(updatedFilter);
    },
    [setFilterAction]
  );

  useEffect(() => {
    if (!isConfigReceived) return;

    getFilterCriteriaAction().then((criteria) => {
      const q = {
        getTableLimit,
        isAllLimitEnabled,
        filter,
        ...urlParams
      };
      const resultFilter = generateInitialQuery(q);
      getProjects(resultFilter);
      setChips(generateChips(criteria, resultFilter.filters));
    });
  }, [isConfigReceived]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const findMatches = useCallback(
    (value) => getProjects(getChangedBySearchFilter(filter, value)),
    [filter, getProjects]
  );
  const sortTable = useCallback(
    (rowName) => getProjects(getChangedBySortFilter(filter, rowName)),
    [filter, getProjects]
  );
  const applyFilter = useCallback(
    (values) => {
      getProjects(getChangedByApplyFilter(filter, values));
      setChips(generateChips(filterCriteria, values));
    },
    [filter, filterCriteria, getProjects]
  );
  const changeLimit = useCallback(
    () => getProjects(getChangedByLimitFilter(filter, getTableLimit(PROJECT_LIST_TABLE_PARAMETER))),
    [filter, getTableLimit, getProjects]
  );
  const changePage = useCallback(
    (page, param) => getProjects(getChangedByPageFilter(filter, page), param === 'AUTOLOAD'),
    [filter, getProjects]
  );
  const deleteChip = useCallback(
    (chip) => applyFilter(removeChip(filters, chip)),
    [applyFilter, filters]
  );

  const openFilterModal = useCallback(() => setOpenFilter(true), [setOpenFilter]);

  const createProject = useCallback(() => {
    removeScrollPosition();
    history.push(PROJECT_EDIT_PATH);
  }, [history]);

  const MainButton = () =>
    isAdminUser || isOperationsManagerUser ? (
      <StyledButton label="Add new project" onClick={createProject} classes={styles.main_button} />
    ) : null;

  return (
    <SummaryWrapper>
      <SummaryHeaderWrapper>
        <SummaryPageHeader title="Project Summary">{!isMobile && <MainButton />}</SummaryPageHeader>
        <SummaryTableControls>
          <FilterWrapper>
            <FilterButton onClick={openFilterModal} isActive={openFilter || !!chips?.length} />
            {!isMobile && <FilterChips chips={chips} onDelete={deleteChip} />}
          </FilterWrapper>
          <SearchField filterValue={filters?.searchQuery} onSearch={findMatches} />
        </SummaryTableControls>
      </SummaryHeaderWrapper>

      {isMobile && <MainButton />}

      <TableWrapper ref={tableRef}>
        <Table stickyHeader={!isMobile} className={styles.table}>
          <TableHeadComponent config={tableConfig} sortRules={sortRules} onSortApply={sortTable} />
          <TableBody>
            {tableState?.length ? (
              tableState.map((row) => (
                <StyledTableRow key={row.id} className={styles.project_table_row}>
                  <StyledTableCell className={styles.nameCell}>
                    <StyledLink
                      to={`${PROJECT_DETAILED_PATH}/${row.id}`}
                      onClick={saveScrollPosition}>
                      {row?.name || ''}
                    </StyledLink>
                  </StyledTableCell>
                  <StyledTableCell className={styles.numberCell}>
                    {row.jobNumber || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.contractNumberCell}>
                    {row.contractNumber || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.contractDateCell}>
                    {getDate(row.contractDate)}
                  </StyledTableCell>
                  <StyledTableCell className={styles.descriptionCell}>
                    {row.description}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : responseReceived ? (
              <NoDataTableRow isTableFiltered={!!chips?.length || !!filters?.searchQuery} />
            ) : null}
          </TableBody>
        </Table>
      </TableWrapper>

      <Pagination
        withOptionAll
        rowsNumber={tableState?.length}
        currentPage={pagination?.page}
        totalPages={pagination?.totalPages}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        selectedLimit={pagination?.limit}
        tableName={PROJECT_LIST_TABLE_PARAMETER}
        tableRef={tableRef}
      />

      {openFilter && (
        <FilterModal open={openFilter} setOpen={setOpenFilter} onApply={applyFilter} />
      )}
    </SummaryWrapper>
  );
}
