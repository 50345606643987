import React from 'react';
import styles from './AssetTablesToggle.module.scss';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useAssetActions, useAssetSelector } from 'hooks/Asset';
import { useTicketActions } from 'hooks/Ticket';
import { useHistory } from 'react-router-dom';

import AssetLocationHistory from './Tables/History/AssetLocationHistory';
import AssetInspectionHistory from './Tables/History/AssetInspectionHistory';
import AssetVerificationHistory from './Tables/History/AssetVerificationHistory';

import AssetOpenedTickets from './Tables/Tickets/AssetOpenedTickets';
import AssetClosedTickets from './Tables/Tickets/AssetClosedTickets';

import ToggleBar from 'components/ToggleBars/ToggleBar';
import { MainButton } from 'components/StyledComponents';
import { DownloadCSVButton } from 'components/Buttons';
import { TICKET_EDIT_PATH } from 'constants/routeConstants';
import AssetLooseItems from './Tables/LooseItems/LooseItems';
import { fileDownload } from 'helpers/AppHelpers';

export default function AssetTablesToggle() {
  const isMobile = useMobileViewport();
  const history = useHistory();

  const {
    selectedTab,
    currentAsset,
    locationHistoryFilter,
    inspectionHistoryFilter,
    verificationHistoryFilter
  } = useAssetSelector();
  const {
    setTablesTabAction,
    getLocationHistoryCsvAction,
    getInspectionVerificationHistoryCsvAction
  } = useAssetActions();
  const { setPrefilledAssetAction } = useTicketActions();

  const TOGGLE_BUTTONS = [
    { id: 1, value: 'locationHistory', label: isMobile ? 'Location' : 'Location History' },
    {
      id: 2,
      value: 'verificationHistory',
      label: isMobile ? 'Verification' : 'Verification History'
    },
    { id: 3, value: 'inspectionHistory', label: isMobile ? 'Inspection' : 'Inspection History' },
    { id: 4, value: 'openedTickets', label: 'Opened Ticket' },
    { id: 5, value: 'closedTickets', label: 'Closed Ticket' },
    { id: 6, value: 'looseItems', label: 'Loose Items' }
  ];

  const changeTab = (tab) => setTablesTabAction(tab);

  const handleCreateTicketClick = () => {
    setPrefilledAssetAction(currentAsset);
    history.push(TICKET_EDIT_PATH);
  };

  const downloadCsv = () => {
    const actions = {
      locationHistory: getLocationHistoryCsvAction,
      inspectionHistory: getInspectionVerificationHistoryCsvAction,
      verificationHistory: getInspectionVerificationHistoryCsvAction
    };

    const filters = {
      locationHistory: { ...locationHistoryFilter, pagination: null },
      inspectionHistory: {
        filters: { ...inspectionHistoryFilter.filters, isVerification: false },
        pagination: null
      },
      verificationHistory: {
        filters: { ...verificationHistoryFilter.filters, isVerification: true },
        pagination: null
      }
    };

    const action = actions[selectedTab];
    const query = filters[selectedTab];

    return action(query).then((file) => file?.link && fileDownload(file));
  };

  const areTicketsSelected = ['openedTickets', 'closedTickets'].includes(selectedTab);
  const shouldDisplayCSV = ['locationHistory', 'verificationHistory', 'inspectionHistory'].includes(
    selectedTab
  );

  return (
    <div className={styles.wrapper}>
      {!isMobile && (
        <div className={styles.header}>
          {shouldDisplayCSV && <DownloadCSVButton onClick={downloadCsv} />}
          {!currentAsset?.deleted && areTicketsSelected && (
            <MainButton text="Create ticket" action={handleCreateTicketClick} type="primary" />
          )}
        </div>
      )}
      <ToggleBar
        buttons={TOGGLE_BUTTONS}
        selectedTab={selectedTab}
        onChange={changeTab}
        classes={styles.toggle}
      />
      {isMobile && !currentAsset?.deleted && areTicketsSelected && (
        <div className={styles.createTicketButton}>
          <MainButton text="Create ticket" action={handleCreateTicketClick} type="primary" />
        </div>
      )}

      {selectedTab === 'locationHistory' && <AssetLocationHistory />}

      {selectedTab === 'verificationHistory' && <AssetVerificationHistory />}

      {selectedTab === 'inspectionHistory' && <AssetInspectionHistory />}

      {selectedTab === 'openedTickets' && <AssetOpenedTickets />}

      {selectedTab === 'closedTickets' && <AssetClosedTickets />}

      {selectedTab === 'looseItems' && <AssetLooseItems />}
    </div>
  );
}
