import api from '../api';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../helpers/AppHelpers';
import { browserHistory } from '../browserHistory';
import {
  SET_TICKET_LIST_FILTER,
  SET_CLOSED_SERVICE_TICKETS_LIST,
  SET_TICKETS_COUNTING,
  SET_TICKETS_FILTER_CRITERIA,
  SET_TICKETS_CREATION_FORM,
  SET_CURRENT_TICKET,
  CLEAR_TICKETS_STATE,
  SET_TICKET_PREFILLED_ASSET
} from 'constants/reduceConstants';
import {
  SUCCESS_CREATE_TICKET,
  SUCCESS_DELETE_TICKET,
  SUCCESS_UPDATE_TICKET
} from '../constants/infoSnackbarData';
import { TICKET_DETAILED_PATH, TICKETS_LIST_PATH } from '../constants/routeConstants';

export const getServiceTicketsList = (query) => () =>
  api.ticket
    .getServiceTicketsListApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getClosedServiceTicketsList = (query) => (dispatch) => {
  return api.ticket
    .getServiceTicketsListApi({
      ...query,
      filters: { ...query.filters, isOpened: false }
    })
    .then((res) => {
      dispatch({ type: SET_CLOSED_SERVICE_TICKETS_LIST, payload: res });
      return res;
    })
    .catch((err) => console.log(err));
};

export const getTicketsCountings = (query) => (dispatch) => {
  api.ticket
    .getTicketCountingsApi(query)
    .then((res) => dispatch({ type: SET_TICKETS_COUNTING, payload: res }))
    .catch((err) => console.log(err));
};

export const getSummaryCsvResource = (q) => () =>
  api.ticket
    .getSummaryCsvResourceApi(q)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getTicketsFilterCriteria = () => (dispatch) =>
  api.ticket
    .getTicketsFilterCriteriaApi()
    .then((res) => {
      dispatch({ type: SET_TICKETS_FILTER_CRITERIA, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const getCreationFrom = (q) => (dispatch) =>
  api.ticket
    .getCreationFromApi(q)
    .then((res) => {
      dispatch({ type: SET_TICKETS_CREATION_FORM, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const getSingleTicket = (id) => (dispatch) => {
  api.ticket
    .getSingleTicketApi(id)
    .then((res) => dispatch({ type: SET_CURRENT_TICKET, payload: res }))
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      browserHistory.push(TICKETS_LIST_PATH);
    });
};

export const deleteTicket = (id) => () => {
  return api.ticket.deleteTicketApi(id).then(
    () => {
      enqueueSuccessSnackbar(SUCCESS_DELETE_TICKET);
      browserHistory.push(TICKETS_LIST_PATH);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );
};

export const createTicket = (data) => () => {
  return api.ticket.createTicketApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_CREATE_TICKET);
      browserHistory.push(`${TICKET_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};

export const updateTicket = (data) => () => {
  return api.ticket.updateTicketApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_UPDATE_TICKET);
      browserHistory.push(`${TICKET_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};

export const setPrefilledAsset = (asset) => (dispatch) => {
  dispatch({ type: SET_TICKET_PREFILLED_ASSET, payload: asset });
};

export const setTicketListFilter = (data) => (dispatch) => {
  dispatch({ type: SET_TICKET_LIST_FILTER, payload: data });
};

export const clearState = () => (dispatch) => {
  dispatch({ type: CLEAR_TICKETS_STATE });
};
