import React, { useState, useEffect, useRef } from 'react';
import styles from './CategoryList.module.scss';

import {
  AccordionSummaryWrapper,
  DebouncedSearchField,
  SummaryHeaderWrapper,
  SummaryPageHeader,
  SummaryTableControls
} from 'components/SummaryComponents';
import { BlueButton, StyledButton } from 'components/Buttons';
import { Accordion, AccordionSummary, AccordionDetails } from 'components/StyledComponents/';
import StyledLink from 'components/StyledLink';
import HighlightedText from 'components/HighlightedText';

import { useHistory } from 'react-router-dom';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';
import { usePrefixCategoryActions, usePrefixCategorySelector } from 'hooks/PrefixCategory';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  CATEGORY_EDIT_PATH,
  CATEGORY_DETAILED_PATH,
  PREFIX_EDIT_PATH,
  PREFIX_DETAILED_PATH,
  ASSETS_LIST_PATH,
  BATCH_OF_ASSETS_CREATION_PATH
} from 'constants/routeConstants';

export default function CategoryList() {
  const history = useHistory();
  const isMobile = useMobileViewport();
  const allCategories = useRef([]);

  const { isAdminUser, isBasicRoleUser } = useUserConfig();

  const { searchValue } = usePrefixCategorySelector();
  const { getCategoryListAction, setActiveCategoryAction, setSearchValueAction, clearStateAction } =
    usePrefixCategoryActions();

  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [openAccordionIds, setOpenAccordionIds] = useState([]);

  useEffect(() => {
    getCategoryListAction().then((res) => {
      allCategories.current = res;
      setCategories(res);
    });
  }, []);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const addPrefixByCategory = (category) => {
    setActiveCategoryAction(category);
    history.push(PREFIX_EDIT_PATH);
  };

  const filterCategoriesAndToggleAccordions = (searchTerm) => {
    if (!searchTerm) {
      return {
        processedCategories: allCategories.current,
        filteredProcessedCategories: allCategories.current,
        openAccordionIds: []
      };
    }

    const lowercasedTerm = searchTerm.toLowerCase();

    const processedCategories = allCategories.current.map((category) => {
      const filteredPrefixes = category.assetPrefixes.filter(
        ({ prefix, description }) =>
          prefix.toLowerCase().includes(lowercasedTerm) ||
          description.toLowerCase().includes(lowercasedTerm)
      );

      return { ...category, assetPrefixes: filteredPrefixes };
    });

    const filteredProcessedCategories = processedCategories.filter(
      ({ assetPrefixes }) => assetPrefixes.length
    );
    const openAccordionIds = filteredProcessedCategories.map((category) => category.id);

    return { processedCategories, filteredProcessedCategories, openAccordionIds };
  };

  const searchAssetPrefixes = (value) => {
    if ((!value && !searchValue) || value === searchValue) return;

    const { processedCategories, filteredProcessedCategories, openAccordionIds } =
      filterCategoriesAndToggleAccordions(value);

    setSearchValueAction(value);
    setCategories(processedCategories);
    setFilteredCategories(filteredProcessedCategories);
    setOpenAccordionIds(openAccordionIds);
  };

  const isAccordionOpen = (id) => openAccordionIds.includes(id);

  const toggleAccordion = (id) => {
    if (isAccordionOpen(id)) {
      setOpenAccordionIds(openAccordionIds.filter((openId) => openId !== id));
    } else {
      setOpenAccordionIds([...openAccordionIds, id]);
    }
  };

  const createBatch = () => history.push(BATCH_OF_ASSETS_CREATION_PATH);
  const addCategory = () => history.push(CATEGORY_EDIT_PATH);

  const AddCategoryButton = () =>
    isAdminUser ? (
      <StyledButton label="Add category" onClick={addCategory} classes={styles.main_button} />
    ) : null;

  return (
    <AccordionSummaryWrapper>
      <SummaryHeaderWrapper>
        <SummaryPageHeader title="Prefix/category list">
          {!isMobile && (
            <div className={styles.controls}>
              {!isBasicRoleUser && (
                <StyledButton
                  label="Create batch of assets"
                  onClick={createBatch}
                  classes={styles.main_button}
                />
              )}
              <AddCategoryButton />
            </div>
          )}
        </SummaryPageHeader>
        <SummaryTableControls>
          <div className={styles.searchBlock}>
            <DebouncedSearchField filterValue={searchValue} onSearch={searchAssetPrefixes} />
            {!filteredCategories?.length && searchValue?.length > 1 && (
              <span className={styles.searchBlock__hint}>No Matches</span>
            )}
          </div>
        </SummaryTableControls>
      </SummaryHeaderWrapper>

      {isMobile && <AddCategoryButton />}

      {!!categories.length &&
        categories.map((category) => (
          <Accordion
            key={category.id}
            expanded={isAccordionOpen(category.id)}
            className={styles.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className={styles.accordion__icon} />}
              IconButtonProps={{ onClick: () => toggleAccordion(category.id) }}>
              <div className={styles.accordion__summary}>
                <div className={styles.accordion__title}>
                  <StyledLink bold to={`${CATEGORY_DETAILED_PATH}/${category.id}`}>
                    {category.name}
                  </StyledLink>
                </div>
                {isAdminUser && (
                  <BlueButton
                    label="Add prefix"
                    className={styles.accordion__button}
                    onClick={() => addPrefixByCategory(category)}
                  />
                )}
              </div>
            </AccordionSummary>

            <AccordionDetails>
              <div className={styles.accordion__details}>
                {category.assetPrefixes.length ? (
                  <>
                    {category.assetPrefixes?.map(({ id, prefix, description }) => (
                      <div key={id} className={styles.accordion__content}>
                        <div className={styles.accordion__prefix}>
                          <StyledLink bold to={`${PREFIX_DETAILED_PATH}/${id}`}>
                            <HighlightedText text={prefix} searchTerm={searchValue} />
                          </StyledLink>
                        </div>
                        <div className={styles.accordion__description}>
                          <HighlightedText text={description} searchTerm={searchValue} />
                        </div>
                        <div className={styles.accordion__controls}>
                          <StyledLink to={`${ASSETS_LIST_PATH}?prefixIds=${id}`}>
                            VIEW ASSETS
                          </StyledLink>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className={styles.accordion__empty}>
                    {searchValue ? 'No matches' : 'No prefixes'}
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
    </AccordionSummaryWrapper>
  );
}
