import api from '../api';
import { enqueueErrorSnackbar } from 'helpers/AppHelpers';

import {
  SET_DASHBOARD_CONFIG,
  SET_DASHBOARD_DATA,
  CLEAR_DASHBOARD_DATA,
  SET_ASSETS_PREFILTER
} from 'constants/reduceConstants';

export const getDashboardConfig = () => (dispatch) => {
  return api.dashboard
    .getDashboardConfigApi()
    .then((res) => dispatch({ type: SET_DASHBOARD_CONFIG, payload: res }))
    .catch((err) => console.log(err));
};

export const updateDashboardConfig = (data) => (dispatch) => {
  return api.dashboard.updateDashboardConfigApi(data).then(
    (res) => {
      dispatch({ type: SET_DASHBOARD_CONFIG, payload: res });
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const getDashboardTablesData = (query) => (dispatch) => {
  api.dashboard
    .getDashboardTablesDataApi(query)
    .then((res) => dispatch({ type: SET_DASHBOARD_DATA, payload: res }))
    .catch((err) => console.log(err));
};

export const setAssetsPrefilter = (filter) => (dispatch) => {
  dispatch({ type: SET_ASSETS_PREFILTER, payload: filter });
};

export const clearDashboardData = () => (dispatch) => {
  dispatch({ type: CLEAR_DASHBOARD_DATA });
};

export const getCsvResource = (q) => () =>
  api.dashboard
    .getCsvResourceApi(q)
    .then((res) => res)
    .catch((err) => console.log(err));
