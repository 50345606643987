import { useDispatch } from 'react-redux';

import {
  setUnsavedFormData,
  setLoadMoreFlag,
  getUserConfig,
  setTopPosition,
  setSessionId
} from 'actions/commonActions';
import { onLogout } from 'actions/authActions';

export function useCommonActions() {
  const dispatch = useDispatch();

  const setUnsavedFormDataAction = (flag) => dispatch(setUnsavedFormData(flag));

  const getUserConfigAction = () => dispatch(getUserConfig());

  const setLoadMoreFlagAction = (bool) => dispatch(setLoadMoreFlag(bool));

  const setTopPositionAction = (v) => dispatch(setTopPosition(v));

  const setSessionIdAction = (v) => dispatch(setSessionId(v));

  const logoutAction = () => dispatch(onLogout());

  return {
    getUserConfigAction,
    setLoadMoreFlagAction,
    setUnsavedFormDataAction,
    setTopPositionAction,
    setSessionIdAction,
    logoutAction
  };
}
