import {
  SET_TICKET_TYPES_FILTER,
  SET_TICKET_TYPE_CREATION_FORM,
  CLEAR_TICKET_TYPES_STATE
} from 'constants/reduceConstants';

const initialState = {
  filter: { responseReceived: false },
  creationForm: {}
};

const ticketTypesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TICKET_TYPES_FILTER:
      return { ...state, filter: payload };
    case SET_TICKET_TYPE_CREATION_FORM:
      return { ...state, creationForm: payload };
    case CLEAR_TICKET_TYPES_STATE:
      return {
        ...initialState,
        filter: { ...state.filter, responseReceived: false }
      };
    default:
      return state;
  }
};

export default ticketTypesReducer;
