import { createSelector } from 'reselect';

const data = (state) => state.looseItemsAndPartsData;

// Summary
export const selectFilterData = () => createSelector([data], (state) => state.filter);

export const selectFilterCriteriaData = () =>
  createSelector([data], (state) => state.filterCriteria);

export const selectSelectedRowsData = () => createSelector([data], (state) => state.checkedItems);

export const selectSelectedColumnsData = () =>
  createSelector([data], (state) => state.customColumns);

// Others
export const selectLooseItemFieldsData = () =>
  createSelector([data], (state) => state.looseItemFields);

export const selectSingleItemData = () => createSelector([data], (state) => state.currentItem);

export const selectCreateLocationsData = () =>
  createSelector([data], (state) => state.createLocationList);

export const selectCreateCategoriesData = () =>
  createSelector([data], (state) => state.createCategoryList);
