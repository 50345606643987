import { useDispatch } from 'react-redux';

import {
  getAssembledBOMList,
  setFilter,
  setFilterCriteria,
  getLocations,
  getLocationsWithSublocations,
  getConfigurations,
  getStatuses,
  getLooseItemConfigurations,
  getConfigurationData,
  createAssemble,
  editAssemble,
  clearState,
  setCreationForm,
  getSingleAssemble,
  getCsvResource
} from 'actions/assembledBOMActions';

export function useAssembledBOMActions() {
  const dispatch = useDispatch();

  const getAssembledBOMListAction = (q) => dispatch(getAssembledBOMList(q));

  const getSingleAssembleAction = (id) => dispatch(getSingleAssemble(id));

  const setFilterAction = (data) => dispatch(setFilter(data));

  const getFilterCriteriaAction = () =>
    Promise.all([
      dispatch(getLocations({ filters: { active: true, includeUserCheck: true } })),
      dispatch(getStatuses()),
      dispatch(getLooseItemConfigurations())
    ]).then(([locations, statuses, configurations]) => {
      const criteria = { locations: locations?.items || [], statuses, configurations };
      dispatch(setFilterCriteria(criteria));
      return criteria;
    });

  const getCreationFormAction = () =>
    Promise.all([
      dispatch(getLocationsWithSublocations({ filters: { active: true } })),
      dispatch(getConfigurations())
    ]).then(([locations, configurations]) => {
      const creationForm = { locations: locations || [], configurations };
      dispatch(setCreationForm(creationForm));
      return creationForm;
    });

  const getConfigurationDataAction = (q) => dispatch(getConfigurationData(q));

  const createAssembleAction = (data) => dispatch(createAssemble(data));
  const updateAssembleAction = (data) => dispatch(editAssemble(data));

  const getCsvResourceAction = (q) => dispatch(getCsvResource(q));

  const clearStateAction = () => dispatch(clearState());

  return {
    getAssembledBOMListAction,
    setFilterAction,
    getFilterCriteriaAction,
    getSingleAssembleAction,
    getCreationFormAction,
    getConfigurationDataAction,
    createAssembleAction,
    updateAssembleAction,
    getCsvResourceAction,
    clearStateAction
  };
}
