import React from 'react';
import styles from './Form.module.scss';

import { FormLabel, FormRow, FormSearchInput, FormSelectInput } from 'components/FormComponents';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';
import FormInputText from 'components/FormComponents/FormInputText/FormInputText_v2';

import { useFormContext } from 'react-hook-form';
import { useAssembledBOMActions, useAssembledBOMSelector } from 'hooks/AssembledBOM';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useParams } from 'react-router-dom';

import { enqueueErrorSnackbar } from 'helpers/AppHelpers';

import { CANNOT_CREATE_ASSEMBLE, TRY_AGAIN_MESSAGE } from 'constants/infoSnackbarData';

import {
  defaultQuantityValue,
  generateQuantityOptions,
  isValidNumber,
  transformAssetPrefixes,
  transformLooseItems
} from '../helpers';

export default function General({ onLoad, onConfigurationChange }) {
  const { id } = useParams();
  const isMobile = useMobileViewport();

  const { getValues, setValue, clearErrors, watch, reset } = useFormContext();

  const { getConfigurationDataAction } = useAssembledBOMActions();
  const { creationForm } = useAssembledBOMSelector();
  const { configurations, locations } = creationForm;

  const selectSublocation = (name, value) => setValue(name, value);
  const selectLocation = (name, value) => {
    setValue(name, value);
    setValue('sublocation', null);
  };

  const resetTables = () => {
    setValue('looseItems', []);
    setValue('assetPrefixes', []);
  };

  const clearTablesErrors = () => clearErrors(['looseItems', 'looseItemIds', 'assetPrefixes']);
  const resetQuantityFields = () =>
    reset({
      ...getValues(),
      quantityOptions: [],
      selectedQuantityOption: {},
      looseItems: [],
      assetPrefixes: []
    });

  const updateTables = (fieldName, data, transformFunction, multiplier) => {
    const transformedData = data?.length ? transformFunction(data, multiplier) : [];
    setValue(fieldName, transformedData);
  };
  const updateLooseItems = (data, multiplier) =>
    updateTables('looseItems', data, transformLooseItems, multiplier);
  const updateAssetPrefixes = (data, multiplier) =>
    updateTables('assetPrefixes', data, transformAssetPrefixes, multiplier);

  const handleConfigurationSelection = async (name, value) => {
    onLoad();
    resetTables();
    clearTablesErrors();

    const config = await getConfigurationDataAction({ id: value.id });
    const { id, maximumQuantity, looseItems, assetPrefixes } = config;

    if (!id) {
      enqueueErrorSnackbar(TRY_AGAIN_MESSAGE);
      return;
    }

    setValue(name, config);
    onConfigurationChange(config);

    if (!isValidNumber(maximumQuantity)) {
      enqueueErrorSnackbar(CANNOT_CREATE_ASSEMBLE);
      resetQuantityFields();
      return;
    }

    setValue('quantityOptions', generateQuantityOptions(maximumQuantity));
    setValue('selectedQuantityOption', defaultQuantityValue);

    updateLooseItems(looseItems);
    updateAssetPrefixes(assetPrefixes);
  };

  const selectQuantity = ({ name, value }) => {
    setValue(name, value);
    updateLooseItems(getValues('looseItemConfiguration.looseItems'), value.quantity);
    updateAssetPrefixes(getValues('looseItemConfiguration.assetPrefixes'), value.quantity);
    clearTablesErrors();
  };

  const locationWatcher = watch('location');
  const quantityOptionsWatcher = watch('quantityOptions');

  const QuantityField = () => (
    <FormRow>
      <FormLabel required>Available amount</FormLabel>
      <FormSelectInput
        name="selectedQuantityOption"
        options={quantityOptionsWatcher}
        onSelect={selectQuantity}
        isDisabled={!quantityOptionsWatcher?.length || !!id}
      />
    </FormRow>
  );

  return (
    <div className={styles.general}>
      <div className={styles.general__block}>
        <FormRow>
          <FormLabel required>Configuration</FormLabel>
          <FormSearchInput
            name="looseItemConfiguration"
            options={configurations || []}
            onSelect={handleConfigurationSelection}
            isDisabled={!!id}
          />
        </FormRow>
        {isMobile && <QuantityField />}
        <FormRow>
          <FormLabel required>Assembly name</FormLabel>
          <FormInputText name="name" options={{ max: 1000 }} />
        </FormRow>
        <FormRow>
          <FormLabel required>Location</FormLabel>
          <FormSearchInput name="location" options={locations || []} onSelect={selectLocation} />
        </FormRow>
        <FormRow>
          <FormLabel>Sublocation</FormLabel>
          <FormSearchInput
            clearable
            name="sublocation"
            options={locationWatcher?.sublocations || []}
            onSelect={selectSublocation}
            isDisabled={!locationWatcher?.id}
          />
        </FormRow>
        <FormRow type="column">
          <FormLabel>Notes</FormLabel>
          <FormTextArea name="notes" options={{ max: 1000 }} />
        </FormRow>
      </div>
      {!isMobile && (
        <div className={styles.general__block}>
          <QuantityField />
        </div>
      )}
    </div>
  );
}
