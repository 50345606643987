import React, { useEffect } from 'react';
import styles from './components.module.scss';

import { useParams, useLocation } from 'react-router-dom';

import { useAssetActions, useAssetSelector } from 'hooks/Asset';
import { ASSETS_LIST_PATH } from 'constants/routeConstants';

export default function AssetDetailsWrapper({ children }) {
  const { id } = useParams();
  const location = useLocation();

  const { currentAsset, filter } = useAssetSelector();
  const { getAssetAction, getNeighboringAssetsAction, setAssetAction, clearStateAction } =
    useAssetActions();

  useEffect(async () => {
    if (!id) return;

    const asset = await getAssetAction(id);

    const isSummaryOrigin = location?.state?.from === ASSETS_LIST_PATH;

    const query = {
      ...filter,
      filters: {
        ...filter?.filters,
        currentAssetId: id,
        ...(isSummaryOrigin && { isSummaryOrigin: true })
      }
    };

    const { nextAsset, previousAsset, filterInUse } = await getNeighboringAssetsAction(query);

    setAssetAction({
      ...asset,
      nextAsset,
      previousAsset,
      isSummaryFilterApplied: isSummaryOrigin && filterInUse
    });
  }, [id]);

  useEffect(() => {
    return () => clearStateAction();
  }, []);

  return (
    <section className={styles.wrapper}>{currentAsset?.id === +id && <>{children}</>}</section>
  );
}
