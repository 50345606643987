import {
  SET_LOAD_MORE_FLAG,
  SET_TEAM_MEMBER_HISTORY_FILTER,
  SET_TEAM_MEMBER_HISTORY_FILTER_CRITERIA,
  CLEAR_TEAM_MEMBER_HISTORY_STATE
} from 'constants/reduceConstants';
import api from 'api';

export const getHistory = (query) => (dispatch) =>
  api.teamMemberHistory
    .getHistoryApi(query)
    .then((res) => {
      dispatch({ type: SET_LOAD_MORE_FLAG, payload: false });
      return res;
    })
    .catch((err) => console.log(err));

export const getHistoryConfig = () => () =>
  api.teamMemberHistory
    .getHistoryConfigApi()
    .then((res) => res)
    .catch((err) => console.log(err));

export const getFilterCriteria = (q) => (dispatch) =>
  api.teamMemberHistory
    .getFilterCriteriaApi(q)
    .then((res) => {
      dispatch({ type: SET_TEAM_MEMBER_HISTORY_FILTER_CRITERIA, payload: res });
      return res;
    })
    .catch((err) => console.log(err));

export const updateConfig = (config) => () =>
  api.teamMemberHistory
    .updateUserConfigApi(config)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getSummaryCsvResource = (q) => () =>
  api.teamMemberHistory
    .getSummaryCsvResourceApi(q)
    .then((res) => res)
    .catch((err) => console.log(err));

export const setFilter = (data) => (dispatch) => {
  dispatch({ type: SET_TEAM_MEMBER_HISTORY_FILTER, payload: data });
};
export const setFilterCriteria = (payload) => (dispatch) => {
  dispatch({ type: SET_TEAM_MEMBER_HISTORY_FILTER_CRITERIA, payload });
};

export const clearState = () => (dispatch) => dispatch({ type: CLEAR_TEAM_MEMBER_HISTORY_STATE });
