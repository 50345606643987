import { createSelector } from 'reselect';

const bomConfigurationsData = (state) => state.bomConfigurationsData;

export const selectSingleConfigurationData = () =>
  createSelector([bomConfigurationsData], (state) => state.currentConfiguration);

export const selectSearchValueData = () =>
  createSelector([bomConfigurationsData], (state) => state.searchValue);

export const selectConfigurationCreationFormData = () =>
  createSelector([bomConfigurationsData], (state) => state.creationForm);
