import {
  SET_LOCATIONS_FOR_LEAVE_LIST,
  SET_STATUSES_FOR_LEAVE_LIST,
  SET_PROJECTS_FOR_LEAVE_LIST,
  SET_LEAVE_LIST_FILTER,
  SET_CURRENT_LEAVE_LIST,
  CLEAR_LEAVE_LIST_STATE,
  SET_LEAVE_LIST_STATUSES,
  SET_TRUCK_OR_TRAILERS
} from 'constants/reduceConstants';

const initialState = {
  filter: { responseReceived: false },
  filterCriteria: {},
  currentLeaveList: {},
  locationList: [],
  statusList: [],
  projectList: [],
  leaveListAssetStatuses: [],
  truckOrTrailers: []
};

const leaveListReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOCATIONS_FOR_LEAVE_LIST:
      return {
        ...state,
        locationList: payload
      };
    case SET_STATUSES_FOR_LEAVE_LIST:
      return {
        ...state,
        statusList: payload
      };
    case SET_PROJECTS_FOR_LEAVE_LIST:
      return {
        ...state,
        projectList: payload
      };
    case SET_CURRENT_LEAVE_LIST:
      return { ...state, currentLeaveList: payload };
    case SET_LEAVE_LIST_STATUSES:
      return { ...state, leaveListAssetStatuses: payload };
    case SET_LEAVE_LIST_FILTER:
      return { ...state, filter: payload };
    case SET_TRUCK_OR_TRAILERS:
      return { ...state, truckOrTrailers: payload };
    case CLEAR_LEAVE_LIST_STATE:
      return { ...initialState, filter: { ...state.filter, responseReceived: false } };
    default:
      return state;
  }
};

export default leaveListReducer;
