import React from 'react';
import styles from './Header.module.scss';

import { ReactComponent as FastBackwardIco } from 'assets/images/fastBackwardIco.svg';
import { ReactComponent as FastForwardIco } from 'assets/images/fastForwardIco.svg';

import { useHistory, useLocation } from 'react-router-dom';
import { useAssetActions, useAssetSelector } from 'hooks/Asset';

import { StyledButton } from 'components/Buttons';
import Comment from 'components/Comment';

import { ASSET_DETAILED_PATH } from 'constants/routeConstants';

export default function AssetNavigationControls() {
  const history = useHistory();
  const location = useLocation();

  const { clearAllStateAction } = useAssetActions();
  const { currentAsset } = useAssetSelector();
  const { nextAsset, previousAsset, isSummaryFilterApplied } = currentAsset;

  const handleNextAssetClick = () => {
    clearAllStateAction();
    history.push({ pathname: `${ASSET_DETAILED_PATH}/${nextAsset.id}`, state: location.state });
  };

  const handlePrevAssetClick = () => {
    clearAllStateAction();
    history.push({ pathname: `${ASSET_DETAILED_PATH}/${previousAsset.id}`, state: location.state });
  };

  return (
    <div className={styles.navigation__buttons}>
      {previousAsset && (
        <StyledButton classes={styles.navigation__btn} onClick={handlePrevAssetClick}>
          <FastBackwardIco />
          {previousAsset.drCode}
        </StyledButton>
      )}
      {nextAsset && (
        <StyledButton classes={styles.navigation__btn} onClick={handleNextAssetClick}>
          {nextAsset.drCode}
          <FastForwardIco />
        </StyledButton>
      )}
      {isSummaryFilterApplied && (
        <Comment className={styles.navigation__hint} label="Filter in use" />
      )}
    </div>
  );
}
