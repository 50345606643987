import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import StyledDatePicker from 'components/StyledDatePicker/StyledDatePicker';
import styles from './FormDatePicker.module.scss';
import { formatDate } from 'helpers/AppHelpers';
import clsx from 'clsx';

export default function FormDatePicker({
  name,
  min,
  max,
  small,
  placement,
  isClearable,
  isDisabled,
  extraAction,
  format,
  errorHighlightOnly,
  customPositionClasses
}) {
  const { control, setValue, clearErrors } = useFormContext();

  const handleDateChange = (date, isInvalid) => {
    isInvalid && clearErrors(name);
    setValue(name, date ? formatDate(date, format) : null, { shouldDirty: true });
    extraAction && extraAction(name, date ? formatDate(date, format) : null);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value }, fieldState: { error } }) => (
        <div
          className={clsx(
            styles.inputWrapper,
            !!small && styles.small,
            isDisabled && styles.disabled
          )}>
          <StyledDatePicker
            min={min}
            max={max}
            value={value}
            onChange={(date) => handleDateChange(date, !!error?.message)}
            isInvalid={!!error}
            placement={placement}
            isClearable={isClearable}
            format={format}
            customPositionClasses={customPositionClasses}
          />
          {!!error && !errorHighlightOnly && <span>{error.message}</span>}
        </div>
      )}
    />
  );
}
