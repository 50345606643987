import api from '../api';
import { queryToString } from 'helpers/AppHelpers';

export const getLeavelistsApi = (query) =>
  api.get(`/Leavelists?${queryToString(query)}`).then((res) => res.data);

export const getFilterCriteriaApi = () =>
  api.get(`/Picklists/FilterCriteria`).then((res) => res.data);

export const getLocationsApi = (query) =>
  api.get(`/Locations/ShortedWithSublocations?${queryToString(query)}`).then((res) => res.data);

export const getStatusesApi = () => api.get('/LeavelistStatuses').then((res) => res.data);

export const getProjectsApi = (query) =>
  api.get(`/ShortedProjects?${queryToString(query)}`).then((res) => res.data);

export const getSingleLeaveListApi = (id) => api.get(`/Leavelist?id=${id}`).then((res) => res.data);

export const createLeaveListApi = (params) =>
  api.post('/Leavelist', params).then((res) => res.data);

export const updateLeaveListApi = (params) => api.put('/Leavelist', params).then((res) => res.data);

export const checkLeaveListApi = (params) =>
  api.post('/LeavelistProjectLocation', params).then((res) => res.data);

export const getAssetStatusesListApi = () =>
  api.get('/LeavelistAssetStatuses').then((res) => res.data);

export const deleteLeaveListApi = (id) => api.delete(`/Leavelist?id=${id}`).then((res) => res.data);

export const getAssetsApi = (query) =>
  api.get(`/LeavelistAssets?${queryToString(query)}`).then((res) => res.data);

export const checkBillOfLadingItemsApi = (params) =>
  api.get(`/BillOfLading/CheckItems?${queryToString(params)}`).then((res) => res);

export const getCsvResourceApi = (q) =>
  api.get(`/LeavelistAssets/Serialized?${queryToString(q)}`).then((res) => res.data);
