import React, { useCallback, useEffect, useState } from 'react';
import styles from './ReportEditWrapper.module.scss';

import { useHistory, useParams } from 'react-router-dom';
import { useFieldArray, useFormContext } from 'react-hook-form';

import DeleteIcon from '@material-ui/icons/Delete';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import HeaderButton from 'components/DetailsComponents/HeaderButton';
import { MainButton } from 'components/StyledComponents';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useReportActions, useReportSelector } from 'hooks/ReportList';
import { useUserConfig } from 'hooks/useUserConfig';
import { useSearchParams } from 'hooks/useSearchParams';
import { useCommonActions, useCommonSelector } from 'hooks/Common';

import { isEqual } from 'helpers/AppHelpers';
import { getErrorsProperties } from 'helpers/ErrorValidator';
import {
  getCreationFormQueryByParams,
  getCreationFormQueryByReport,
  getPresetValues,
  getRootListPath,
  validateAssetTemplateField,
  validateChoiceField,
  validateLocationTemplateField,
  validateQueryParams,
  validateSignatureField,
  validateTableField,
  validateTextField
} from './helpers';
import {
  EXIT_WITHOUT_SAVING,
  REMOVE_REPORT,
  REMOVE_REPORT_TYPE,
  REPORT_HAS_BEEN_CLOSED_TYPE,
  SAVE_REPORT,
  SAVE_REPORT_TYPE,
  SUBMIT_REPORT,
  SUBMIT_REPORT_TYPE,
  WITHOUT_SAVING_BY_NAV_TYPE,
  WITHOUT_SAVING_TYPE
} from 'constants/dialogPopupsData';

import useDataSync from './useDataSync';

import CustomDetails from '../CustomDetails/CustomDetails';
import SignatureDetails from '../SignatureDetails/SignatureDetails';
import FilesDetails from '../FilesDetails';
import CreatorAndEditorsDetails from '../CreatorAndEditorsDetails';
import AssignmentDetails from '../AssignmentDetails';
import LocationDetails from '../LocationReportDetails/LocationDetails';
import ConnectedUsers from './ConnectedUsers';

export default function ReportEditWrapper({ reportType, getWeather, children }) {
  const { id } = useParams();
  const history = useHistory();
  const isMobile = useMobileViewport();

  const goToSummary = useCallback(
    () => history.push(getRootListPath(reportType)),
    [reportType, history]
  );

  const [isFormReady, setFormReady] = useState(false);
  const [socketEnabled, setSocketEnabled] = useState(false);
  const [modalData, setModalData] = useState({});

  const queryParams = useSearchParams([
    'generalReportTemplateId',
    'locationId',
    'projectId',
    'assetId'
  ]);

  const { control, formState, getValues, setError, setValue, reset, clearErrors } =
    useFormContext();
  const { isDirty, errors } = formState;
  const isFormInvalid = !!Object.values(errors).filter(Boolean).length;
  const { fields, append, remove } = useFieldArray({ control, name: 'signatures' });

  const {
    isAdminUser,
    isBasicRoleUser,
    isFieldTechUser,
    isPersonnelManagerUser,
    isEntityCreatorUser,
    isLocationInConfig,
    isConfigReceived
  } = useUserConfig();

  const { unsavedFormData } = useCommonSelector();
  const { setUnsavedFormDataAction } = useCommonActions();
  const { currentGeneralReport } = useReportSelector();
  const {
    updateGeneralReportAction,
    removeGeneralReportAction,
    clearStateAction,
    getSingleGeneralReportAction,
    getCreationFormAction
  } = useReportActions();

  const saveReport = useCallback(() => {
    if (currentGeneralReport?.submitted) return;

    const options = { type: 'save', shouldBlur: true, includeNotification: false, sessionId };

    updateGeneralReportAction(getValues(), options).then((res) => {
      if (!getValues('id')) {
        setValue('id', res.id);
        setSocketEnabled(true);
      }
      if (!isEqual(res?.additionalContributors || [], getValues('additionalContributors'))) {
        setValue('additionalContributors', res.additionalContributors);
      }
      if (!isEqual(res?.signatures || [], getValues('signatures'))) {
        setValue('signatures', res.signatures);
      }
    });
  }, [currentGeneralReport, updateGeneralReportAction, setValue, setSocketEnabled]);

  const { connectedUsers, onBlur, onFocus, sessionId } = useDataSync({
    getValues,
    setValue,
    clearErrors,
    setModalData,
    socketEnabled,
    setSocketEnabled,
    onSave: saveReport,
    addSignature: append,
    removeSignature: (i) => remove(i)
  });

  useEffect(() => {
    if (!isConfigReceived || !currentGeneralReport?.id) return;

    const { submitted, isEditable } = currentGeneralReport;

    if (id) {
      if (submitted && !isEditable) goToSummary();
    } else if (!queryParams?.generalReportTemplateId) {
      goToSummary();
    }
  }, [currentGeneralReport, isConfigReceived]);

  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        const report = await getSingleGeneralReportAction(id, reportType);
        if (!report.id) return;
        reset(report);
        await getCreationFormAction(getCreationFormQueryByReport(reportType, report));
        setFormReady(true);
        !report?.submitted && setSocketEnabled(true);
      } else {
        if (!validateQueryParams(reportType, queryParams)) {
          goToSummary();
          return;
        }

        const response = await getCreationFormAction(
          getCreationFormQueryByParams(reportType, queryParams)
        );
        if (!response?.generalReportTemplate?.id) return;

        reset(getPresetValues(reportType, response, getWeather, getValues));
        setFormReady(true);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      setUnsavedFormDataAction(true);
    }
    return () => setUnsavedFormDataAction(false);
  }, [isDirty]);

  useEffect(() => {
    return () => clearStateAction();
  }, []);

  const validateForm = useCallback(() => {
    let isFormValid = true;
    const allFields = getValues('generalReportFields');
    const requiredFields = allFields.filter(({ data }) => data.required);

    const setFieldError = (fieldPath, message) => {
      setError(fieldPath, getErrorsProperties(message));
      isFormValid = false;
    };

    requiredFields.forEach((el) => {
      const { data, generalReportFieldType } = el;
      const needIndex = allFields.findIndex(({ id }) => id === el.id);
      const fieldPath = `generalReportFields[${needIndex}].data`;

      switch (generalReportFieldType.fieldType) {
        case 'Text': {
          validateTextField(data, fieldPath, setFieldError);
          break;
        }
        case 'Choice': {
          validateChoiceField(data, fieldPath, setFieldError);
          break;
        }
        case 'Table': {
          validateTableField(data, fieldPath, setFieldError);
          break;
        }
        case 'Signature': {
          validateSignatureField(data, fieldPath, setFieldError);
          break;
        }
        case 'LocationTemplate': {
          validateLocationTemplateField(data, fieldPath, setFieldError);
          break;
        }
        case 'AssetTemplate': {
          validateAssetTemplateField(data, fieldPath, setFieldError);
          break;
        }
      }
    });

    return isFormValid;
  }, [getValues, setError]);

  const handleSubmitClick = useCallback(() => {
    if (!validateForm()) return;
    setModalData({ ...SUBMIT_REPORT(!!connectedUsers?.length), isOpened: true });
  }, [connectedUsers, validateForm, setModalData]);

  const handleBackClick = useCallback(() => {
    if (isDirty) {
      setModalData({ ...EXIT_WITHOUT_SAVING, isOpened: true });
    } else {
      goToSummary();
    }
  }, [goToSummary, setModalData]);

  const handleRemoveClick = useCallback(() => {
    setModalData({ ...REMOVE_REPORT(!!connectedUsers?.length), isOpened: true });
  }, [connectedUsers, setModalData]);

  const handleSaveClick = useCallback(() => {
    if (currentGeneralReport?.submitted && !validateForm()) return;

    setModalData({ ...SAVE_REPORT, isOpened: true });
  }, [currentGeneralReport, validateForm, setModalData]);

  const agreeModal = useCallback(() => {
    switch (modalData.type) {
      case SAVE_REPORT_TYPE:
        closeModal();
        updateGeneralReportAction(getValues(), {
          type: 'save',
          shouldBlur: false,
          includeNotification: id ? !getValues('submitted') : true,
          sessionId
        });
        break;
      case SUBMIT_REPORT_TYPE:
        closeModal();
        updateGeneralReportAction(
          { ...getValues(), submitted: true },
          { type: 'submit', shouldBlur: false, includeNotification: true, sessionId }
        );
        break;
      case REMOVE_REPORT_TYPE:
        closeModal();
        removeGeneralReportAction(getValues(), { sessionId });
        break;
      case REPORT_HAS_BEEN_CLOSED_TYPE:
        goToSummary();
        break;
      case WITHOUT_SAVING_TYPE:
        goToSummary();
        break;
      case WITHOUT_SAVING_BY_NAV_TYPE:
        history.push(modalData.selectedRouteUrl);
        break;
      default:
        break;
    }
  }, [updateGeneralReportAction, setModalData, goToSummary, getValues, history, modalData]);

  const closeModal = useCallback(() => setModalData({ isOpened: false }), [setModalData]);

  const isSubmitAvailable = useCallback(() => {
    switch (reportType) {
      case 'location':
        if (isBasicRoleUser) return isReportAvailableForBasicUser();
        if (isFieldTechUser || isPersonnelManagerUser)
          return isReportAvailableForFieldTechOrPersonnelManager();
        return true;
      case 'asset':
        return isBasicRoleUser ? isReportAvailableForBasicUser() : true;
      case 'general':
        return isAdminUser ? true : !id || isEntityCreatorUser(getValues('createdByUser')?.email);
    }
  }, [isConfigReceived, id]);

  const isReportAvailableForBasicUser = useCallback(
    () =>
      !id ||
      isEntityCreatorUser(getValues('createdByUser')?.email) ||
      isLocationInConfig(getValues('location')?.id),
    [isConfigReceived]
  );
  const isReportAvailableForFieldTechOrPersonnelManager = useCallback(
    () =>
      !id ||
      isEntityCreatorUser(getValues('createdByUser')?.email) ||
      isLocationInConfig(getValues('location')?.id),
    [isConfigReceived]
  );

  return isFormReady ? (
    <section className={styles.wrapper}>
      {!isMobile && (
        <BreadcrumbsNav
          itemsArray={[{ name: id ? 'Edit a report' : 'Create a report' }]}
          setDialogModalData={setModalData}
          formIsChanged={isDirty}
        />
      )}
      <div className={styles.header}>
        <h1>{id ? 'Edit Report' : 'Create Report'}</h1>
        <div className={styles.header__controls}>
          {!!id && isMobile && isAdminUser && (
            <HeaderButton onClick={handleRemoveClick}>
              <DeleteIcon />
            </HeaderButton>
          )}
          {isMobile && (
            <HeaderButton onClick={handleBackClick}>
              <ChevronLeftIcon />
            </HeaderButton>
          )}
        </div>
      </div>

      {reportType === 'location' && <LocationDetails getWeather={getWeather} onSave={saveReport} />}
      {children}
      <CustomDetails
        reportType={reportType}
        onSave={saveReport}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      <SignatureDetails
        fields={fields}
        append={append}
        remove={remove}
        onSave={saveReport}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      <FilesDetails onSave={saveReport} />
      <CreatorAndEditorsDetails reportType={reportType} />
      <AssignmentDetails onSave={saveReport} />

      <div className={styles.footer}>
        {!!id && !isMobile && isAdminUser && (
          <MainButton text="remove" type="secondary" action={handleRemoveClick} />
        )}
        {!!id && !!getValues('submitted') && (
          <MainButton text="cancel" type="secondary" action={handleBackClick} />
        )}
        <MainButton
          text="save"
          type="primary"
          action={handleSaveClick}
          isDisabled={currentGeneralReport?.submitted ? isFormInvalid : false}
        />

        {isSubmitAvailable() && !getValues('submitted') && (
          <MainButton
            text="submit report"
            type="primary"
            action={handleSubmitClick}
            isDisabled={isFormInvalid}
          />
        )}
      </div>

      <ConnectedUsers users={connectedUsers || []} />

      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
    </section>
  ) : null;
}
