import { useSelector } from 'react-redux';
import {
  selectActiveCategoryData,
  selectCreationFormData,
  selectSinglePrefixData
} from 'pages/PrefixCategoryList/selectors';
import { selectUnsavedFormData } from 'pages/commonSelectors';

export function usePrefixSelector() {
  const currentPrefix = useSelector(selectSinglePrefixData());

  const activeCategory = useSelector(selectActiveCategoryData());

  const unsavedFormData = useSelector(selectUnsavedFormData());

  const creationForm = useSelector(selectCreationFormData());

  return {
    currentPrefix,
    activeCategory,
    creationForm,
    unsavedFormData
  };
}
