import React, { useEffect, useState } from 'react';
import styles from './AssetsInspection.module.scss';

import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import MainButton from 'components/StyledComponents/MainButton';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import { FormMultipleAssetSelect, FormLabel } from 'components/FormComponents';

import { MARK_INSPECTED_ASSETS } from 'constants/dialogPopupsData';

import { useBatchAssetUpdatesActions, useBatchAssetUpdatesSelector } from 'hooks/BatchAssetUpdates';

import { useFormContext } from 'react-hook-form';

import { getOtherTabNames, getUpdatedTabValues, inspectedStatus } from '../helpers';
import { getErrorsProperties } from 'helpers/ErrorValidator';

import { tabs } from '../helpers';

export default function AssetsInspection() {
  const tabName = tabs.assetsInspection;

  const { getValues, clearErrors, setError, formState, setValue, watch } = useFormContext();
  const getFieldName = (name) => `${tabName}.${name}`;
  const { errors } = formState;
  const isFormInvalid = () => !!errors?.[tabName]?.['assets'];

  const { creationForm } = useBatchAssetUpdatesSelector();
  const { setAssetsInspectionStatusAction } = useBatchAssetUpdatesActions();

  const { locations } = creationForm;

  const [modalData, setModalData] = useState({});
  const closeModal = () => setModalData({ isOpened: false });

  useEffect(() => {
    return () => {
      clearErrors(tabName);
    };
  }, []);

  const validateForm = () => {
    let isFormValid = true;
    if (!getValues(getFieldName('assets')).length) {
      setError(getFieldName('assets'), getErrorsProperties('Assets field is required'));
      isFormValid = false;
    }
    return isFormValid;
  };

  const handleUpdate = () => {
    if (!validateForm()) return;
    setModalData({ ...MARK_INSPECTED_ASSETS, isOpened: true });
  };

  const agreeModal = () => {
    closeModal();
    const payload = {
      assets: getValues(getFieldName('assets')),
      inspectionStatus: inspectedStatus
    };
    setAssetsInspectionStatusAction(payload).then(() => {
      setValue(getFieldName('assets'), []);
      selectAssets([]);
    });
  };

  const selectAssets = (assets) => {
    getOtherTabNames(tabName).forEach((name) =>
      setValue(name, getUpdatedTabValues(name, assets, getValues()))
    );
    errors?.[tabName]?.['assets']?.message && clearErrors(getFieldName('assets'));
  };

  const locationIdsWatcher = watch(getFieldName('filterLocationIds'));

  return (
    <div className={styles.wrapper}>
      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
      <div className={styles.form}>
        <div className={styles.form__filter}>
          <FormLabel>Narrow Assets by Location</FormLabel>
          <FormAutoMultiSelect
            name={getFieldName('filterLocationIds')}
            menuItems={locations || []}
            options={{
              label: 'siteCode',
              disableByObjectTracker: true,
              disableLabel: true
            }}
          />
        </div>
        <div className={styles.form__assets}>
          <FormLabel>Enter Asset DR Codes; codes must be valid DR Codes*</FormLabel>
          <div className={styles.form__assets_input}>
            <FormMultipleAssetSelect
              withQr
              name={getFieldName('assets')}
              onSelectTriggered={selectAssets}
              queryOptions={{ locationIds: locationIdsWatcher }}
            />
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <MainButton
          text="update"
          type="primary"
          action={handleUpdate}
          isDisabled={isFormInvalid()}
        />
      </div>
    </div>
  );
}
