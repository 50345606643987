import { useSelector } from 'react-redux';
import { selectUnsavedFormData } from 'pages/commonSelectors';
import {
  selectCreateCategoriesData,
  selectCreateLocationsData,
  selectLooseItemFieldsData,
  selectSingleItemData,
  selectSelectedColumnsData,
  selectFilterData,
  selectFilterCriteriaData,
  selectSelectedRowsData
} from 'pages/LooseItems/LooseItemsList/selectors';

export function useLooseItemSelector() {
  // Summary
  const customColumns = useSelector(selectSelectedColumnsData());

  const filter = useSelector(selectFilterData());
  const filterCriteria = useSelector(selectFilterCriteriaData());

  const selectedRows = useSelector(selectSelectedRowsData());

  // Others
  const unsavedFormData = useSelector(selectUnsavedFormData());

  const currentLooseItem = useSelector(selectSingleItemData());

  const locationList = useSelector(selectCreateLocationsData());
  const categoryList = useSelector(selectCreateCategoriesData());
  const looseItemFields = useSelector(selectLooseItemFieldsData());

  return {
    filter,
    filterCriteria,
    selectedRows,
    customColumns,
    unsavedFormData,
    locationList,
    categoryList,
    looseItemFields,
    currentLooseItem
  };
}
