import React, { useEffect, useRef, useState } from 'react';
import styles from './Wrapper.module.scss';

import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import BreadcrumbsNav from 'components/BreadcrumbsNav/BreadcrumbsNav';
import MainButton from 'components/StyledComponents/MainButton';
import BackButton from 'components/BackButton/BackButton';

import { useHistory, useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useCommonActions, useCommonSelector } from 'hooks/Common';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useAssembledBOMActions, useAssembledBOMSelector } from 'hooks/AssembledBOM';

import {
  CREATE_ASSEMBLE,
  CREATE_ASSEMBLE_TYPE,
  EXIT_WITHOUT_SAVING,
  UPDATE_ASSEMBLE,
  UPDATE_ASSEMBLE_TYPE,
  WITHOUT_SAVING_BY_NAV_TYPE,
  WITHOUT_SAVING_TYPE
} from 'constants/dialogPopupsData';
import { ASSEMBLED_BOM_DETAILED_PATH, ASSEMBLED_BOM_LIST_PATH } from 'constants/routeConstants';

import { focusErrorField, getErrorsProperties } from 'helpers/ErrorValidator';
import { getBackendErrors, getSum, pluck, removeDuplicates } from 'helpers/AppHelpers';

import {
  getValidationFields,
  REQUIRED_EXCEEDS_SELECTED_ERROR,
  transformPayload,
  getLooseItemSectionErrorName,
  SUM_DOES_NOT_MATCH_REQUIRED_ERROR
} from '../helpers';

export function Wrapper({ children }) {
  const { id } = useParams();
  const isMobile = useMobileViewport();
  const history = useHistory();

  const breadcrumbs = useRef([{ name: 'Create Assembly' }]);

  const { unsavedFormData } = useCommonSelector();
  const { setUnsavedFormDataAction } = useCommonActions();
  const { currentAssembly } = useAssembledBOMSelector();
  const { createAssembleAction, updateAssembleAction } = useAssembledBOMActions();

  const { formState, getValues, setError } = useFormContext();
  const { isDirty, errors } = formState;

  const isFormInvalid =
    !getValues('quantityOptions').length || !!Object.values(errors).filter(Boolean).length;

  const [modalData, setModalData] = useState({});

  useEffect(() => {
    if (isDirty && !unsavedFormData) {
      setUnsavedFormDataAction(true);
    }
    return () => {
      setUnsavedFormDataAction(false);
    };
  }, [isDirty]);

  useEffect(() => {
    if (id && currentAssembly?.id) {
      breadcrumbs.current = [
        {
          path: `${ASSEMBLED_BOM_DETAILED_PATH}/${id}`,
          name: currentAssembly.name
        },
        { name: 'Edit Assembly' }
      ];
    }
  }, [currentAssembly]);

  const closeModal = () => setModalData({});
  const getBack = () =>
    history.push(id ? `${ASSEMBLED_BOM_DETAILED_PATH}/${id}` : ASSEMBLED_BOM_LIST_PATH);

  const handleBackClick = () => {
    if (isDirty) {
      setModalData(EXIT_WITHOUT_SAVING);
    } else {
      getBack();
    }
  };

  const handleResponse = (err) => {
    if (!err?.errors) return;

    getBackendErrors(err).forEach(({ name, type, message }) => setError(name, { type, message }));
  };

  const agreeModal = () => {
    switch (modalData.type) {
      case CREATE_ASSEMBLE_TYPE:
        closeModal();
        createAssembleAction(transformPayload(getValues())).then(handleResponse);
        break;
      case UPDATE_ASSEMBLE_TYPE:
        closeModal();
        updateAssembleAction(getValues()).then(handleResponse);
        break;
      case WITHOUT_SAVING_TYPE:
        getBack();
        break;
      case WITHOUT_SAVING_BY_NAV_TYPE:
        history.push(modalData.selectedRouteUrl);
        break;
      default:
        break;
    }
  };

  const validateForm = () => {
    const validateField = ({ fieldName, errorMessage, isInvalid, selector }) => {
      if (isInvalid) {
        setError(fieldName, getErrorsProperties(errorMessage));
        return selector;
      }
      return null;
    };

    const isGeneralFormValid = () => {
      const firstInvalidField = getValidationFields(getValues()).map(validateField).find(Boolean);

      if (firstInvalidField) {
        focusErrorField(firstInvalidField);
        return false;
      }

      return true;
    };

    const isAssetsTableValid = () => {
      let isTableValid = true;

      if (id) return isTableValid;

      const assetPrefixes = getValues('assetPrefixes');

      if (!assetPrefixes?.length) return isTableValid;

      assetPrefixes.forEach((row, index) => {
        if (!row?.selectedAsset?.id) {
          setError(
            `assetPrefixes[${index}].selectedAsset`,
            getErrorsProperties('Not enough assets')
          );
          isTableValid = false;
        }
      });

      return isTableValid;
    };

    const isLooseItemsTableValid = () => {
      let isTableValid = true;

      if (id) return isTableValid;

      const looseItems = getValues('looseItems');

      if (!looseItems?.length) return isTableValid;

      const looseItemIds = removeDuplicates(looseItems.map(({ looseItem }) => looseItem.id));

      looseItemIds.forEach((id) => {
        const looseItemLocations = looseItems.filter(({ looseItem }) => looseItem.id === id);
        const required = looseItemLocations[0].requiredQuantity;
        const selectedSum = getSum(pluck(looseItemLocations, 'selectedQuantity'));

        if (required > selectedSum) {
          setError(
            getLooseItemSectionErrorName(id),
            getErrorsProperties(REQUIRED_EXCEEDS_SELECTED_ERROR)
          );
          isTableValid = false;
        }
        if (selectedSum > required) {
          setError(
            getLooseItemSectionErrorName(id),
            getErrorsProperties(SUM_DOES_NOT_MATCH_REQUIRED_ERROR)
          );
          isTableValid = false;
        }
      });

      return isTableValid;
    };

    const generalValid = isGeneralFormValid();
    const looseItemsValid = isLooseItemsTableValid();
    const assetsValid = isAssetsTableValid();

    return generalValid && looseItemsValid && assetsValid;
  };

  const handleCreateClick = () => {
    if (!validateForm()) return;

    setModalData({ ...(id ? UPDATE_ASSEMBLE : CREATE_ASSEMBLE), isOpened: true });
  };

  return (
    <section className={styles.wrapper}>
      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={closeModal} />
      <div className={styles.header}>
        {!isMobile && (
          <BreadcrumbsNav
            itemsArray={breadcrumbs.current}
            setDialogModalData={setModalData}
            formIsChanged={isDirty}
          />
        )}

        <div className={styles.header__block}>
          <h1>{id ? 'Edit' : 'Create'} Assembly</h1>
          {isMobile && <BackButton onCancel={handleBackClick} />}
        </div>
      </div>
      {children}
      <div className={styles.footer}>
        <MainButton text="cancel" type="secondary" action={handleBackClick} />
        <MainButton
          text="Save"
          type="primary"
          action={handleCreateClick}
          isDisabled={isFormInvalid}
        />
      </div>
    </section>
  );
}
