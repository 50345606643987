import { useDispatch } from 'react-redux';
import {
  clearState,
  createTeamMember,
  deleteTeamMember,
  getCreationForm,
  getSingleTeamMember,
  restoreTeamMember,
  updateTeamMember,
  deleteTeamMemberParameter,
  updateTeamMemberParameter,
  getPermissionToCancelLastOperation,
  cancelLastOperation,
  getTeamMembers,
  getFilterCriteria,
  setFilter,
  getSummaryCsvResource
} from 'actions/teamMemberActions';
import { getUserConfig, setUnsavedFormData } from 'actions/commonActions';

export function useTeamMemberActions() {
  const dispatch = useDispatch();

  const getTeamMembersAction = (q) => dispatch(getTeamMembers(q));

  const getFilterCriteriaAction = (q) => dispatch(getFilterCriteria(q));
  const setFilterAction = (data) => dispatch(setFilter(data));

  const getTeamMemberAction = (id) => dispatch(getSingleTeamMember(id));
  const deleteTeamMemberAction = (id, f) => dispatch(deleteTeamMember(id, f));
  const restoreTeamMemberAction = (id, f) => dispatch(restoreTeamMember(id, f));
  const getUserConfigAction = () => dispatch(getUserConfig());

  const getCreationFormAction = (q) => dispatch(getCreationForm(q));
  const createTeamMemberAction = (data) => dispatch(createTeamMember(data));
  const updateTeamMemberAction = (data) => dispatch(updateTeamMember(data));

  const deleteParameterAction = (data, prefix) => dispatch(deleteTeamMemberParameter(data, prefix));
  const updateParameterAction = (data, prefix) => dispatch(updateTeamMemberParameter(data, prefix));

  const getPermissionToCancelLastOperationAction = () =>
    dispatch(getPermissionToCancelLastOperation());
  const cancelLastOperationAction = () => dispatch(cancelLastOperation());

  const setUnsavedFormDataAction = (flag) => dispatch(setUnsavedFormData(flag));

  const getSummaryCsvResourceAction = (q) => dispatch(getSummaryCsvResource(q));

  const clearStateAction = () => dispatch(clearState());

  return {
    getTeamMembersAction,
    getFilterCriteriaAction,
    setFilterAction,
    getTeamMemberAction,
    deleteTeamMemberAction,
    restoreTeamMemberAction,
    getUserConfigAction,
    clearStateAction,
    setUnsavedFormDataAction,
    getCreationFormAction,
    createTeamMemberAction,
    deleteParameterAction,
    updateParameterAction,
    getPermissionToCancelLastOperationAction,
    cancelLastOperationAction,
    updateTeamMemberAction,
    getSummaryCsvResourceAction
  };
}
