import { useContext } from 'react';
import { ContainerRefContext } from 'containers/AuthorizedView/AuthorizedView';

export function useContainerRef() {
  const containerRefApi = useContext(ContainerRefContext);

  return {
    getRef: containerRefApi?.current?.getRef,
    getScrollTop: containerRefApi?.current?.getScrollTop
  };
}
