import React, { useEffect, useState } from 'react';
import styles from './AddAssetPopup.module.scss';
import PropTypes from 'prop-types';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import DialogHeader from 'components/DialogComponents/DialogHeader';
import { MainButton } from 'components/StyledComponents';
import {
  FormDayPicker,
  FormLabel,
  FormMultipleAssetSelect,
  FormSearchInput
} from 'components/FormComponents';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';
import FormServerAssetSearch from 'components/FormComponents/FormServerAssetSearch/FormServerAssetSearch';
import FormTextArea from 'components/FormComponents/FormTextArea/FormTextArea_v2';
import FormInputWithCreation from 'components/FormComponents/FormInputWithCreation/FormInputWithCreation';
import FormChipsContainer from 'components/FormComponents/FormChipsContainer/FormChipsContainer';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import MatchingLocation from 'components/ModalContents/MatchingLocation';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { getPicklists, checkLocation, addAssetsToPicklist } from 'actions/addAssetPopupsActions';

import {
  ADD_ASSETS_PICKLIST,
  ADD_ASSETS_PICKLIST_TYPE,
  PICKLIST_ASSETS_LOCATION_CHECK,
  PICKLIST_ASSETS_LOCATION_CHECK_TYPE
} from 'constants/dialogPopupsData';

import { allKeyboardLatSymbols, pluck } from 'helpers/AppHelpers';
import { getErrorsProperties } from 'helpers/ErrorValidator';

const SCREENS = Object.freeze({
  PICKLIST_DETAILS: 'Picklist Details',
  MAP: 'Map',
  ASSET_SUMMARY: 'Asset Summary'
});

/**
 * @typedef {Object} AddPicklistAssetPopupProps
 * @property {boolean} open
 * @property {() => void} setOpen
 * @property {'Picklist Details' | 'Map' | 'Asset Summary'} screen
 * @property {{id: number, name: string}} picklist
 * @property {Array} assets
 * @property {() => void} onSuccess
 */

/**
 * @param {AddPicklistAssetPopupProps} props
 */

function AddPicklistAssetPopup({ open, setOpen, screen, picklist, assets, onSuccess }) {
  const isMobile = useMobileViewport();
  const dispatch = useDispatch();

  const [modalData, setModalData] = useState({});
  const [options, setOptions] = useState({});

  const methods = useForm({
    defaultValues: {
      assets: [],
      customs: [],
      picklist: {},
      pickupDate: null,
      truck: {},
      isThirdPartyTruck: false,
      notes: ''
    },
    mode: 'onChange'
  });
  const { setValue, getValues, formState, setError, clearErrors, watch, reset } = methods;
  const { errors } = formState;
  const isFormInvalid = !!Object.values(errors).filter(Boolean).length;

  const { picklists } = options;

  useEffect(() => {
    if (screen !== SCREENS.PICKLIST_DETAILS) {
      dispatch(getPicklists()).then((res) => setOptions((prev) => ({ ...prev, picklists: res })));
    }
  }, [screen]);

  useEffect(() => {
    if (picklist?.id) {
      reset({ ...getValues(), picklist });
    }
  }, [picklist]);

  useEffect(() => {
    if (assets?.length) {
      reset({ ...getValues(), assets });
    }
  }, [assets]);

  const validateForm = () => {
    let isFormValid = true;

    if (!getValues('assets')?.length && !getValues('customs')?.length) {
      setError('assets', getErrorsProperties('At least one field is required'));
      setError('customs', getErrorsProperties('At least one field is required'));
      isFormValid = false;
    }

    if (!getValues('picklist')?.id) {
      setError('picklist', getErrorsProperties('Picklist field is required'));
      isFormValid = false;
    }

    return isFormValid;
  };

  const parsePayload = (data) => {
    const payload = {};

    payload.assets = [...data.assets, ...data.customs];
    payload.picklist = data.picklist;

    if (data.pickupDate) {
      payload.pickupDate = data.pickupDate;
    }
    if (data.truck?.id) {
      payload.truck = data.truck;
    }
    if (data.isThirdPartyTruck) {
      payload.isThirdPartyTruck = data.isThirdPartyTruck;
    }
    if (data.notes) {
      payload.notes = data.notes;
    }

    return payload;
  };

  const closePopup = () => setOpen(false);
  const handleAddClick = () => {
    if (!validateForm()) return;

    setModalData({
      ...ADD_ASSETS_PICKLIST,
      isOpened: true,
      title: `Do you want to add assets to the picklist ${getValues('picklist.name')}?`
    });
  };

  const saveAssetsInPicklist = (excludedAssetIds) => {
    let handledAssets = getValues('assets');

    if (excludedAssetIds?.length) {
      handledAssets = handledAssets.filter(({ id }) => !excludedAssetIds.includes(id));
    }

    setModalData({});

    const parsedValues = parsePayload({ ...getValues(), assets: handledAssets });

    if (!parsedValues?.assets?.length) return;

    dispatch(addAssetsToPicklist(parsedValues)).then((res) => {
      if (res.status === 200) {
        onSuccess && onSuccess();
        closePopup();
      }
    });
  };

  const checkPicklistLocation = () => {
    const query = {
      picklistId: getValues('picklist.id'),
      assetIds: pluck(getValues('assets'), 'id')
    };

    dispatch(checkLocation(query)).then((res) => {
      const { assets, isPicklistWithSublocation } = res;

      if (!assets?.length) {
        saveAssetsInPicklist();
        return;
      }

      setModalData({
        ...PICKLIST_ASSETS_LOCATION_CHECK,
        excludedAssetIds: pluck(assets, 'id'),
        content: (
          <MatchingLocation
            items={assets}
            options={{
              itemType: 'asset',
              documentType: 'picklist',
              endpointType: isPicklistWithSublocation ? 'sublocation' : 'location'
            }}
          />
        )
      });
    });
  };

  const dismissModal = () => {
    if (modalData.type === PICKLIST_ASSETS_LOCATION_CHECK_TYPE) {
      saveAssetsInPicklist(modalData.excludedAssetIds);
    } else {
      setModalData({});
    }
  };

  const agreeModal = () => {
    if (modalData.type === ADD_ASSETS_PICKLIST_TYPE) {
      checkPicklistLocation();
    } else {
      saveAssetsInPicklist();
    }
  };

  const selectOption = (name, value) => setValue(name, value);
  const selectCheckbox = () => setValue('truck', {});

  const validateScannedAsset = ({ rental }) => rental?.rentalStatus?.name === 'Available';
  const getSnackbarScanErrorMessage = ({ drCode }) =>
    `Asset ${drCode} is not available for adding to picklist`;

  const checkError = () =>
    (errors?.assets || errors?.customs) && clearErrors(['assets', 'customs']);

  const thirdPartyTruckWatcher = watch('isThirdPartyTruck');

  return (
    <DialogWrapper open={open} onClose={closePopup}>
      <div className={styles.popup}>
        <DialogHeader
          title={isMobile ? 'Add assets' : 'Add assets to picklist'}
          onClose={closePopup}
        />
        <div className={styles.content}>
          <FormProvider {...methods}>
            <div className={styles.form}>
              <div className={styles.form__assets}>
                <FormLabel required>Enter Asset DR Codes; codes must be valid DR Codes</FormLabel>
                <FormMultipleAssetSelect
                  withQr
                  disableErrorMessage
                  name="assets"
                  queryOptions={{
                    rentalIds: [1],
                    isPicklist: true,
                    picklistId: picklist?.id || null
                  }}
                  validateScannedAsset={validateScannedAsset}
                  getSnackbarScanErrorMessage={getSnackbarScanErrorMessage}
                  onSelectTriggered={checkError}
                />
              </div>

              {screen === SCREENS.PICKLIST_DETAILS && (
                <>
                  <div className={styles.form__customs}>
                    <FormLabel required>Enter Non deployed resources owned Codes</FormLabel>
                    <FormInputWithCreation
                      name="customs"
                      itemLabel="drCode"
                      disableErrorMessage
                      hintText="After entering code press “Enter” button"
                      modifyText={(str) => str.replace(allKeyboardLatSymbols, '').substring(0, 200)}
                      transformItem={(str) => ({ drCode: str.trim() })}
                      onKeyDownTriggered={checkError}
                    />
                  </div>

                  <FormChipsContainer
                    fieldName="customs"
                    keyParam="drCode"
                    label="drCode"
                    deleteParameter="drCode"
                  />
                </>
              )}

              {screen !== SCREENS.PICKLIST_DETAILS && (
                <div className={styles.form__row}>
                  <FormLabel required>Picklist</FormLabel>
                  <FormSearchInput
                    name="picklist"
                    options={picklists || []}
                    onSelect={selectOption}
                  />
                </div>
              )}

              <div className={styles.form__row}>
                <FormLabel>Pickup Date</FormLabel>
                <FormDayPicker name="pickupDate" />
              </div>

              <div className={styles.form__row}>
                <FormLabel>Truck or trailer</FormLabel>
                <FormServerAssetSearch
                  name="truck"
                  max={100}
                  onSelect={selectOption}
                  getLabel={(option) => option?.drCode || ''}
                  getQuery={(searchQuery) => ({
                    pagination: { limit: 39, page: 1 },
                    filters: { isDeleted: false, equipmentIds: [9, 10], searchQuery }
                  })}
                  isDisabled={!!thirdPartyTruckWatcher}
                />
              </div>
              <div className={styles.form__checkbox}>
                <FormLabel>Third party truck?</FormLabel>
                <FormCheckbox name="isThirdPartyTruck" onSelectTriggered={selectCheckbox} />
              </div>
              <div className={styles.form__notes}>
                <FormLabel>Notes</FormLabel>
                <FormTextArea name="notes" options={{ max: 1000 }} />
              </div>
            </div>
          </FormProvider>
        </div>
        <div className={styles.footer}>
          <MainButton text="Cancel" action={closePopup} type="secondary" />
          <MainButton
            text="Add"
            action={handleAddClick}
            type="primary"
            isDisabled={isFormInvalid}
          />
          <div className={styles.footer__error}>
            {(errors?.assets || errors?.customs) && (
              <div className={styles.footer__error_message}>
                {errors.assets.message || errors.customs.message}
              </div>
            )}
          </div>
        </div>
      </div>
      <ConfirmationPopup data={modalData} onAgree={agreeModal} onDismiss={dismissModal} />
    </DialogWrapper>
  );
}

AddPicklistAssetPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  screen: PropTypes.oneOf(Object.values(SCREENS)).isRequired,
  picklist: PropTypes.object,
  assets: PropTypes.array,
  onSuccess: PropTypes.func
};

export { AddPicklistAssetPopup };
