import React, { useState, useEffect, useRef, useCallback } from 'react';
import styles from './LooseItemsList.module.scss';

import {
  NoDataTableRow,
  Pagination,
  SearchField,
  SummaryHeaderWrapper,
  SummaryPageHeader,
  SummaryTableControls,
  SummaryWrapper,
  TableWrapper
} from 'components/SummaryComponents';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { FilterButton, FilterChips, FilterWrapper } from 'components/FilterComponentsV2';
import StyledLink from 'components/StyledLink';
import SortableBlock from 'components/SortableBlock/SortableBlock';
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox';
import EditabilityToggle from 'components/EditabilityToggle/EditabilityToggle';

import { Table, TableBody, TableHead } from '@material-ui/core';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';
import { useLooseItemActions, useLooseItemSelector } from 'hooks/LooseItems';
import { useCommonActions, useCommonSelector } from 'hooks/Common';
import useScrollManager from 'hooks/useScrollManager';

import {
  getChangedByApplyFilter,
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';
import { cloneObj, enqueueErrorSnackbar, pluck } from 'helpers/AppHelpers';

import { LOOSE_ITEMS_TABLE_PARAMETER } from 'constants/configTableConstants';
import {
  ASSET_DETAILED_PATH,
  LOCATION_DETAILED_PATH,
  LOOSE_ITEM_DETAILED_PATH
} from 'constants/routeConstants';
import {
  ERROR_BAA_EXCEED_QUANTITY,
  ERROR_BAA_MUST_NOT_EXCEED_QTY
} from 'constants/infoSnackbarData';

import {
  generateChips,
  generateInitialQuery,
  useSummaryParams,
  parseQuery,
  removeChip,
  generateSelectedRowObject
} from './helpers';
import FilterModal from './Filter';
import HeaderButtons from './HeaderButtons';
import LooseItemCounting from './Counting';
import AddColumnButton from './AddColumnButton';
import SelectionOverview from './SelectionOverview';

export function LooseItemsList() {
  const isMobile = useMobileViewport();
  const tableRef = useRef(null);

  const urlParams = useSummaryParams();

  const [tableState, setTableState] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [chips, setChips] = useState([]);
  const [counts, setCounts] = useState({});

  const { isConfigReceived, getTableLimit, isAllLimitEnabled } = useUserConfig();
  const { setLoadMoreFlagAction } = useCommonActions();
  const { loadMoreFlag } = useCommonSelector();

  const { filter, filterCriteria, selectedRows, customColumns } = useLooseItemSelector();
  const {
    getLooseItemsAction,
    getCountingAction,
    getFilterCriteriaAction,
    setFilterAction,
    selectRowAction,
    updateLooseItemTableRowAction,
    clearStateAction
  } = useLooseItemActions();

  const { filters, pagination, sortRules, responseReceived } = filter;

  const { saveScrollPosition } = useScrollManager({
    tableKey: LOOSE_ITEMS_TABLE_PARAMETER,
    isDataLoaded: responseReceived && counts?.total
  });

  const getLooseItems = useCallback(
    (query, isAutoload) => {
      setFilterAction(query);

      const payload = parseQuery(query);

      getLooseItemsAction(payload).then((tableData) => {
        !isAutoload && getCountingAction(payload).then((res) => setCounts(res));
        setLooseItems(tableData, query, isAutoload);
        !loadMoreFlag && setLoadMoreFlagAction(false);
      });
    },
    [setFilterAction, getLooseItemsAction]
  );

  const setLooseItems = useCallback(
    (data, query, isAutoload) => {
      setTableState((prevState) =>
        isAutoload ? prevState.concat(data?.items || []) : data?.items || []
      );

      const updatedFilter = {
        ...query,
        pagination: { limit: data.limit, page: data.pageNumber, totalPages: data.totalPages },
        responseReceived: true
      };
      setFilterAction(updatedFilter);
    },
    [setFilterAction]
  );

  useEffect(() => {
    if (!isConfigReceived) return;

    getFilterCriteriaAction().then((criteria) => {
      const q = {
        getTableLimit,
        isAllLimitEnabled,
        filter,
        urlParams
      };
      const resultFilter = generateInitialQuery(q);
      getLooseItems(resultFilter);

      const options = { ...criteria, assets: filters?.assetList || [] };
      setChips(generateChips(options, resultFilter.filters));
    });
  }, [isConfigReceived]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const findMatches = useCallback(
    (value) => getLooseItems(getChangedBySearchFilter(filter, value)),
    [filter, getLooseItems]
  );
  const sortTable = useCallback(
    (rowName) => getLooseItems(getChangedBySortFilter(filter, rowName)),
    [filter, getLooseItems]
  );
  const applyFilter = useCallback(
    (values) => {
      getLooseItems(getChangedByApplyFilter(filter, values));

      const options = { ...filterCriteria, assets: values?.assetList || [] };
      setChips(generateChips(options, values));
    },
    [filter, getLooseItems, filterCriteria]
  );
  const changeLimit = useCallback(
    () =>
      getLooseItems(getChangedByLimitFilter(filter, getTableLimit(LOOSE_ITEMS_TABLE_PARAMETER))),
    [filter, getTableLimit, getLooseItems]
  );
  const changePage = useCallback(
    (page, param) => getLooseItems(getChangedByPageFilter(filter, page), param === 'AUTOLOAD'),
    [filter, getLooseItems]
  );
  const deleteChip = useCallback(
    (chip) => applyFilter(removeChip(filters, chip, filterCriteria)),
    [applyFilter, filters, filterCriteria]
  );

  const shouldDisplayLooseItemCell = (index) =>
    !(index !== 0 && tableState[index]?.looseItem?.id === tableState[index - 1]?.looseItem?.id);
  const getLooseItemCellSpan = (id) =>
    tableState.filter(({ looseItem }) => looseItem.id === id).length;

  const openFilterModal = useCallback(() => setOpenFilter(true), [setOpenFilter]);

  const isCheckboxSelected = useCallback(
    (rowId) => !!selectedRows.find(({ id }) => id === rowId),
    [selectedRows]
  );
  const handleCheckboxClick = useCallback(
    (e, row) => {
      if (e.target.checked) {
        selectRowAction(selectedRows.concat([generateSelectedRowObject(row)]));
      } else {
        selectRowAction(selectedRows.filter(({ id }) => id !== row.locationLooseItemId));
      }
    },
    [selectedRows, selectRowAction]
  );

  const isHeadCheckboxSelected = useCallback(() => {
    const selectedIds = pluck(selectedRows, 'id');

    const rowsWithCounts = tableState.filter(
      ({ quantity, byAheadAccount }) => quantity || byAheadAccount
    );
    const rowIds = pluck(rowsWithCounts, 'locationLooseItemId');

    const hasUnselectedIds = rowIds.some((id) => !selectedIds.includes(id));
    return !hasUnselectedIds && rowsWithCounts.length > 0;
  }, [selectedRows, tableState]);
  const handleHeadCheckboxClick = useCallback(
    (e) => {
      const rowsWithCounts = tableState.filter(
        ({ quantity, byAheadAccount }) => quantity || byAheadAccount
      );
      const rowIdsWithCounts = pluck(rowsWithCounts, 'locationLooseItemId');

      if (e.target.checked) {
        const selectedRowIds = pluck(selectedRows, 'id');
        const rowsToAdd = rowsWithCounts
          .filter(({ locationLooseItemId }) => !selectedRowIds.includes(locationLooseItemId))
          .map(generateSelectedRowObject);
        selectRowAction([...selectedRows, ...rowsToAdd]);
      } else {
        const filteredRows = selectedRows.filter(({ id }) => !rowIdsWithCounts.includes(id));
        selectRowAction(filteredRows);
      }
    },
    [tableState, selectedRows]
  );

  const updateRow = (row, index) => {
    updateLooseItemTableRowAction(row).then((res) => {
      if (!res?.locationLooseItemId) return;

      if (pagination.limit !== 100) {
        getLooseItems(filter);
      } else {
        const clonedTableState = cloneObj(tableState);
        clonedTableState[index] = row;
        setTableState(clonedTableState);
        getCountingAction(filter);
      }

      const isRowSelected = selectedRows?.some(({ id }) => id === res.locationLooseItemId);
      if (isRowSelected) {
        const filteredRows = selectedRows.filter(({ id }) => id !== res.locationLooseItemId);
        selectRowAction(filteredRows);
      }
    });
  };

  const handleQuantityUpdate = useCallback(
    ({ value, index, onClose }) => {
      const row = tableState[index];
      const rowByAheadAccount = row.byAheadAccount || 0;

      if (value < rowByAheadAccount) {
        enqueueErrorSnackbar(ERROR_BAA_EXCEED_QUANTITY);
        return;
      }

      onClose();
      updateRow({ ...row, quantity: value }, index);
    },
    [tableState, updateRow]
  );
  const handleBAAUpdate = useCallback(
    ({ value, index, onClose }) => {
      const row = tableState[index];
      const rowQuantity = row.quantity || 0;

      if (value > rowQuantity) {
        enqueueErrorSnackbar(ERROR_BAA_MUST_NOT_EXCEED_QTY);
        return;
      }

      onClose();
      updateRow({ ...row, byAheadAccount: value }, index);
    },
    [tableState, updateRow]
  );

  return (
    <SummaryWrapper>
      <SummaryHeaderWrapper>
        <SummaryPageHeader title="Loose Items List">
          {!isMobile && <HeaderButtons />}
        </SummaryPageHeader>
        {isMobile && <HeaderButtons />}
      </SummaryHeaderWrapper>

      <LooseItemCounting counts={counts} />

      <SummaryTableControls>
        <FilterWrapper>
          <FilterButton onClick={openFilterModal} isActive={openFilter || !!chips?.length} />
          {!isMobile && <FilterChips chips={chips} onDelete={deleteChip} />}
        </FilterWrapper>
        <div className={styles.tableControls}>
          <SearchField filterValue={filters?.searchQuery} onSearch={findMatches} />
          {!isMobile && <AddColumnButton />}
        </div>
      </SummaryTableControls>

      {isMobile && <AddColumnButton />}

      <TableWrapper ref={tableRef} enableHorizontalScroll={!!customColumns?.length}>
        <Table stickyHeader={!isMobile && !customColumns?.length} className={styles.table}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>
                <SortableBlock
                  label="Loose Items"
                  parameter="name"
                  sortRules={sortRules}
                  onSortApply={sortTable}
                />
              </StyledTableCell>
              {!!customColumns?.length > 0 &&
                customColumns.map(({ id, name }) => (
                  <StyledTableCell key={id}>
                    <SortableBlock
                      label={name || ''}
                      parameter={`customFields.${name}`}
                      sortRules={sortRules}
                      onSortApply={sortTable}
                    />
                  </StyledTableCell>
                ))}
              <StyledTableCell>Location</StyledTableCell>
              <StyledTableCell>Sublocation</StyledTableCell>
              <StyledTableCell>Contained In</StyledTableCell>
              <StyledTableCell>QTY</StyledTableCell>
              <StyledTableCell>BAA</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>
                {!!tableState?.length && (
                  <CustomCheckbox
                    sendEvent
                    value={isHeadCheckboxSelected()}
                    onChange={handleHeadCheckboxClick}
                  />
                )}
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {tableState?.length ? (
              tableState.map((row, index) => (
                <StyledTableRow
                  key={row.locationLooseItemId}
                  className={styles.loose_item_table_row}>
                  {shouldDisplayLooseItemCell(index) && (
                    <StyledTableCell
                      rowSpan={getLooseItemCellSpan(row.looseItem.id)}
                      className={styles.looseItemCell}>
                      <StyledLink
                        bold
                        to={`${LOOSE_ITEM_DETAILED_PATH}/${row?.looseItem?.id}`}
                        onClick={saveScrollPosition}>
                        {row?.looseItem?.name || ''}
                      </StyledLink>
                    </StyledTableCell>
                  )}
                  {!!customColumns?.length > 0 &&
                    shouldDisplayLooseItemCell(index) &&
                    customColumns.map(({ id }) => (
                      <StyledTableCell
                        key={id}
                        rowSpan={getLooseItemCellSpan(row.looseItem.id)}
                        className={styles.customCell}>
                        {row?.customFields?.find((field) => field.id === id)?.value || ''}
                      </StyledTableCell>
                    ))}
                  <StyledTableCell className={styles.locationCell}>
                    <StyledLink
                      to={`${LOCATION_DETAILED_PATH}/${row?.location?.id}`}
                      isRedirectAvailable={!row.location?.deleted}
                      onClick={saveScrollPosition}>
                      {row?.location?.siteCode || ''}
                    </StyledLink>
                  </StyledTableCell>
                  <StyledTableCell className={styles.sublocationCell}>
                    {row.sublocation?.name || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.containedInAssetCell}>
                    <StyledLink
                      to={`${ASSET_DETAILED_PATH}/${row?.containedInAsset?.id}`}
                      onClick={saveScrollPosition}>
                      {row?.containedInAsset?.drCode || ''}
                    </StyledLink>
                  </StyledTableCell>
                  <StyledTableCell className={styles.qtyCell}>
                    <EditabilityToggle
                      name="quantity"
                      inputValue={row?.quantity || 0}
                      options={{ type: 'number', index, keyToDown: 'Enter' }}
                      onAccept={handleQuantityUpdate}
                      isDisabled={row?.status?.displayName !== 'Available'}
                    />
                  </StyledTableCell>
                  <StyledTableCell className={styles.baaCell}>
                    <EditabilityToggle
                      name="byAheadAccount"
                      inputValue={row?.byAheadAccount || 0}
                      options={{ type: 'number', index, keyToDown: 'Enter' }}
                      onAccept={handleBAAUpdate}
                      isDisabled={row?.status?.displayName !== 'Available'}
                    />
                  </StyledTableCell>
                  <StyledTableCell className={styles.statusCell}>
                    {row?.status?.displayName || ''}
                    {row?.status?.displayName === 'In Picklist' && ` (${row?.picklist?.name})`}
                    {row?.status?.displayName === 'In Leavelist' && ` (${row?.leavelist?.name})`}
                  </StyledTableCell>
                  <StyledTableCell className={styles.checkboxCell}>
                    {!!(row?.quantity || row?.byAheadAccount) && (
                      <CustomCheckbox
                        sendEvent
                        value={isCheckboxSelected(row.locationLooseItemId)}
                        onChange={(e) => handleCheckboxClick(e, row)}
                      />
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : responseReceived ? (
              <NoDataTableRow isTableFiltered={!!chips?.length || !!filters?.searchQuery} />
            ) : null}
          </TableBody>
        </Table>
      </TableWrapper>

      <Pagination
        withOptionAll
        rowsNumber={tableState?.length}
        currentPage={pagination?.page}
        totalPages={pagination?.totalPages}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        selectedLimit={pagination?.limit}
        tableName={LOOSE_ITEMS_TABLE_PARAMETER}
        tableRef={tableRef}
      />

      {openFilter && (
        <FilterModal open={openFilter} setOpen={setOpenFilter} onApply={applyFilter} />
      )}

      <SelectionOverview />
    </SummaryWrapper>
  );
}
