import React, { useCallback, useEffect, useRef, useState } from 'react';
import styles from './AssembledBOMList.module.scss';

import {
  NoDataTableRow,
  Pagination,
  SearchField,
  SummaryHeaderWrapper,
  SummaryPageHeader,
  SummaryTableControls,
  SummaryWrapper,
  TableWrapper,
  TableHeadComponent
} from 'components/SummaryComponents';
import { StyledTableCell, StyledTableRow } from 'components/StyledComponents';
import { StyledButton, DownloadCSVButton } from 'components/Buttons';
import { FilterButton, FilterChips, FilterWrapper } from 'components/FilterComponentsV2';
import StyledLink from 'components/StyledLink';

import { Table, TableBody } from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import { useMobileViewport } from 'hooks/useMobileViewport';
import { useUserConfig } from 'hooks/useUserConfig';
import { useAssembledBOMActions, useAssembledBOMSelector } from 'hooks/AssembledBOM';
import { useCommonActions, useCommonSelector } from 'hooks/Common';
import useScrollManager from 'hooks/useScrollManager';

import {
  getChangedByApplyFilter,
  getChangedByLimitFilter,
  getChangedByPageFilter,
  getChangedBySearchFilter,
  getChangedBySortFilter
} from 'helpers/SummaryPagesHelpers';
import { formatDate, dateTimeFormat, fileDownload } from 'helpers/AppHelpers';

import {
  ASSEMBLE_EDIT_PATH,
  ASSEMBLED_BOM_DETAILED_PATH,
  BOM_CONFIGURATION_DETAILED_PATH,
  LOCATION_DETAILED_PATH
} from 'constants/routeConstants';
import { ASSEMBLE_LIST_TABLE_PARAMETER } from 'constants/configTableConstants';

import {
  generateChips,
  generateInitialQuery,
  parseQuery,
  tableConfig,
  removeChip
} from './helpers';
import FilterModal from './Filter';

export function AssembledBOMList() {
  const isMobile = useMobileViewport();
  const history = useHistory();
  const tableRef = useRef(null);

  const [tableState, setTableState] = useState([]);
  const [openFilter, setOpenFilter] = useState(false);
  const [chips, setChips] = useState([]);

  const { isConfigReceived, getTableLimit, isAllLimitEnabled, isBasicRoleUser } = useUserConfig();
  const { setLoadMoreFlagAction } = useCommonActions();
  const { loadMoreFlag } = useCommonSelector();

  const { filter, filterCriteria } = useAssembledBOMSelector();
  const {
    getAssembledBOMListAction,
    getFilterCriteriaAction,
    setFilterAction,
    getCsvResourceAction,
    clearStateAction
  } = useAssembledBOMActions();

  const { filters, pagination, sortRules, responseReceived } = filter;

  const { saveScrollPosition, removeScrollPosition } = useScrollManager({
    tableKey: ASSEMBLE_LIST_TABLE_PARAMETER,
    isDataLoaded: responseReceived
  });

  const getAssembles = useCallback(
    (query, isAutoload) => {
      setFilterAction(query);

      getAssembledBOMListAction(parseQuery(query)).then((tableData) => {
        setAssembles(tableData, query, isAutoload);
        !loadMoreFlag && setLoadMoreFlagAction(false);
      });
    },
    [setFilterAction, getAssembledBOMListAction]
  );

  const setAssembles = useCallback(
    (data, query, isAutoload) => {
      setTableState((prevState) =>
        isAutoload ? prevState.concat(data?.items || []) : data?.items || []
      );

      const updatedFilter = {
        ...query,
        pagination: { limit: data.limit, page: data.pageNumber, totalPages: data.totalPages },
        responseReceived: true
      };
      setFilterAction(updatedFilter);
    },
    [setFilterAction]
  );

  useEffect(() => {
    if (!isConfigReceived) return;

    getFilterCriteriaAction().then((criteria) => {
      const q = { getTableLimit, isAllLimitEnabled, filter };
      const resultFilter = generateInitialQuery(q);
      getAssembles(resultFilter);
      setChips(generateChips(criteria, resultFilter.filters));
    });
  }, [isConfigReceived]);

  useEffect(() => {
    return () => {
      clearStateAction();
    };
  }, []);

  const findMatches = useCallback(
    (value) => getAssembles(getChangedBySearchFilter(filter, value)),
    [filter, getAssembles]
  );
  const sortTable = useCallback(
    (rowName) => getAssembles(getChangedBySortFilter(filter, rowName)),
    [filter, getAssembles]
  );
  const applyFilter = useCallback(
    (values) => {
      getAssembles(getChangedByApplyFilter(filter, values));
      setChips(generateChips(filterCriteria, values));
    },
    [filter, filterCriteria, getAssembles]
  );
  const changeLimit = useCallback(
    () =>
      getAssembles(getChangedByLimitFilter(filter, getTableLimit(ASSEMBLE_LIST_TABLE_PARAMETER))),
    [filter, getTableLimit, getAssembles]
  );
  const changePage = useCallback(
    (page, param) => getAssembles(getChangedByPageFilter(filter, page), param === 'AUTOLOAD'),
    [filter, getAssembles]
  );
  const deleteChip = useCallback(
    (chip) => applyFilter(removeChip(filters, chip)),
    [applyFilter, filters]
  );

  const openFilterModal = useCallback(() => setOpenFilter(true), [setOpenFilter]);

  const createAssemble = useCallback(() => {
    removeScrollPosition();
    history.push(ASSEMBLE_EDIT_PATH);
  }, [history]);

  const downloadCsv = () =>
    getCsvResourceAction(filter).then((file) => file?.link && fileDownload(file));

  const MainButton = () =>
    isBasicRoleUser ? null : (
      <StyledButton label="Create assembly" onClick={createAssemble} classes={styles.main_button} />
    );

  return (
    <SummaryWrapper>
      <SummaryHeaderWrapper>
        <SummaryPageHeader title="Assembled BOM list">
          {!isMobile && (
            <div className={styles.controls}>
              <DownloadCSVButton onClick={downloadCsv} />
              <MainButton />
            </div>
          )}
        </SummaryPageHeader>
        <SummaryTableControls>
          <FilterWrapper>
            <FilterButton onClick={openFilterModal} isActive={openFilter || !!chips?.length} />
            {!isMobile && <FilterChips chips={chips} onDelete={deleteChip} />}
          </FilterWrapper>
          <SearchField filterValue={filters?.searchQuery} onSearch={findMatches} />
        </SummaryTableControls>
      </SummaryHeaderWrapper>

      {isMobile && <MainButton />}

      <TableWrapper ref={tableRef}>
        <Table stickyHeader={!isMobile} className={styles.table}>
          <TableHeadComponent config={tableConfig} sortRules={sortRules} onSortApply={sortTable} />
          <TableBody>
            {tableState?.length ? (
              tableState.map((row) => (
                <StyledTableRow key={row.id} className={styles.assemble_table_row}>
                  <StyledTableCell className={styles.assembleCell}>
                    <StyledLink
                      bold
                      to={`${ASSEMBLED_BOM_DETAILED_PATH}/${row.id}`}
                      onClick={saveScrollPosition}>
                      {row.name || ''}
                    </StyledLink>
                  </StyledTableCell>
                  <StyledTableCell className={styles.configurationCell}>
                    <StyledLink
                      to={`${BOM_CONFIGURATION_DETAILED_PATH}/${row.looseItemConfiguration.id}`}
                      onClick={saveScrollPosition}>
                      {row?.looseItemConfiguration?.name || ''}
                    </StyledLink>
                  </StyledTableCell>
                  <StyledTableCell className={styles.locationCell}>
                    <StyledLink
                      to={`${LOCATION_DETAILED_PATH}/${row.location?.id}`}
                      isRedirectAvailable={!row.location?.deleted}
                      onClick={saveScrollPosition}>
                      {row?.location?.siteCode || ''}
                    </StyledLink>
                  </StyledTableCell>
                  <StyledTableCell className={styles.statusCell}>
                    {row.status?.displayName || ''}
                  </StyledTableCell>
                  <StyledTableCell className={styles.createdAtCell}>
                    {formatDate(row?.createdAtUtc, dateTimeFormat)}
                  </StyledTableCell>
                  <StyledTableCell className={styles.createdByCell}>
                    {row?.createdByUser?.firstName + ' ' + row?.createdByUser?.lastName}
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : responseReceived ? (
              <NoDataTableRow isTableFiltered={!!chips?.length || !!filters?.searchQuery} />
            ) : null}
          </TableBody>
        </Table>
      </TableWrapper>

      <Pagination
        withOptionAll
        rowsNumber={tableState?.length}
        currentPage={pagination?.page}
        totalPages={pagination?.totalPages}
        onChangePage={changePage}
        onChangeLimit={changeLimit}
        selectedLimit={pagination?.limit}
        tableName={ASSEMBLE_LIST_TABLE_PARAMETER}
        tableRef={tableRef}
      />

      {openFilter && (
        <FilterModal open={openFilter} setOpen={setOpenFilter} onApply={applyFilter} />
      )}
    </SummaryWrapper>
  );
}
