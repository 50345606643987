import {
  SET_CURRENT_CATEGORY,
  SET_ACTIVE_CATEGORY,
  SET_CATEGORIES_SEARCH_VALUE,
  CLEAR_CATEGORIES_STATE
} from 'constants/reduceConstants';

const initialState = {
  currentCategory: {},
  activeCategory: {},
  searchValue: ''
};

const categoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_CATEGORY:
      return { ...state, currentCategory: payload };
    case SET_ACTIVE_CATEGORY:
      return { ...state, activeCategory: { ...payload } };
    case SET_CATEGORIES_SEARCH_VALUE:
      return { ...state, searchValue: payload };
    case CLEAR_CATEGORIES_STATE:
      return { ...initialState, searchValue: state.searchValue };
    default:
      return state;
  }
};

export default categoryReducer;
