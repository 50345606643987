import React, { useEffect, useState } from 'react';
import styles from './Actions.module.scss';

import AddColumnModal from 'components/AddColumnModal';
import { BlueButton, StyledButton } from 'components/Buttons';
import GenerateQRCodesPopup from 'components/QR/GenerateQRCodesPopup';
import { AddPicklistAssetPopup } from 'components/Popups';

import { useDispatch, useSelector } from 'react-redux';
import { selectAssetsFilterData } from '../../selectors';
import { getAssetsTableFields, getAssetsForQrPopup } from 'actions/assetActions';

import { useMobileViewport } from 'hooks/useMobileViewport';
import { useAssetSelector } from 'hooks/Asset';

import QRIcon from 'assets/images/qr.svg';
import AddIcon from '@material-ui/icons/Add';

export function Actions({ isTableFiltered, selectedAssets, onRefresh, onAddColumn, children }) {
  const isMobile = useMobileViewport();

  const { filter } = useAssetSelector();

  const dispatch = useDispatch();

  const [openColumnsModal, setOpenColumnsModal] = useState(false);
  const [fields, setFields] = useState([]);
  const [openQRModal, setOpenQRModal] = useState(false);
  const [assets, setAssets] = useState([]);
  const [openAssetsPopup, setOpenAssetsPopup] = useState(false);

  const { columns = [] } = useSelector(selectAssetsFilterData());

  useEffect(() => {
    if (openColumnsModal && !fields?.length) {
      dispatch(getAssetsTableFields({ filters: { isShowOnAssetSummary: true } })).then((res) =>
        setFields(res)
      );
    }
  }, [openColumnsModal]);

  useEffect(() => {
    if (openQRModal) return;
    if (!assets.length) return;
    setAssets([]);
  }, [openQRModal]);

  const addColumn = () => setOpenColumnsModal(true);

  const applyClick = (selected) => onAddColumn(selected);

  const shouldDisplaySeparator = (currentOption, options) => {
    const currentOptionIndex = options.findIndex(({ id }) => id === currentOption.id);
    const nextOption = options[currentOptionIndex + 1];
    return currentOption?.id < 0 && nextOption?.id > 0;
  };

  const generateQR = async () => {
    if (isTableFiltered) {
      const res = await dispatch(
        getAssetsForQrPopup({ ...filter, filters: { ...filter.filters, isQrCode: true } })
      );
      setAssets(res?.items || []);
      setOpenQRModal(true);
    } else {
      setOpenQRModal(true);
    }
  };

  const addAssetsToPicklist = () => setOpenAssetsPopup(true);

  return (
    <div className={styles.controls}>
      {openAssetsPopup && (
        <AddPicklistAssetPopup
          open={openAssetsPopup}
          setOpen={setOpenAssetsPopup}
          screen="Asset Summary"
          assets={selectedAssets || []}
          onSuccess={onRefresh}
        />
      )}
      {openColumnsModal && !!fields?.length && (
        <AddColumnModal
          open={openColumnsModal}
          setOpen={setOpenColumnsModal}
          items={fields}
          onApply={applyClick}
          selected={columns}
          label="displayName"
          separatorCondition={shouldDisplaySeparator}
        />
      )}
      {openQRModal && !isMobile && (
        <GenerateQRCodesPopup
          open={openQRModal}
          setOpen={setOpenQRModal}
          title={'Generate QR Codes' + ' ' + (isTableFiltered ? 'for filtered assets' : '')}
          prefilledAssets={assets}
        />
      )}
      {!!selectedAssets?.length && (
        <StyledButton classes={styles.addAssetsBtn} onClick={addAssetsToPicklist}>
          Add to picklist
        </StyledButton>
      )}
      {children}
      <BlueButton label="Add Column" className={styles.btn} onClick={addColumn}>
        <AddIcon />
      </BlueButton>
      {!isMobile && (
        <BlueButton label="QR Generate" className={styles.btn} onClick={generateQR}>
          <img src={QRIcon} alt="" />
        </BlueButton>
      )}
    </div>
  );
}
