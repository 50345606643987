import api from '../api';
import store from 'store';
import {
  SET_USER_CONFIG,
  SET_UNSAVED_FORM_DATA,
  SET_TOP_POSITION,
  SET_LOAD_MORE_FLAG,
  SET_SESSION_ID
} from 'constants/reduceConstants';
import { ERROR_FIND_ASSET } from '../constants/infoSnackbarData';
import { enqueueErrorSnackbar } from 'helpers/AppHelpers';
import { setUser } from '@sentry/react';

export const getUserConfig = () => (dispatch) => {
  api.common
    .getUserConfigApi()
    .then((res) => {
      dispatch({ type: SET_USER_CONFIG, payload: res });
      setUser({
        id: res?.userId || 'undefined',
        initials: res?.firstName?.[0] + '' + res?.lastName?.[0],
        roleId: res?.userRole?.id
      });
    })
    .catch((err) => console.log(err));
};

export const updateUserConfig = (data) => (dispatch) => {
  return api.common.updateUserConfigApi(data).then(
    (res) => {
      dispatch({ type: SET_USER_CONFIG, payload: res });
      return res;
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const getResourceViaBytes = (str) => () => {
  return api.common.getResourceViaBytesApi({ signatureBase64: str }).then(
    (res) => res,
    (err) => console.log(err)
  );
};

export const setUnsavedFormData = (data) => (dispatch) => {
  dispatch({ type: SET_UNSAVED_FORM_DATA, payload: data });
};

export const setTopPosition = (data) => (dispatch) => {
  dispatch({ type: SET_TOP_POSITION, payload: data });
};

export const setSessionId = (payload) => (dispatch) => dispatch({ type: SET_SESSION_ID, payload });

export const checkElemPosition = (elem) => {
  const currentHeight =
    elem.current.scrollHeight -
    Math.abs(elem.current.scrollTop) -
    Math.abs(elem.current.clientHeight);
  if (currentHeight !== 0 && currentHeight < 200) {
    store.dispatch({ type: SET_LOAD_MORE_FLAG, payload: true });
  }
};

export const setLoadMoreFlag = (payload) => (dispatch) => {
  dispatch({ type: SET_LOAD_MORE_FLAG, payload });
};

export const searchAssets = (query) => () => {
  return api.common
    .searchAssetsApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const searchUsers = (query) => () => {
  return api.common
    .searchUsersApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const updateImageResource = (data) => () => {
  return api.common
    .updateImageResourceApi(data)
    .then((res) => res)
    .catch((err) => console.log(err));
};

export const scanAsset = (id) => () => {
  return api.common
    .scanAssetApi(id)
    .then((res) => res)
    .catch(() => {
      enqueueErrorSnackbar(ERROR_FIND_ASSET);
    });
};
