import {
  SET_CURRENT_PREFIX,
  SET_PREFIX_CREATION_FORM,
  CLEAR_PREFIX_STATE
} from 'constants/reduceConstants';

const initialState = {
  currentPrefix: {},
  creationForm: {}
};

const prefixReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_PREFIX:
      return { ...state, currentPrefix: payload };
    case SET_PREFIX_CREATION_FORM:
      return { ...state, creationForm: payload };
    case CLEAR_PREFIX_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default prefixReducer;
