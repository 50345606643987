import React, { useEffect } from 'react';
import styles from './Filter.module.scss';

import DialogWrapper from 'components/DialogComponents/DialogWrapper';
import { FormDayPicker, FormLabel, FormRow } from 'components/FormComponents';
import { FilterActions, FilterHeader } from 'components/FilterComponentsV2';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import FormMultiSelect from 'components/FormComponents/FormMultiSelect/FormMultiSelect';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';
import InfoTooltip from 'components/InfoTooltip/InfoTooltip';

import { FormProvider, useForm } from 'react-hook-form';
import { useHistoryActions, useHistorySelector } from 'hooks/TeamMemberHistory';
import { useMobileViewport } from 'hooks/useMobileViewport';

import { enrichNameToId, isEqual } from 'helpers/AppHelpers';

import { defaultFilter, DEPARTMENT_TOOLTIP_TEXT } from '../helpers';

export function Filter({ open, setOpen, onApply }) {
  const isMobile = useMobileViewport();

  const methods = useForm({
    defaultValues: { ...defaultFilter },
    mode: 'onChange'
  });
  const { getValues, reset, setValue, watch } = methods;

  const { filter, filterCriteria } = useHistorySelector();
  const { getFilterCriteriaAction } = useHistoryActions();

  const {
    teamMemberStatuses,
    teamMemberDepartments,
    teamMembers,
    teamMemberCapacities,
    projects,
    timeOnProjects
  } = filterCriteria;

  useEffect(() => {
    if (open) {
      reset(filter.filters);
    }
  }, [open]);

  const closePopup = () => setOpen(false);

  const handleClosePopup = () => {
    closePopup();

    // need to check department field when popup closes
    // to avoid unnecessary api calls
    const departmentIds = filter.filters?.teamMemberDepartmentIds || [];
    const currentDepartmentIds = getValues('teamMemberDepartmentIds');
    if (isEqual(departmentIds, currentDepartmentIds)) return;

    getFilterCriteriaAction({ departmentIds });
  };

  const applyFilter = () => {
    onApply(getValues());
    closePopup();
  };

  const clearFilter = () => {
    const defaultState = {
      ...defaultFilter.filters,
      searchQuery: getValues('searchQuery')
    };
    reset(defaultState);

    // to avoid unnecessary api calls
    // need to refresh filter criteria data if department field was changed
    const isDepartmentFieldFilled = !!getValues('teamMemberDepartmentIds')?.length;
    isDepartmentFieldFilled && getFilterCriteriaAction();
  };

  const handleDepartmentSelect = (_, value = []) => {
    getFilterCriteriaAction({ departmentIds: value }).then(() => {
      const resetState = {
        ...getValues(),
        teamMemberDepartmentIds: value,
        teamMemberIds: [],
        teamMemberCapacityIds: []
      };
      reset(resetState);
    });
  };

  const handleMultiSelect = (name, value) => setValue(name, value);

  const fromDayInWatcher = watch('fromDayIn');
  const toDayInWatcher = watch('toDayIn');
  const fromDayOutWatcher = watch('fromDayOut');
  const toDayOutWatcher = watch('toDayOut');

  return (
    <DialogWrapper open={open} onClose={handleClosePopup}>
      <div className={styles.dialog}>
        {isMobile && <FilterHeader title="Filters" onBack={handleClosePopup} />}
        <FormProvider {...methods}>
          <div className={styles.form}>
            <div className={styles.form__block}>
              <FormRow>
                <div className={styles.form__label}>
                  <FormLabel>Department</FormLabel>
                  <InfoTooltip text={DEPARTMENT_TOOLTIP_TEXT} />
                </div>
                <FormAutoMultiSelect
                  name="teamMemberDepartmentIds"
                  menuItems={teamMemberDepartments || []}
                  options={{
                    label: 'name',
                    disableByObjectTracker: true,
                    disableLabel: true,
                    extraAction: handleDepartmentSelect
                  }}
                />
              </FormRow>
              <FormRow>
                <FormLabel>Team member</FormLabel>
                <FormAutoMultiSelect
                  name="teamMemberIds"
                  menuItems={teamMembers || []}
                  options={{
                    labelType: 'teamMember',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </FormRow>
              <FormRow>
                <FormLabel>Capacity</FormLabel>
                <FormAutoMultiSelect
                  name="teamMemberCapacityIds"
                  menuItems={teamMemberCapacities || []}
                  options={{ label: 'name', disableByObjectTracker: true, disableLabel: true }}
                />
              </FormRow>
              <FormRow>
                <FormLabel>Employment</FormLabel>
                <FormAutoMultiSelect
                  name="teamMemberStatusIds"
                  menuItems={teamMemberStatuses || []}
                  options={{ label: 'name', disableByObjectTracker: true, disableLabel: true }}
                />
              </FormRow>
            </div>
            <div className={styles.form__block}>
              <FormRow>
                <FormLabel>Project</FormLabel>
                <FormAutoMultiSelect
                  name="projectIds"
                  menuItems={projects || []}
                  options={{
                    labelType: 'project',
                    disableByObjectTracker: true,
                    disableLabel: true
                  }}
                />
              </FormRow>
              <FormRow>
                <FormLabel>Time on Project</FormLabel>
                <FormMultiSelect
                  trackById
                  name="timeOnProjectNames"
                  options={enrichNameToId(timeOnProjects) || []}
                  onSelect={handleMultiSelect}
                />
              </FormRow>
              <FormRow>
                <FormLabel>Date In:</FormLabel>
                <div className={styles.form__dateRow}>
                  <div className={styles.form__date}>
                    <label>from</label>
                    <FormDayPicker
                      name="fromDayIn"
                      max={toDayInWatcher}
                      className={styles.form__picker}
                    />
                  </div>
                  <div className={styles.form__date}>
                    <label>to</label>
                    <FormDayPicker
                      name="toDayIn"
                      min={fromDayInWatcher}
                      className={styles.form__picker}
                    />
                  </div>
                </div>
              </FormRow>
              <FormRow>
                <FormLabel>Date Out:</FormLabel>
                <div className={styles.form__dateRow}>
                  <div className={styles.form__date}>
                    <label>from</label>
                    <FormDayPicker
                      name="fromDayOut"
                      max={toDayOutWatcher}
                      className={styles.form__picker}
                    />
                  </div>
                  <div className={styles.form__date}>
                    <label>to</label>
                    <FormDayPicker
                      name="toDayOut"
                      min={fromDayOutWatcher}
                      className={styles.form__picker}
                    />
                  </div>
                </div>
              </FormRow>
              <FormRow type="checkbox">
                <FormLabel>See all history</FormLabel>
                <FormCheckbox name="showAllHistory" />
              </FormRow>
            </div>
          </div>
        </FormProvider>
        <FilterActions onClear={clearFilter} onCancel={handleClosePopup} onApply={applyFilter} />
      </div>
    </DialogWrapper>
  );
}
