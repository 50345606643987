import api from '../api';
import {
  SET_TEAM_MEMBER_TRACKER_FILTER_CRITERIA,
  CLEAR_TEAM_MEMBER_TRACKER_STATE,
  SET_TEAM_MEMBER_TRACKER_TOGGLE_TAB,
  SET_TRACKER_MASTER_FILTER_DATA,
  SET_TRACKER_BREAKDOWN_FILTER_DATA,
  SET_TRACKER_FILTER_DATA,
  SET_TRACKER_TABLE_DATA,
  SET_TRACKER_RESPONSE_RECEIVED
} from '../constants/reduceConstants';
import { enqueueSuccessSnackbar } from '../helpers/AppHelpers';
import { SUCCESS_SEND_GROUP_MESSAGE } from '../constants/infoSnackbarData';

export const getMasterTrackerData = (query) => () =>
  api.teamMemberTracker
    .getMasterTrackerDataApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getTrackerConfigData = (query) => () =>
  api.teamMemberTracker
    .getTrackerConfigDataApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const updateTrackerConfigData = (data, query) => () =>
  api.teamMemberTracker
    .updateTrackerConfigDataApi(data, query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getBreakdownTrackerData = (query) => () =>
  api.teamMemberTracker
    .getBreakdownTrackerDataApi(query)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getFilterCriteria = (query) => (dispatch) => {
  api.teamMemberTracker
    .getFilterCriteriaApi(query)
    .then((res) => dispatch({ type: SET_TEAM_MEMBER_TRACKER_FILTER_CRITERIA, payload: res }))
    .catch((err) => console.log(err));
};

export const getMasterCsvResource = (q) => () =>
  api.teamMemberTracker
    .getMasterCsvResourceApi(q)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getBreakdownCsvResource = (q) => () =>
  api.teamMemberTracker
    .getBreakdownCsvResourceApi(q)
    .then((res) => res)
    .catch((err) => console.log(err));

export const sendGroupNotification = (ids) => () => {
  api.teamMemberTracker
    .sendGroupNotificationApi(ids)
    .then((res) => {
      enqueueSuccessSnackbar(SUCCESS_SEND_GROUP_MESSAGE);
      return res;
    })
    .catch((err) => console.log(err));
};

export const setTrackerDay = (data) => () => {
  if (data?.id) {
    return api.teamMemberTracker
      .putTrackerDayApi(data)
      .then((res) => res)
      .catch((err) => console.log(err));
  } else {
    return api.teamMemberTracker
      .postTrackerDayApi(data)
      .then((res) => res)
      .catch((err) => console.log(err));
  }
};

export const setTrackerFilter = (payload) => (dispatch) => {
  dispatch({ type: SET_TRACKER_FILTER_DATA, payload });
};

export const setTrackerResponseReceived = (payload) => (dispatch) => {
  dispatch({ type: SET_TRACKER_RESPONSE_RECEIVED, payload });
};

export const setMasterFilterData = (data) => (dispatch) => {
  dispatch({ type: SET_TRACKER_MASTER_FILTER_DATA, payload: data });
};

export const setBreakdownFilterData = (data) => (dispatch) => {
  dispatch({ type: SET_TRACKER_BREAKDOWN_FILTER_DATA, payload: data });
};

export const setToggleTab = (value) => (dispatch) => {
  dispatch({ type: SET_TEAM_MEMBER_TRACKER_TOGGLE_TAB, payload: value });
};

export const setTrackerTableData = (payload) => (dispatch) => {
  dispatch({ type: SET_TRACKER_TABLE_DATA, payload });
};

export const clearTeamMemberTrackerState = () => (dispatch) => {
  dispatch({ type: CLEAR_TEAM_MEMBER_TRACKER_STATE });
};
