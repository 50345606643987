import React, { useEffect, useState } from 'react';
import styles from './AssetsDashboardsFilter.module.scss';

import { FormDatePicker, FormLabel } from 'components/FormComponents';
import FormAutoMultiSelect from 'components/FormComponents/FormAutoMultiSelect/FormAutoMultiSelect';
import FormMultiSelect from 'components/FormComponents/FormMultiSelect/FormMultiSelect';
import FilterWrapper from 'components/FilterComponents/FilterWrapper';
import InfoTooltip from 'components/InfoTooltip/InfoTooltip';
import FormCheckbox from 'components/FormComponents/FormCheckbox/FormCheckbox';

import RemoveIcon from '@material-ui/icons/Remove';

import { useAssetsDashboardsActions, useAssetsDashboardsSelector } from 'hooks/AssetsDashboards';

import { FormProvider, useForm } from 'react-hook-form';

import { enrichNameToId, formatDate, isSuccessfulStatus, sortByParam } from 'helpers/AppHelpers';
import { getErrorsProperties } from 'helpers/ErrorValidator';
import { generateChips } from './helpers';

import clsx from 'clsx';

export default function Filter({ filter, onApply }) {
  const methods = useForm({
    defaultValues: {
      categories: [],
      prefixes: [],
      assets: [],
      rentalStatuses: [],
      projects: [],
      locations: [],
      statuses: [],
      durations: [
        { dateFrom: null, dateTo: null, endDateAsCurrent: false },
        { dateFrom: null, dateTo: null, endDateAsCurrent: false },
        { dateFrom: null, dateTo: null, endDateAsCurrent: false }
      ]
    },
    mode: 'onChange'
  });
  const { getValues, reset, setValue, watch, formState, setError, clearErrors } = methods;
  const { errors } = formState;
  const isFormInvalid = !!Object.values(errors).filter(Boolean).length;

  const [open, setOpen] = useState(false);
  const [chips, setChips] = useState([]);

  const [assetOptions, setAssetOptions] = useState([]);
  const [prefixOptions, setPrefixOptions] = useState([]);

  const { filterCriteria } = useAssetsDashboardsSelector();
  const { getDashboardConfigAction, updateSectionConfigAction, getAssetOptionsAction } =
    useAssetsDashboardsActions();

  const getAssetOptions = (prefixIds) =>
    getAssetOptionsAction({ filters: { prefixIds } }).then((res) =>
      setAssetOptions(res?.items || [])
    );

  const setSortedPrefixOptions = (options = []) => setPrefixOptions(sortByParam(options, 'prefix'));

  const prepareFilterValues = (data) => ({
    ...data,
    statuses: data?.statuses?.length ? enrichNameToId(data.statuses) : [],
    rentalStatuses: data?.rentalStatuses?.length ? enrichNameToId(data.rentalStatuses) : []
  });

  const { prefixes, rentalStatuses, projects, locations, statuses, categories } = filterCriteria;

  useEffect(() => {
    if (open) {
      filter?.prefixes?.length && getAssetOptions(filter.prefixes.map(({ id }) => id));

      if (filter?.categories?.length) {
        const options = filter.categories.flatMap(({ assetPrefixes }) => assetPrefixes);
        setSortedPrefixOptions(options);
      } else {
        setSortedPrefixOptions(prefixes);
      }

      reset(prepareFilterValues(filter));
    }
  }, [open]);

  useEffect(() => {
    if (filterCriteria?.['locations']?.length) {
      reset(filter);
      createChips(filter);
    }
  }, [filter, filterCriteria]);

  const clearFilter = () => {
    const defaultState = {
      ...getValues(),
      categories: [],
      prefixes: [],
      assets: [],
      rentalStatuses: [],
      projects: [],
      locations: [],
      statuses: [],
      durations: [
        { dateFrom: null, dateTo: null, endDateAsCurrent: false },
        { dateFrom: null, dateTo: null, endDateAsCurrent: false },
        { dateFrom: null, dateTo: null, endDateAsCurrent: false }
      ]
    };
    reset(defaultState);
  };

  const closeFilter = () => setOpen(false);
  const toggleFilter = (value) => setOpen(value || !open);

  const validateForm = () => {
    const values = getValues();
    const { durations } = values;

    let isFormValid = true;

    if (!durations?.[0]?.dateFrom) {
      setError('durations[0].dateFrom', getErrorsProperties('Field is required'));
      isFormValid = false;
    }

    if (!durations?.[0]?.dateTo) {
      setError('durations[0].dateTo', getErrorsProperties('Field is required'));
      isFormValid = false;
    }

    if (
      (durations?.[1]?.dateFrom && !durations?.[1]?.dateTo) ||
      (!durations?.[1]?.dateFrom && durations?.[1]?.dateTo)
    ) {
      if (!durations?.[1]?.dateFrom)
        setError('durations[1].dateFrom', getErrorsProperties('Field is required'));
      if (!durations?.[1]?.dateTo)
        setError('durations[1].dateTo', getErrorsProperties('Field is required'));
      isFormValid = false;
    }

    if (
      (durations?.[2]?.dateFrom && !durations?.[2]?.dateTo) ||
      (!durations?.[2]?.dateFrom && durations?.[2]?.dateTo)
    ) {
      if (!durations?.[2]?.dateFrom)
        setError('durations[2].dateFrom', getErrorsProperties('Field is required'));
      if (!durations?.[2]?.dateTo)
        setError('durations[2].dateTo', getErrorsProperties('Field is required'));
      isFormValid = false;
    }

    return isFormValid;
  };

  const applyFilter = async () => {
    if (!validateForm()) return;

    const values = getValues();

    const response = await updateSectionConfigAction(values);

    if (!isSuccessfulStatus(response?.status)) return;

    closeFilter();

    getDashboardConfigAction({ dashboardType: values.dashboardType }).then(() => {
      onApply();
    });
  };

  const createChips = (data) => setChips(generateChips(data));

  const refreshAssetsValue = () => {
    if (!getValues('assets')?.length) return;

    if (!getValues('prefixes').length) {
      setValue('assets', []);
      return;
    }

    const prefixIds = getValues('prefixes').map(({ id }) => id);
    const filteredAssets = getValues('assets').filter(({ assetPrefix }) =>
      prefixIds.includes(assetPrefix.id)
    );

    setValue('assets', filteredAssets);
  };

  const handleCategorySelect = (_, value) => {
    if (!value?.length) return setSortedPrefixOptions(prefixes);

    const options = value?.flatMap(({ assetPrefixes }) => assetPrefixes) || [];
    setSortedPrefixOptions(options);
  };

  const handlePrefixSelect = (_, value) => {
    refreshAssetsValue();

    if (!value?.length) return;

    getAssetOptions(value?.map(({ id }) => id) || []);
  };

  const handleMultiSelect = (name, value) => setValue(name, value);

  const prefixesWatcher = watch('prefixes');

  const date1FromWatcher = watch(`durations[0].dateFrom`);
  const date1ToWatcher = watch(`durations[0].dateTo`);

  const date2FromWatcher = watch(`durations[1].dateFrom`);
  const date2ToWatcher = watch(`durations[1].dateTo`);

  const date3FromWatcher = watch(`durations[2].dateFrom`);
  const date3ToWatcher = watch(`durations[2].dateTo`);

  const isFilterActive = () =>
    open ||
    chips?.length ||
    filter?.durations?.[0]?.dateFrom ||
    filter?.durations?.[0]?.dateTo ||
    filter?.durations?.[1]?.dateFrom ||
    filter?.durations?.[1]?.dateTo ||
    filter?.durations?.[2]?.dateFrom ||
    filter?.durations?.[2]?.dateTo;

  const handleCheckboxSelect = (value, index) => {
    if (!value) return;

    errors?.durations?.[index].dateTo?.message && clearErrors(`durations[${index}].dateTo`);

    setValue(`durations[${index}].dateTo`, formatDate(new Date()));
  };

  return (
    <FilterWrapper
      open={open}
      transparentBackDrop={false}
      disableChipDelete
      onClick={toggleFilter}
      onApply={applyFilter}
      onCancel={closeFilter}
      onClear={clearFilter}
      onBack={closeFilter}
      isApplyDisabled={isFormInvalid}
      chips={chips}
      isFilterActive={isFilterActive}>
      <FormProvider {...methods}>
        <div className={styles.wrapper}>
          <div className={styles.block}>
            <div className={styles.block__cell}>
              <FormLabel>Asset Category</FormLabel>
              <FormAutoMultiSelect
                name="categories"
                menuItems={categories || []}
                options={{
                  label: 'name',
                  disableByObjectTracker: false,
                  disableLabel: true,
                  extraAction: handleCategorySelect
                }}
              />
            </div>
            <div className={styles.block__cell}>
              <FormLabel>Prefix</FormLabel>
              <FormAutoMultiSelect
                name="prefixes"
                menuItems={prefixOptions || []}
                options={{
                  label: 'prefix',
                  disableByObjectTracker: false,
                  disableLabel: true,
                  extraAction: handlePrefixSelect
                }}
              />
            </div>
            <div className={styles.block__cell}>
              <FormLabel>Asset</FormLabel>
              <FormAutoMultiSelect
                name="assets"
                menuItems={assetOptions || []}
                options={{
                  label: 'drCode',
                  disableByObjectTracker: false,
                  disableLabel: true,
                  isDisabled: !prefixesWatcher?.length
                }}
              />
            </div>
            <div className={styles.block__cell}>
              <FormLabel>Rental Status</FormLabel>
              <FormMultiSelect
                name="rentalStatuses"
                options={enrichNameToId(rentalStatuses) || []}
                onSelect={handleMultiSelect}
              />
            </div>
            <div className={styles.block__cell}>
              <FormLabel>Status</FormLabel>
              <FormMultiSelect
                name="statuses"
                options={enrichNameToId(statuses) || []}
                onSelect={handleMultiSelect}
              />
            </div>
            <div className={styles.block__cell}>
              <FormLabel>Project</FormLabel>
              <FormAutoMultiSelect
                name="projects"
                menuItems={projects || []}
                options={{
                  labelType: 'project',
                  disableByObjectTracker: false,
                  disableLabel: true
                }}
              />
            </div>
            <div className={styles.block__cell}>
              <FormLabel>Location</FormLabel>
              <FormAutoMultiSelect
                name="locations"
                menuItems={locations || []}
                options={{
                  label: 'siteCode',
                  disableByObjectTracker: false,
                  disableLabel: true
                }}
              />
            </div>
          </div>
          <div className={styles.block}>
            <div className={styles.block__cell}>
              <FormLabel required>Duration 1</FormLabel>
              <div className={styles.block__cell_dates}>
                <FormDatePicker name="durations[0].dateFrom" small max={date1ToWatcher} />
                <RemoveIcon />
                <FormDatePicker name="durations[0].dateTo" small min={date1FromWatcher} />
              </div>
            </div>
            <div className={clsx(styles.block__cell, styles.checkbox)}>
              <div className={styles.block__cell_label}>
                <FormLabel>Set End Date as Current</FormLabel>
                <InfoTooltip text="By selecting the checkbox, the end date and data in the widget will be updated in accordance with the current date each day." />
              </div>

              <FormCheckbox
                name={`durations[0].endDateAsCurrent`}
                onSelectTriggered={(_, value) => handleCheckboxSelect(value, 0)}
              />
            </div>
            <div className={styles.block__cell}>
              <div className={styles.separator} />
              <FormLabel>Duration 2</FormLabel>
              <div className={styles.block__cell_dates}>
                <FormDatePicker
                  name={`durations[1].dateFrom`}
                  small
                  max={date2ToWatcher}
                  placement="top"
                />
                <RemoveIcon />
                <FormDatePicker
                  name={`durations[1].dateTo`}
                  small
                  min={date2FromWatcher}
                  placement="top"
                />
              </div>
            </div>
            <div className={clsx(styles.block__cell, styles.checkbox)}>
              <div className={styles.block__cell_label}>
                <FormLabel>Set End Date as Current</FormLabel>
                <InfoTooltip text="By selecting the checkbox, the end date and data in the widget will be updated in accordance with the current date each day." />
              </div>

              <FormCheckbox
                name={`durations[1].endDateAsCurrent`}
                onSelectTriggered={(_, value) => handleCheckboxSelect(value, 1)}
              />
            </div>
            <div className={styles.block__cell}>
              <div className={styles.separator} />
              <FormLabel>Duration 3</FormLabel>
              <div className={styles.block__cell_dates}>
                <FormDatePicker
                  name={`durations[2].dateFrom`}
                  small
                  max={date3ToWatcher}
                  placement="top"
                />
                <RemoveIcon />
                <FormDatePicker
                  name={`durations[2].dateTo`}
                  small
                  min={date3FromWatcher}
                  placement="top"
                />
              </div>
            </div>
            <div className={clsx(styles.block__cell, styles.checkbox)}>
              <div className={styles.block__cell_label}>
                <FormLabel>Set End Date as Current</FormLabel>
                <InfoTooltip text="By selecting the checkbox, the end date and data in the widget will be updated in accordance with the current date each day." />
              </div>

              <FormCheckbox
                name={`durations[2].endDateAsCurrent`}
                onSelectTriggered={(_, value) => handleCheckboxSelect(value, 2)}
              />
            </div>
          </div>
        </div>
      </FormProvider>
    </FilterWrapper>
  );
}
