import {
  SET_TEAM_MEMBER_CREATION_FORM,
  CLEAR_TEAM_MEMBER_STATE,
  SET_CURRENT_TEAM_MEMBER,
  SET_TEAM_MEMBERS_FILTER,
  SET_TEAM_MEMBERS_FILTER_CRITERIA
} from 'constants/reduceConstants';

const initialState = {
  currentTeamMember: { responseReceived: false },
  creationForm: {},
  filter: {},
  filterCriteria: {}
};

const teamMembersReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_TEAM_MEMBER:
      return { ...state, currentTeamMember: payload };
    case SET_TEAM_MEMBER_CREATION_FORM:
      return { ...state, creationForm: payload };
    case SET_TEAM_MEMBERS_FILTER:
      return { ...state, filter: payload };
    case SET_TEAM_MEMBERS_FILTER_CRITERIA:
      return { ...state, filterCriteria: payload };
    case CLEAR_TEAM_MEMBER_STATE:
      return { ...initialState, filter: { ...state.filter, responseReceived: false } };
    default:
      return state;
  }
};

export default teamMembersReducer;
