import { useDispatch } from 'react-redux';
import {
  getConfigurationList,
  setSearchValue,
  clearState
} from 'actions/bomConfigurationActions';

export function useBOMConfigurationActions() {
  const dispatch = useDispatch();

  const getConfigurationListAction = () => dispatch(getConfigurationList());

  const setSearchValueAction = (v) => dispatch(setSearchValue(v));

  const clearStateAction = () => dispatch(clearState());

  return {
    getConfigurationListAction,
    setSearchValueAction,
    clearStateAction
  };
}
