import React from 'react';
import styles from './AssetPrefixDetails.module.scss';

import Subtitle from 'components/DetailsComponents/Subtitle';
import DetailedRow from 'components/DetailsComponents/DetailedRow';

import { useAssetSelector } from 'hooks/Asset';

import { formatDate } from 'helpers/AppHelpers';

export default function AssetPrefixDetails() {
  const { currentAsset } = useAssetSelector();
  const { assetPrefix, assetFields } = currentAsset;

  const assets = assetFields?.map((asset) => {
    return { ...asset.assetField, value: asset.value };
  });

  const prefixDetails = [
    assets?.slice(0, Math.ceil(assets.length / 2)),
    assets?.slice(Math.ceil(assets.length / 2), assets.length)
  ].filter(Boolean);

  const getValue = (detail) => {
    if (typeof detail.value == 'boolean') return detail.value ? 'Yes' : 'No';
    if (detail.value?.id) return detail?.value?.displayName || '';
    if (detail.type === 'DateTime') return detail.value ? formatDate(detail.value) : '';
    return detail.value;
  };

  return (
    <div className={styles.wrapper}>
      <Subtitle>{assetPrefix?.prefixType?.name || ''} Details</Subtitle>
      {!!prefixDetails?.length && (
        <div className={styles.details}>
          {prefixDetails.map((column, index) => (
            <div key={index} className={styles.details__column}>
              {column.map((detail) => (
                <DetailedRow
                  key={detail.id}
                  label={detail?.displayName || ''}
                  value={getValue(detail)}
                />
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
