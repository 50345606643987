import api from '../api';
import { browserHistory } from '../browserHistory';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from 'helpers/AppHelpers';

import {
  SET_CURRENT_PREFIX,
  CLEAR_PREFIX_STATE,
  SET_PREFIX_CREATION_FORM
} from 'constants/reduceConstants';
import {
  SUCCESS_CREATE_PREFIX,
  SUCCESS_DELETE_PREFIX,
  SUCCESS_UPDATE_PREFIX
} from '../constants/infoSnackbarData';

import { CATEGORY_LIST_PATH, PREFIX_DETAILED_PATH } from '../constants/routeConstants';

// Details Screen
export const deletePrefix = (id) => (dispatch) =>
  api.prefix.deletePrefixApi(id).then(
    () => {
      enqueueSuccessSnackbar(SUCCESS_DELETE_PREFIX);
      dispatch({ type: CLEAR_PREFIX_STATE });
      browserHistory.push(CATEGORY_LIST_PATH);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
    }
  );

// Common
export const getSinglePrefix = (id) => (dispatch) =>
  api.prefix
    .getSinglePrefixApi(id)
    .then((res) => dispatch({ type: SET_CURRENT_PREFIX, payload: res }))
    .catch((err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      browserHistory.push(CATEGORY_LIST_PATH);
    });

export const clearState = () => (dispatch) => dispatch({ type: CLEAR_PREFIX_STATE });

// Edit Screen
export const setCreationForm = (payload) => (dispatch) =>
  dispatch({ type: SET_PREFIX_CREATION_FORM, payload });

export const getAssetFieldsDeletePermissions = (assetPrefixId, assetFieldId) => () =>
  api.prefix
    .getAssetFieldsDeletePermissionsApi(assetPrefixId, assetFieldId)
    .then((res) => res)
    .catch((err) => console.log(err));

export const getPrefixTypes = () => () =>
  api.prefix
    .getPrefixTypesApi()
    .then((res) => res)
    .catch((err) => console.log(err));

export const getAssetFields = () => () =>
  api.prefix
    .getAssetFieldsApi()
    .then((res) => res)
    .catch((err) => console.log(err));

export const createPrefix = (data) => () =>
  api.prefix.createPrefixApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_CREATE_PREFIX);
      browserHistory.push(`${PREFIX_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );

export const updatePrefix = (data) => () =>
  api.prefix.updatePrefixApi(data).then(
    (res) => {
      enqueueSuccessSnackbar(SUCCESS_UPDATE_PREFIX);
      browserHistory.push(`${PREFIX_DETAILED_PATH}/${res.id}`);
    },
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );

export const updatePrefixField = (params) => () => {
  const modifiedField = { ...params, displayName: params?.name || '' };
  return api.prefix.updatePrefixFieldApi(modifiedField).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      enqueueErrorSnackbar('', errors);
      return errors;
    }
  );
};

export const deletePrefixField = (q) => () => {
  return api.prefix.deletePrefixFieldApi(q).then(
    (res) => res,
    (err) => {
      const errors = JSON.parse(err.request.response);
      return errors;
    }
  );
};
