import React, { useMemo } from 'react';
import styles from './HighlightedText.module.scss';

export const HighlightedText = ({ text, searchTerm }) => {
  const highlightedText = useMemo(() => {
    if (!searchTerm) return text;

    const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
		
    return parts.map((part, index) =>
      part.toLowerCase() === searchTerm.toLowerCase() ? (
        <span key={index} className={styles.highlighted}>
          {part}
        </span>
      ) : (
        part
      )
    );
  }, [text, searchTerm]);

  return <>{highlightedText}</>;
};
